import React, { useEffect, useState } from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import { Box, Button, FormHelperText, Grid, IconButton, Typography, useTheme } from '@mui/material'
import CustomInput from '../../theme/style-components/CustomInput';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomButton from '../../theme/style-components/CustomButton';
import { useDeletePropertyDocumentsMutation, useLazyGetMyPropertyListingQuery, useLazyGetPropertyListQuery, useUpdatePropertyDocumentsMutation } from '../../store/property/propertyApiSlice';
import { toast } from 'react-toastify';
import { showError } from '../shared/helperFunction';
import { Link } from 'react-router-dom';

const PropertyDocs = ({ documents, id }) => {
    const theme = useTheme();
    const [getMyPropertyListing] = useLazyGetMyPropertyListingQuery();
    const [updatePropertyDocuments, { data, isLoading, error }] = useUpdatePropertyDocumentsMutation();
    const [deletePropertyDocuments] = useDeletePropertyDocumentsMutation();

    const [propDocs, setPropDocs] = useState([
        {
            document_name: "",
            document: null,
        }
    ]);

    useEffect(() => {
        if (id) {
            getMyPropertyListing({ id: id, page: 1, query: "" })
        }
    }, [id])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        if (documents?.length) {
            setPropDocs(documents)
        }
    }, [documents]);

    const handleAdd = () => {
        setPropDocs((prevPropDocs) => [
            ...prevPropDocs,
            {
                document_name: "",
                document: null,
            },
        ]);
    };

    useEffect(() => {
        if (propDocs?.length === 0) {
            handleAdd();
        }
    }, [propDocs])

    const handleNameChange = (e, index) => {
        setPropDocs((prevPropDocs) => {
            const updatedPropDocs = [...prevPropDocs];
            updatedPropDocs[index] = {
                ...updatedPropDocs[index],
                document_name: e.target.value,
                nameError: ""
            };
            return updatedPropDocs;
        });
    };

    const handleFileChange = (e, index) => {
        setPropDocs((prevPropDocs) => {
            const updatedPropDocs = [...prevPropDocs];
            updatedPropDocs[index] = {
                ...updatedPropDocs[index],
                document: e.target.files[0],
                name: e.target.files[0].name,
                docError: ""
            };
            return updatedPropDocs;
        });
    };

    const handleValidation = (item, index) => {
        let valid = true;
        if (!item.document_name) {
            setPropDocs((prevPropDocs) => {
                const updatedPropDocs = [...prevPropDocs];
                updatedPropDocs[index] = {
                    ...updatedPropDocs[index],
                    nameError: "Name is required"
                };
                return updatedPropDocs;
            });
            valid = false
        }
        if (!item.document) {
            setPropDocs((prevPropDocs) => {
                const updatedPropDocs = [...prevPropDocs];
                updatedPropDocs[index] = {
                    ...updatedPropDocs[index],
                    docError: "Please select the file"
                };
                return updatedPropDocs;
            });
            valid = false
        }
        return valid
    };

    const uploadDocuments = async (item, index) => {
        let formData = new FormData();
        formData.append("id", id)
        formData.append("doc", item.document)
        formData.append("name", item.document_name)

        if (item) {
            updatePropertyDocuments(formData).unwrap().then((response) => {
                toast.success("Property document updated succesfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                try {
                    const res = getMyPropertyListing({ id: id, page: 1 }).unwrap()
                } catch (error) {
                    showError(error?.data)
                }
            }).catch((error) => {
                showError(error?.data)
            })
        }

    }

    const handleUpload = (item, index) => {
        const isValid = handleValidation(item, index);
        if (isValid) {
            uploadDocuments(item, index)
        }
    };

    const handleDelete = async (delteID) => {
        try {
            const response = await deletePropertyDocuments({ doc_id: delteID }).unwrap();
            if (response) {
                const filteredDocs = propDocs.filter((item) => item.id !== delteID);
                if (filteredDocs) {
                    setPropDocs(filteredDocs)
                }
                toast.success("Property document deleted succesfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            showError(error?.data)
        }
    };

    return (
        <CustomPaperBox>
            <Box sx={{
                "& .MuiTypography-body1": {
                    color: theme.palette.primary.main, fontSize: '22px', marginBottom: '15px'
                }
            }}><Typography >Documents</Typography></Box>

            <Box>
                <Grid container spacing={0} sx={{ marginBottom: "10px" }}>
                    <Grid item lg={6} md={6} sm={6} xs={4}>
                        <Typography sx={{ marginTop: "7px", color: theme.palette.text.secondary, textAlign: 'center' }}>Name</Typography>
                    </Grid>
                    <Grid item lg={5} md={3} sm={3} xs={4}>
                        <Typography sx={{ marginTop: "7px", color: theme.palette.text.secondary, textAlign: 'center' }}>Doc</Typography>
                    </Grid>
                    <Grid item lg={1} md={3} sm={3} xs={4} sx={{ textAlign: "right" }}>
                        <CustomButton variant="contained" onClick={handleAdd} buttonName="Add" />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ borderBottom: "1px solid #2ec4b6", marginBottom: "15px" }}>
            </Box>
            {
                propDocs?.length && propDocs?.map((item, index) => {
                    return (
                        <Box key={index} sx={{
                            marginBottom: "15px"
                        }}>
                            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <CustomInput value={item.document_name}
                                        error={
                                            propDocs[index].nameError &&
                                            Boolean(propDocs[index].nameError)
                                        }
                                        helperText={
                                            propDocs[index].nameError && propDocs[index].nameError
                                        }
                                        label="Name" disabled={Boolean(item?.id)} onChange={(e) => handleNameChange(e, index)} />
                                </Grid>
                                <Grid item lg={4} md={3} sm={3} xs={6}>
                                    <Box sx={{
                                        textAlign: { xl: 'center', lg: 'center', md: 'center', sm: 'center', xs: 'start' },
                                        "& label": {
                                            textTransform: "capitalize !important",
                                            borderRadius: "20px",
                                            wordBreak: 'break-all',
                                            borderColor: propDocs[index].docError ? theme.palette.error.main : theme.palette.primary.main,
                                            color: propDocs[index].docError ? theme.palette.error.main : theme.palette.primary.main
                                        },
                                        '& a': {
                                            color: theme.palette.primary.main
                                        },
                                        '& .MuiFormHelperText-root': {
                                            textAlign: "center"
                                        }
                                    }}>
                                        {!item?.id && <Button component="label" startIcon={<CloudUploadIcon />} variant="outlined" >
                                            Select file
                                            <VisuallyHiddenInput onChange={(e) => handleFileChange(e, index)} type="file" />
                                        </Button>}{item.name && <Typography sx={{ marginLeft: "5px", color: theme.palette.grey[500] }} variant='span'>{item.name}</Typography>}
                                        {item?.id && <Link to={item.document} target="_blank"
                                            variant="outlined" >
                                            View Document
                                        </Link>}
                                        <FormHelperText error={Boolean(propDocs[index].docError)}>{propDocs[index].docError && propDocs[index].docError}</FormHelperText>
                                    </Box>
                                </Grid>
                                <Grid item lg={2} md={3} sm={3} xs={6} sx={{ textAlign: "right" }}>
                                    {!item?.id && <CustomButton buttonName="Upload" onClick={() => handleUpload(item, index)} />}
                                    {item?.id && <IconButton sx={{
                                        "& svg": {
                                            width: "1.5em !important",
                                            height: "1.5em !important"
                                        }
                                    }} onClick={() => handleDelete(item?.id)}>
                                        <DeleteForeverIcon color="error" />
                                    </IconButton>}
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }

        </CustomPaperBox >
    )
}

export default PropertyDocs;

