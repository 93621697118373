import { apiSlice } from "../apiSlice";

export const transactionsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTransactionsLogs: builder.query({
            query: ({ id, page, query }) => "/wallet/trx_log/",
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    useGetTransactionsLogsQuery,
} = transactionsApiSlice;