import React, { useEffect, useState } from "react";
import CustomTable from "../../theme/style-components/CustomTable";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { useGetDashboardDataQuery } from "../../store/dashboard/dashboadApiSlice";
import { Typography, Box, Grid } from "@mui/material";
import CustomButton from "../../theme/style-components/CustomButton";

const Dashboard = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState();

  const theme = useTheme();

  //base query hooks
  const {
    isLoading,
    data: getDashboardData,
    isSuccess,
    isError,
    refetch,
  } = useGetDashboardDataQuery();

  const [dashboardListData, setDashboardListData] = useState([]);

  const navigate = useNavigate();

  const columns = [
    { id: "id", label: "#" },
    { id: "property_name", label: "Property Name" },
    { id: "token_balance", label: "Balance" },
    { id: "token_price_aed_cents", label: "Price" },
    { id: "value", label: "Value" },
    { id: "action", label: "Action" },
  ];

  const data =
    dashboardListData?.invested_properties?.length > 0
      ? dashboardListData?.invested_properties?.map((data) => ({
          id: "", // data?.id,
          property_name: (
            <Box
              sx={{
                "& a": {
                  textDecoration: "none",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                },
              }}
            >
              <Link
                to={
                  data?.property_active_on_marketplace
                    ? `/market-place/${data.property}`
                    : `/new-listings/${data.id}`
                }
              >
                {data?.property_name}
              </Link>
            </Box>
          ),
          token_balance: (
            <Typography>{`${
              data?.token_balance ? data?.token_balance : "N/A"
            }`}</Typography>
          ),
          token_price_aed_cents: (
            <Typography>{`${
              data?.token_price_aed_cents
                ? data?.token_price_aed_cents / 100 + " AED"
                : "N/A"
            }`}</Typography>
          ),
          value: (
            <Typography>{`${
              data?.token_price_aed_cents && data?.token_balance
                ? (data?.token_balance * data?.token_price_aed_cents) / 100 +
                  " AED"
                : "N/A"
            }`}</Typography>
          ),
          action: (
            <Box
              sx={{
                minWidth: {
                  md: "150px",
                  lg: "150px",
                  sm: "110px",
                  xs: "110px",
                },
                display: "flex",
                gap: "10px",
              }}
            >
              <CustomButton
                buttonName="Buy"
                onClick={() => {
                  data?.property_active_on_marketplace
                    ? navigate(`/market-place/${data.property}`)
                    : navigate(`/new-listings/${data.id}`);
                }}
              />
              <CustomButton
                buttonName="Sell"
                color={"error"}
                onClick={() =>
                  data?.property_active_on_marketplace
                    ? navigate(`/market-place/${data.property}`)
                    : navigate(`/new-listings/${data.id}`)
                }
              />
            </Box>
          ),
        }))
      : [];

  const columns2 = [
    { id: "id", label: "#" },
    { id: "property_name", label: "Property Name" },
    { id: "token_balance", label: "Balance" },
    { id: "token_price_aed_cents", label: "Price" },
    { id: "value", label: "Value" },
    { id: "action", label: "Status" },
  ];
  const data2 =
    dashboardListData?.listing_properties?.length > 0
      ? dashboardListData?.listing_properties?.map((data) => ({
          id: "", // data?.id,
          property_name: (
            <Box
              sx={{
                "& a": {
                  textDecoration: "none",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                },
              }}
            >
              <Link
                to={
                  data?.property_active_on_marketplace
                    ? `/market-place/${data.property}`
                    : `/new-listings/${data.id}`
                }
              >
                {data?.property_name}
              </Link>
            </Box>
          ),
          token_balance: (
            <Typography>{`${
              data?.token_balance ? data?.token_balance : "N/A"
            }`}</Typography>
          ),
          token_price_aed_cents: (
            <Typography>{`${
              data?.token_price_aed_cents
                ? data?.token_price_aed_cents / 100 + " AED"
                : "N/A"
            }`}</Typography>
          ),
          value: (
            <Typography>{`${
              data?.token_price_aed_cents && data?.token_balance
                ? (data?.token_balance * data?.token_price_aed_cents) / 100 +
                  " AED"
                : "N/A"
            }`}</Typography>
          ),
          action: (
            <Typography>{`${
              data?.balance_status ? data?.balance_status : "N/A"
            }`}</Typography>
          ),
        }))
      : [];

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setDashboardListData(getDashboardData?.data);
    }
  }, [isLoading, getDashboardData?.data]);

  //handle page function
  const onPageChange = (value) => {
    setPage(value);
  };

  //handle row page function
  const onRowsPerPageChange = (value) => {
    setRowsPerPage(value);
  };

  const handleCreateClick = () => {
    navigate("/create-properties");
  };

  return (
    <>
      <Box
        sx={{
          background: theme.palette.background.paper,
          borderRadius: "20px",
          marginTop: "30px",
          padding: "20px",
        }}
      >
        <Grid container spacing={2}>
          {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                        <Typography sx={{
                            fontSize: '1.2rem',
                            fontWeight: 600,
                        }}>Coming soon...</Typography>
                    </Grid> */}
          <Grid
            item
            xs={4}
            sx={
              {
                // backgroundColor: 'red',
              }
            }
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2ec4b603",
                border: "1px solid #2ec4b6",
                width: "320px", // Adjust width as needed
                padding: "20px",
                borderRadius: "8px",
                margin: "0 auto 10px auto",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.2rem",
                }}
              >
                Account Bal :{" "}
                {dashboardListData?.account_balance_aed_cents / 100} AED
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2ec4b603",
                border: "1px solid #2ec4b6",
                width: "320px", // Adjust width as needed
                padding: "20px",
                borderRadius: "8px",
                margin: "0 auto 10px auto",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.2rem",
                }}
              >
                Total Rent : {dashboardListData?.total_rental_earned / 100} AED
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                        <Typography sx={{
                            fontSize: '1.2rem',
                            fontWeight: 600,
                        }}>Coming soon...</Typography>
                    </Grid> */}
          <Grid
            item
            xs={4}
            sx={
              {
                // backgroundColor: 'red',
              }
            }
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2ec4b603",
                border: "1px solid #2ec4b6",
                width: "320px", // Adjust width as needed
                padding: "20px",
                borderRadius: "8px",
                margin: "0 auto 10px auto",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.2rem",
                }}
              >
                Account Val : {dashboardListData?.account_value_aed_cents / 100}{" "}
                AED
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2ec4b603",
                border: "1px solid #2ec4b6",
                width: "320px", // Adjust width as needed
                padding: "20px",
                borderRadius: "8px",
                margin: "0 auto 10px auto",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.2rem",
                }}
              >
                Unclaimed Rent : {dashboardListData?.total_unclaimed_rent / 100}{" "}
                AED
              </Typography>
              {/* <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomButton buttonName="Withdraw" />
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <CustomTable
        //pagination props
        tableTitle="Invested Properties"
        isPagination={false}
        // page={page}
        // count={count}
        // rowsPerPage={rowsPerPage}
        // onPageChange={onPageChange}
        // onRowsPerPageChange={onRowsPerPageChange}

        //create button props
        // handleCreateClick={handleCreateClick}
        // createButtonName="Create Property"
        isShowCreateButton={false}
        //action props
        isActionVisible={false}
        //data props
        data={data}
        columns={columns}
        isLoading={isLoading}
        //search props
        isShowSearch={false}
        // handleOnSearch={handleOnSearch}
        // searchText={searchText}
        searchPlaceholder="Search by id"
      />
      <CustomTable
        //pagination props
        tableTitle="New Listings"
        isPagination={false}
        // page={page}
        // count={count}
        // rowsPerPage={rowsPerPage}
        // onPageChange={onPageChange}
        // onRowsPerPageChange={onRowsPerPageChange}

        //create button props
        // handleCreateClick={handleCreateClick}
        // createButtonName="Create Property"
        isShowCreateButton={false}
        //action props
        isActionVisible={false}
        //data props
        data={data2}
        columns={columns2}
        isLoading={isLoading}
        //search props
        isShowSearch={false}
        // handleOnSearch={handleOnSearch}
        // searchText={searchText}
        searchPlaceholder="Search by id"
      />
    </>
  );
};

export default Dashboard;
