import { Autocomplete, Box, Button, Grid, LinearProgress, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, linearProgressClasses, styled, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useBuyPropertyMutation, useGetMyPropertyListingQuery, useGetPropertyListQuery } from "../../store/property/propertyApiSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Lightbox from "react-18-image-lightbox";
import { formatAmount, showError } from "../shared/helperFunction";
import Slider from "react-slick";
import rightarrow from '../../asset/right-arrow.svg'
import leftarrow from '../../asset/left-arrow.svg';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Splide from '@splidejs/splide';
import room from '../../../src/asset/room.jpg'
import ImageIcon from '@mui/icons-material/Image';
import CustomModal from "../../theme/style-components/CustomModal";
import CustomSwitch from "../../theme/style-components/CustomSwitch";
import { useGetMarketplaceListingsQuery, useGetMyMarketplaceQuery, useListOnMarketplaceMutation } from "../../store/market-place/marketPlaceApiSlice";
import CustomInput from "../../theme/style-components/CustomInput";
import useCustomFormik from "../../component/formik/useFormikForm";
import { buyValidationSchema, createValidationSchema, investValidationSchema } from "../../component/formik/validationSchema";
import TabTitle from "../shared/TabTitle";
import CustomButton from "../../theme/style-components/CustomButton";
import ImageGrid from "../../component/property-tabs/ImageGrid";
import DetailsTab from "../../component/property-tabs/DetailsTab";
import FinancialsTab from "../../component/property-tabs/FinancialsTab";
import DocumentsTab from "../../component/property-tabs/DocumentsTab";
import BuyingProcess from "../../component/property-tabs/BuyingProcess";
import VideoTab from "../../component/property-tabs/VideoTab";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    gallrymainImg: {
        "& img": {
            width: "100%",
            height: "437px",
            objectFit: "cover",
            borderRadius: "8px",
            '@media (max-width: 900px)': {
                height: "100%"
            }
        }
    },
    smallimg: {
        width: '100%',
        height: '400px',
        background: '#8686867d',
        borderRadius: '20px',

        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            borderRadius: "8px",
            '@media (max-width: 900px)': {
                height: "100%"
            }
        }
    },
    photogrid: {
        position: "relative",
    },
    photobutton: {
        position: "absolute !important",
        bottom: "-14px !important",
        right: "20px !important",
        background: "#fff !important",
        borderRadius: "100px !important",
        padding: "10px 24px !important",
        boxShadow: "0px 0px 3px #5c5c5c !important",
        gap: "5px !important",
    },
    hotel: {
        background: "#ffffff",
        padding: "30px",
        borderRadius: "10px",
        marginTop: "15px",
    },
    hotelName: {
        paddingBottom: "20px",
        borderBottom: "1px solid lightgray",
        "& h2": {
            fontSize: "36px",
            fontWeight: "500",
            color: "#161616",
            marginBottom: "10px",
        }
    },
    rooms: {
        "& span": {
            fontSize: "14px !important",
            color: "#262626 !important",
            padding: "0 10px !important",
            borderRight: "1px solid gray",
            margin: "0 !important",
        }
    },
    hotelpointone: {
        display: "flex",
        alignItems: "flex-start",
        gap: "10px",
        paddingTop: "10px",
    },
    hotelicondetails: {
        "& h3": {
            fontSize: "16px",
            fontWeight: "500",
            color: "#161616",
            marginBottom: "5px",
        },
        "& span": {
            fontSize: "16px",
            fontWeight: "400",
            color: "gray",
            marginBottom: "10px",
        }
    },
    hotelicon: {
        "& svg": {
            fontSize: "50px !important",
        }
    },
    hotelpoint: {
        paddingTop: "10px",
        borderBottom: "1px solid lightgray",
        paddingBottom: "20px",
    },
    OverviewHeading: {
        "& h3": {
            fontSize: "25px",
            fontWeight: "600",
            marginTop: "20px",
            marginBottom: "10px",
        },
    },
    Propertymoredetails: {
        color: "#262626 !important",
        marginBottom: "20px !important",
    },
    showmore: {
        color: "#41ce8e !important",
        padding: "0 !important",
        paddingLeft: "10px !important",
        "& hover": {
            background: "transparent !important"
        }
    },
    Propertycost: {
        "& h4": {
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
        }
    },
    Propertyprice: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
        width: "100%",
    },
    financBox: {

        gap: "15px",
    },
    pricelable: {
        color: "#5e6473",
    },
    price: {
        color: "#262626",
        fontWeight: "600"
    },
    totalprice: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTop: "1px solid lightgray",
        padding: "7px 0",
    },
    Tprice: {
        color: "#41ce8e",
        fontWeight: "600",
    },
    Property: {
        width: "100%",
    },
    owenership: {
        display: "flex",
        padding: "7px 10px",
        background: "#f6f7f9",
        borderRadius: "7px"
    },
    owenershipDetails: {
        fontSize: "12px",
        color: "#666666",
        margin: "0",
    },
    qusspan: {
        width: "20px",
        color: "#666666",
    },
    fundingB: {
        fontSize: "12px",
        color: "#666666",
        margin: "0",
        padding: "7px 10px",
        background: "#f6f7f9",
        borderRadius: "7px",
    },
    timeline: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    timelineDate: {
        fontSize: "14px"
    },
    timelineheading: {
        fontSize: "18px",
        fontWeight: "500",
    },
    timelinedetails: {
        fontSize: " 14px",
        color: "gray",
    },
    maplink: {
        fontSize: "16px",
        color: "#121c30",
        margin: "0",
        display: "flex",
        alignItems: " center",
        gap: "8px",
        "& svg": {
            color: "#41ce8e"
        }
    },
    mdetails: {
        fontSize: "16px",
        color: " gray",
        margin: "0",
        paddingTop: "20px",
    },
    amenitis: {
        display: "flex",
        alignItems: "center",
        gap: "60px",
        marginTop: "20px"
    },
    amenitiesbox: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& svg": {
            fontSize: "32px",
        },
        "& span": {
            fontSize: "16px",
            margin: "0",
            color: "#000000"
        }
    },
    docBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px",
        border: "1px solid #d3d3d3",
        borderRadius: "10px",
        marginTop: "20px"
    },
    docheading: {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    downicon: {
        color: "#02c102",
    },
    questions: {
        display: "flex",
        alignItems: "flex-start",
        gap: "20px"
    },
    userimage: {
        "& img": {
            width: "88px"
        }
    },
    namedetails: {
        "& span": {
            fontSize: "16px",
            color: "#121c30",
            margin: "0",
        }
    },
    massage: {
        padding: "10px 20px !important",
        border: "1px solid gray !important",
        display: "flex !important",
        alignItems: "center !important",
        gap: "10px !important",
        borderRadius: "10px !important",
        color: "#000 !important",
        marginTop: "10px !important"
    },
    Propertypricebox: {
        background: "#fff",
        padding: "30px",
        borderRadius: "10px",
        marginTop: "15px"
    },
    priceheading: {
        color: "#5e6473",
        fontSize: "16px",
        fontWeight: "600",
        textAlign: "center",
        width: "100%",
        display: "block",
        paddingTop: "20px",
        paddingBottom: "10px"
    },
    priceboxheading: {
        color: '#1976d2',
        fontSize: '36px !important',
        textAlign: 'center',
        margin: '0px 0 20px !important',
        fontWeight: 'bold !important',
        '& span': {
            fontSize: '26px',
            marginRight: '5px',
        },
    },
    progressbar: {
        borderRadius: '100px',
        height: '8px',
    },
    progressdetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0',
        color: '#5e6473',
        fontSize: '14px',
    },
    investors: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0',
        paddingTop: '10px',
    },
    investorstext: {
        color: '#5e6473',
        fontSize: '16px',
        '& p': {
            display: 'inline-block',
            marginRight: '5px',
            color: '#1976d2',
            fontWeight: '600',
        },
    },
    redtext: {
        color: '#e53e3e',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '5px',
    },
    investorslist: {
        background: '#F6F7F9',
        padding: '10px 20px',
        borderRadius: '8px',
        margin: '20px 0',
    },
    investorslistitem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0',
        '& p': {
            color: '#5e6473',
            fontSize: '14px',
        },
        '& span': {
            color: '#5e6473',
            fontWeight: '600',
        }
    },
    Addtocartwrp: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
    },
    Addtocarfiled: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    Addtocarflag: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        left: '8px',
        top: '12px',
        '& img': {
            width: '16px',
            height: '16px',
            marginRight: '5px',
            borderRadius: '100px',
            objectFit: 'fill',
        },
        '& p': {
            fontSize: '12px',
        },
    },
    Addcarttextfield: {
        margin: '0 !important',
        '& input': {
            padding: '10px 10px 10px 70px',
        },
    },
    Addtocartbtn: {
        whiteSpace: 'nowrap',
        padding: '10px 14px !important',
    },
    Addtocartbtngroup: {
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const ViewMyListing = ({ isView }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { id } = useParams();
    const navigate = useNavigate();
    const [propertyDetails, setPropertyDetails] = useState([])
    const [gmapLink, setGmapLink] = useState(null);
    const [investAmount, setInvestAmount] = useState(0);
    const [investAmountUSD, setInvestAmountUSD] = useState(0);
    const [tokenAmount, setTokenAmount] = useState();
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);
    const [isShowMyListings, setIsShowMyListings] = useState(false);
    const [isCreateListings, setIsCreateListings] = useState(false);
    const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [tokenValue, setTokenValue] = useState(0);
    const [expectedPricePerTokenAedCents, setExpectedPricePerTokenAedCents] = useState(0);
    console.log(investAmount, "investAmount");
    const GmapAPIKEY = process.env.REACT_APP_GmapAPIKEY;
    const [listingStatus, setListingStatus] = useState("AVAILABLE");
    const [selectedPropertyId, setSelectedPropertyId] = useState();
    const [selectedListingId, setSelectedListingId] = useState();

    const { state } = useLocation();

    console.log(state, "state");
    console.log("GmapAPIKEY", GmapAPIKEY);
    console.log("investAmount", investAmount);

    useEffect(() => {
        if (state) {
            setListingStatus(state?.type)
        }
    }, [state]);

    //base query hooks
    const {
        isLoading: isMarketLoading,
        data: marketReturned,
        isSuccess: isSuccessMarket,
        isError: isErrorMarket,
        refetch: reftechMarket
    } = useGetMarketplaceListingsQuery({ id: id });

    //base query hooks
    const {
        isLoading: isMyMarkeplacetListingLoading,
        data: myMarkeplacetListingReturned,
        isSuccess: isMyMarketplaceSuccess,
        isError: isMyMarketplaceError,
        refetch: refetchMyMarketplace
    } = useGetMyMarketplaceQuery({ page: 1, id: id });

    // //base query hooks
    // const {
    //     isLoading,
    //     data: propertyReturned,
    //     isSuccess,
    //     isError,
    //     refetch: refetchProperty
    // } = useGetPropertyListQuery({ id: id, page: 1, query: "", listing_status: listingStatus, });

    //base query hooks
    const {
        isLoading,
        data: propertyReturned,
        isSuccess,
        isError,
        refetch: refetchProperty
    } = useGetMyPropertyListingQuery({ id: id, page: 1, query: "" });


    //base query hooks
    const [buyProperty, {
        isLoading: isBuyPropertyLoading,
        data: buyPropertyReturned,
        isSuccess: isBuypropertySuccess,
        isError: isBuypropertyError,
        refetch: refetchBuyProperty
    }] = useBuyPropertyMutation();

    //base query hooks
    const [listOnMarketplace, {
        isLoading: isListOnMarketplaceLoading,
        data: listOnMarketplaceReturned,
        isSuccess: isListOnMarketplaceSuccess,
        isError: isListOnMarketplaceError,
        refetch: refetchListOnMarket
    }] = useListOnMarketplaceMutation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!isLoading && isSuccess && propertyReturned) {
            setPropertyDetails(propertyReturned?.data[0]);
        }
    }, [isLoading, isSuccess, propertyReturned]);

    console.log(propertyReturned, "propertyReturned");

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ py: 3 }}>
                        {children}
                    </Box>
                )}
            </Box>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];

    const theme = useTheme();

    const breadcrumbs = [
        <Link key="1" to="/new-listings">
            New Listings
        </Link>,
        <Typography key="2" variant="body1">
            Details
        </Typography>,
    ];

    const lineChartData = [
        {
            name: "Rental growth %",
            data: [5.5, 2.2, 2.6, 4.5, 6.7],
            key: ["2016", "2017", "2018", "2019", "2020"],
        },
    ];

    const progressValue = (((Number(propertyDetails?.web3?.total_tokens) - Number(propertyDetails?.web3?.tokens_left)) / Number(propertyDetails?.web3?.total_tokens)) * 100)?.toFixed(3)

    const handleInvestClick = async () => {
        setIsInvestModalOpen(true);
        // try {
        //     const res = await buyProperty({
        //         property_id: propertyDetails?.id,
        //         token_amount: investAmount,
        //     }).unwrap();
        //     if (res) {
        //         toast.success("Investment Successful", {
        //             position: toast.POSITION.TOP_RIGHT,
        //         });
        //     }
        // } catch (err) {
        //     // alert("Investment failed");
        //     if (err.response) {
        //         if (err.response.data) {
        //             if (err.response.data.message) {
        //                 toast.error(err.response.data.message, {
        //                     position: toast.POSITION.TOP_RIGHT,
        //                 });
        //             } else {
        //                 toast.error("Investment failed", {
        //                     position: toast.POSITION.TOP_RIGHT,
        //                 });
        //             }
        //         } else {
        //             toast.error("Investment failed", {
        //                 position: toast.POSITION.TOP_RIGHT,
        //             });
        //         }
        //     } else {
        //         toast.error("Investment failed", {
        //             position: toast.POSITION.TOP_RIGHT,
        //         });
        //     }
        // }
    };

    const lightBoxImage = propertyDetails?.images?.length > 0
        ? propertyDetails.images.map((item) => item.image_url)
        : [];

    console.log("lightBoxImage", lightBoxImage);
    console.log("propertyDetails", propertyDetails);

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    console.log("isSmallScreen", isSmallScreen);

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        swipeToSlide: true,
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
            // setActiveSlide(nextSlide)
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        },
    };

    // useEffect(() => {
    //     // Initialize the main slider
    //     const mainSlider = !isSmallScreen && lightBoxImage?.length && new Splide('#main-slider', {
    //         // Main slider options
    //         type: 'fade',
    //         focus: "center",
    //         heightRatio: 0.3,
    //         pagination: false,
    //         arrows: true,
    //         cover: true,
    //     })

    //     // Initialize the thumbnail carousel
    //     const thumbnailSlider = !isSmallScreen && lightBoxImage?.length && new Splide('#thumbnail-slider', {
    //         fixedWidth: 100,
    //         fixedHeight: 100,
    //         isNavigation: true,
    //         gap: 10,
    //         focus: 'center',
    //         arrows: false,
    //         pagination: false,
    //         cover: true,
    //         breakpoints: {
    //             600: {
    //                 fixedWidth: 66,
    //                 fixedHeight: 40,
    //             },
    //         },
    //     })

    //     // Synchronize the main slider with the thumbnail carousel
    //     if (lightBoxImage?.length > 0 && !isSmallScreen) {

    //         mainSlider.sync(thumbnailSlider);
    //         mainSlider.mount()
    //         thumbnailSlider.mount()
    //     }
    // }, [lightBoxImage, isSmallScreen]);

    const top100Films = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 },
    ];

    const handleOnChange = (event, newValue) => {
        setSelectedValue(newValue);
    };

    const handleTokenChange = (event, newValue) => {
        setTokenValue(event.target.value);
    };

    const handlePriceChange = (event, newValue) => {
        setExpectedPricePerTokenAedCents(event.target.value);
    };

    console.log(tokenValue, "token");

    const handleCreateListingSubmit = async () => {
        try {
            const res = await listOnMarketplace({
                property_id: 16,
                tokens: tokenValue,
                expected_price_per_token_aed_cents: expectedPricePerTokenAedCents
            }).unwrap();
            if (res) {
                setIsCreateListings(false);
                toast.success("Listed Successful", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (err) {
            showError(err?.data)
        }
    };


    const investInitialValues = {
        tokenAmount: ""
    }

    const handleInvestSubmit = async (values) => {
        setIsInvestModalOpen(true);
    }

    const investformik = useCustomFormik(investInitialValues, investValidationSchema, handleInvestSubmit);


    const buyInitialValues = {
        numTokenToPurchase: ""
    }

    const handlebuySubmit = async (values) => {
        navigate(`/buy-property/${selectedPropertyId}`, {
            state: {
                property_id: selectedPropertyId,
                num_tokens_to_purchase: values?.numTokenToPurchase,
                listing_id: selectedListingId
            }
        })
    }

    const buyformik = useCustomFormik(buyInitialValues, buyValidationSchema, handlebuySubmit);

    const createInitialValues = {
        tokens: "",
        tokenPrice: ""
    }

    const handleCreateSubmit = async (values) => {
        try {
            const res = await listOnMarketplace({
                property_id: Number(id),
                tokens: Number(values?.tokens),
                expected_price_per_token_aed_cents: values.tokenPrice * 100 * values.tokens
            }).unwrap();
            if (res) {
                setIsCreateListings(false);
                toast.success("Listed Successful", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (err) {
            showError(err?.data)
        }
    }

    const createListingsformik = useCustomFormik(createInitialValues, createValidationSchema, handleCreateSubmit);

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 8,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.primary.text,
        },
    }));

    return (
        <>
            <TabTitle title="Property Details" />
            <Box sx={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <Typography variant="h5" fontWeight={600}>Property Details</Typography>
                <CustomButton outline color="primary" buttonName="Back" onClick={() => navigate(-1)} />
            </Box>
            {/* {!isView && <ComponentTitle breadcrumbs={breadcrumbs} />} */}
            {isView && <Box sx={{ textAlign: "end", marginBottom: '15px' }}><CustomButton buttonName="Back" onClick={() => navigate(`/edit-properties/${id}`)} /></Box>}
            <Box>
                <ImageGrid lightBoxImage={lightBoxImage} />

                {isSmallScreen && <Box sx={{
                    "& .slick-prev": {
                        left: "0px !important",
                        zIndex: '99'
                    },
                    "& .slick-next": {
                        right: "0px !important"
                    },
                    '& .slick-next:before': {
                        content: "''",
                        backgroundImage: `url(${rightarrow})`,
                        width: '20px',
                        display: 'block',
                        height: '20px',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                    },
                    '& .slick-prev:before': {
                        content: "''",
                        backgroundImage: `url(${leftarrow})`,
                        width: '20px',
                        display: 'block',
                        height: '20px',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                    }
                }}>
                    <Slider {...settings}>
                        {lightBoxImage?.length > 0 && lightBoxImage.map((url, index) => {
                            return (
                                <Box key={index} sx={{ display: "flex !important", justifyContent: "center !important", alignItems: "center" }}>
                                    <Box sx={{
                                        width: "100% !important",
                                        height: "320px",
                                        "& img": {
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "cover"
                                        }
                                    }}><img onClick={() => {
                                        setIsLightBoxOpen(true);
                                        setPhotoIndex(index);
                                    }} src={url} /></Box>
                                </Box>
                            )
                        })}
                    </Slider>
                </Box>}

                <Box className={classes.detailsPage}>
                    <Grid container spacing={2} sx={{
                        marginTop: "0px"
                    }}>
                        <Grid item lg={8} md={12} xs={12}>
                            <Box sx={{
                                background: theme.palette.background.default,
                                borderRadius: '10px',
                                marginTop: '20px',
                            }}>
                                <Box>
                                    <Typography sx={{
                                        fontSize: '22px',
                                        fontWeight: 'bold',
                                        color: theme.palette.primary.main,
                                        margin: '0px 0 15px 0',
                                    }} variant="h2">{propertyDetails?.name}</Typography>
                                </Box>

                                <Box sx={{ width: '100%', }}>
                                    <Box sx={{ borderColor: 'Boxider' }}>
                                        <Tabs variant="scrollable"
                                            scrollButtons={false} value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Details" {...a11yProps(0)} />
                                            <Tab label="Finacials" {...a11yProps(1)} />
                                            <Tab label="Documents" {...a11yProps(2)} />
                                            <Tab label="Buying Process" {...a11yProps(3)} />
                                            {propertyDetails?.active_on_marketplace && <Tab label="Market" {...a11yProps(4)} />}
                                            {propertyDetails?.videos !== null && <Tab label="Video" {...a11yProps(propertyDetails?.active_on_marketplace ? 5 : 4)} />}
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0}>
                                        <DetailsTab propertyDetails={propertyDetails} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <FinancialsTab propertyDetails={propertyDetails} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <DocumentsTab propertyDetails={propertyDetails} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={3}>
                                        <BuyingProcess propertyDetails={propertyDetails} />
                                    </CustomTabPanel>
                                    {propertyDetails?.active_on_marketplace &&
                                        <CustomTabPanel value={value} index={4}>
                                            <Box display="flex" justifyContent="space-between" marginBottom="20px">
                                                <CustomSwitch onChange={(e) => {
                                                    setIsShowMyListings(e.target.checked)
                                                }
                                                } label="My listings" value={isShowMyListings} checked={isShowMyListings} />
                                                {
                                                    propertyDetails?.wallet?.token_balance !== null && propertyDetails?.wallet?.token_balance !== 0 &&
                                                    <CustomButton onClick={() => setIsCreateListings(true)} buttonName="Create listings" />}
                                            </Box>
                                            {!isShowMyListings ? marketReturned?.data?.length > 0 ?
                                                <Box>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{
                                                            "& th": {
                                                                fontWeight: "bold"
                                                            }
                                                        }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Property</TableCell>
                                                                    <TableCell>Tokens qty</TableCell>
                                                                    <TableCell>Expected price per token</TableCell>
                                                                    <TableCell>Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {marketReturned?.data?.map((item, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <TableRow>
                                                                            <TableCell>{item.property}</TableCell>
                                                                            <TableCell>{item.tokens_qty}</TableCell>
                                                                            <TableCell>$ {item?.expected_price_per_token_aed_cents / 100}</TableCell>
                                                                            <TableCell>
                                                                                <CustomButton buttonName="Buy" onClick={() => {
                                                                                    setSelectedPropertyId(item.property);
                                                                                    setSelectedListingId(item.id)
                                                                                    setIsBuyModalOpen(true)
                                                                                }} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </React.Fragment>
                                                                ))}
                                                            </TableBody>

                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                                :
                                                <Typography>No data found</Typography> : null}

                                            {isShowMyListings ? myMarkeplacetListingReturned?.data?.length > 0 ?
                                                <Box>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{
                                                            "& th": {
                                                                fontWeight: "bold"
                                                            }
                                                        }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell variant="head">Property</TableCell>
                                                                    <TableCell>Tokens qty</TableCell>
                                                                    <TableCell>Expected price per token</TableCell>
                                                                    <TableCell>Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {myMarkeplacetListingReturned?.data?.map((item, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <TableRow>
                                                                            <TableCell>{item.marketplace_pool?.property}</TableCell>
                                                                            <TableCell>{item?.marketplace_pool?.tokens_qty}</TableCell>
                                                                            <TableCell>${item?.marketplace_pool?.expected_price_per_token_aed_cents / 100}</TableCell>
                                                                            <TableCell>
                                                                                <CustomButton buttonName="Delist" />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </React.Fragment>
                                                                ))}
                                                            </TableBody>

                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                                :
                                                <Typography>No data found</Typography> : null}
                                        </CustomTabPanel>
                                    }
                                    {propertyDetails?.videos !== null &&
                                        <CustomTabPanel value={value} index={propertyDetails?.active_on_marketplace ? 5 : 4}>
                                            <VideoTab propertyDetails={propertyDetails} />
                                        </CustomTabPanel>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={12} xs={12}>
                            <Box sx={{
                                background: theme.palette.background.paper,
                                borderRadius: '10px',
                                marginTop: { lg: '15px', md: '0' },
                            }}>
                                <Box padding="25px">
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        '& h3': {
                                            color: theme.palette.primary.main,
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                            '& span': {
                                                fontSize: '12px',
                                                marginRight: '5px',
                                            },
                                        },
                                        '& p': {
                                            color: theme.palette.text.primary,
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        },
                                    }}>
                                        <Typography >Property
                                            price</Typography>
                                        <Typography variant="h3"><span>$</span>
                                            {((propertyDetails?.financials?.total_amount))}</Typography>
                                    </Box>
                                    <Box sx={{
                                        margin: '6px 0',
                                    }}>
                                        <BorderLinearProgress variant="determinate"
                                            value={(propertyDetails?.tokenomics?.sold_tokens * 100) / propertyDetails?.tokenomics?.total_tokens} />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        '& p': {
                                            color: '#08165d',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            color: theme.palette.text.primary,
                                        },
                                    }}>
                                        <Typography>{(propertyDetails?.tokenomics?.sold_tokens * 100) / propertyDetails?.tokenomics?.total_tokens}% Funded</Typography>
                                        {/* <Typography>{progressValue}%</Typography> */}
                                        <Typography>{(propertyDetails?.tokenomics?.available_tokens)} tokens left</Typography>
                                        {/* <Typography>{propertyDetails?.web3?.tokens_left} tokens left</Typography> */}
                                    </Box>
                                </Box>
                                <Box sx={{
                                    padding: '25px',
                                    background: theme.palette.background.paper,
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        margin: '20px 0',
                                        '& p': {
                                            color: '#08165d',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: theme.palette.text.primary,
                                        },
                                        '& span': {
                                            color: theme.palette.primary.main,
                                            fontSize: '16px',
                                            fontWeight: '600',
                                        },
                                    }}>
                                        <Typography>Projected Annual Return</Typography>
                                        <Typography variant="span">{propertyDetails?.financials?.projected_anualized_return}%</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        margin: '20px 0',
                                        '& p': {
                                            color: '#08165d',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: theme.palette.text.primary,
                                        },
                                        '& span': {
                                            color: theme.palette.primary.main,
                                            fontSize: '16px',
                                            fontWeight: '600',
                                        },
                                    }}>
                                        <Typography>Projected Rental Yield</Typography>
                                        <Typography variant="span">{propertyDetails?.financials?.anual_gross_rent}%</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        margin: '20px 0',
                                        '& p': {
                                            color: '#08165d',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: theme.palette.text.primary,
                                        },
                                        '& span': {
                                            color: theme.palette.primary.main,
                                            fontSize: '16px',
                                            fontWeight: '600',
                                        },
                                    }}>
                                        <Typography>Rental Yield</Typography>
                                        <Typography variant="span">${propertyDetails?.financials?.current_rent ? propertyDetails?.financials?.current_rent : 0}</Typography>
                                    </Box>
                                    {!propertyDetails?.active_on_marketplace && <>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            gap: '10px',
                                            '& .MuiFormControl-root': {
                                                width: '100%'
                                            },
                                        }}>
                                            <TextField sx={{
                                                '& input': {
                                                    padding: '12px 14px'
                                                },
                                                '& fieldset': {
                                                    borderRadius: '16px'
                                                },
                                                '& .MuiInputBase-root': {
                                                    '&:hover': {
                                                        '& fieldset': {
                                                            borderColor: 'rgba(0, 0, 0, 0.23) !important'
                                                        }
                                                    }
                                                }
                                            }}
                                                type="number"
                                                name="tokenAmount"
                                                id="outlined-basic"
                                                error={
                                                    investformik.touched.tokenAmount &&
                                                    Boolean(investformik.errors.tokenAmount)
                                                }
                                                helperText={
                                                    investformik.touched.tokenAmount && investformik.errors.tokenAmount
                                                }
                                                onChange={(e) => {
                                                    investformik.handleChange(e);
                                                    if (propertyDetails) {
                                                        if (propertyDetails.financials) {
                                                            if (propertyDetails?.tokenomics?.total_tokens) {
                                                                setInvestAmountUSD(
                                                                    e.target.value * (propertyDetails?.tokenomics?.token_price_aed_cents / 100)
                                                                );
                                                            }
                                                        }
                                                    }
                                                }}
                                                value={investformik.values.tokenAmount} variant="outlined" placeholder="Min 1 token" />
                                            <Typography sx={{
                                                color: "grey",
                                                fontWeight: '700',
                                                fontSize: '1.5rem'

                                            }}>~${investAmountUSD}</Typography>
                                        </Box>
                                        <Button size="large" variant="contained" disabled={isView} sx={{
                                            width: '100%',
                                            borderRadius: '16px',
                                            color: '#fff',
                                            padding: '11px 22px',
                                            marginTop: '15px'
                                        }} onClick={investformik.handleSubmit}>Invest</Button>
                                    </>}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {isLightBoxOpen && lightBoxImage?.length > 0 && (
                    <Lightbox
                        imageTitle={`${photoIndex + 1}/${lightBoxImage.length}`}
                        mainSrc={lightBoxImage[photoIndex]}
                        nextSrc={lightBoxImage[(photoIndex + 1) % lightBoxImage.length]}
                        prevSrc={lightBoxImage[(photoIndex + lightBoxImage.length - 1) % lightBoxImage.length]}
                        onCloseRequest={() => setIsLightBoxOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + lightBoxImage.length - 1) % lightBoxImage.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % lightBoxImage.length)}
                    />
                )}

                {
                    <CustomModal handleClose={() => setIsInvestModalOpen(false)} title="Payment Method" isModalOpen={isInvestModalOpen} isShowActionButton={false}>
                        <>
                            <Grid item lg={12} md={12}>
                                <Box sx={{
                                    "& div": {
                                        width: "100% !important"
                                    }
                                }}>
                                    <CustomButton onClick={() => navigate("/invest-pay", { state: { num_tokens_to_purchase: investformik.values.tokenAmount, prop_id: id } })} fullWidth buttonName="Stripe" />
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} >
                                <Box sx={{
                                    "& div": {
                                        width: "100% !important"
                                    }
                                }}>
                                    <CustomButton disabled={true} fullWidth buttonName="Internal Wallet" />
                                </Box>
                            </Grid>
                        </>
                    </CustomModal>
                }

                {
                    <CustomModal handleClose={() => setIsCreateListings(false)} title="Create Listings" isModalOpen={isCreateListings} isShowActionButton={false}>
                        <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box>
                                    <CustomInput
                                        label="Tokens"
                                        name="tokens"
                                        type="number"
                                        value={createListingsformik?.values.tokens}
                                        onChange={createListingsformik.handleChange}
                                        error={
                                            createListingsformik.touched.tokens &&
                                            Boolean(createListingsformik.errors.tokens)
                                        }
                                        helperText={
                                            createListingsformik.touched.tokens && createListingsformik.errors.tokens
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box>
                                    <CustomInput label="Token listing price"
                                        type="number"
                                        value={createListingsformik?.values.tokenPrice}
                                        onChange={createListingsformik.handleChange}
                                        name="tokenPrice"
                                        error={
                                            createListingsformik.touched.tokenPrice &&
                                            Boolean(createListingsformik.errors.tokenPrice)
                                        }
                                        helperText={
                                            createListingsformik.touched.tokenPrice && createListingsformik.errors.tokenPrice
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box>
                                    <Typography>Expected amount ~ ${createListingsformik?.values.tokens * createListingsformik?.values.tokenPrice}</Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <CustomButton buttonName="Submit" onClick={createListingsformik.handleSubmit} />
                                </Box>
                            </Grid>
                        </>
                    </CustomModal>
                }
                {
                    <CustomModal handleClose={() => setIsBuyModalOpen(false)} title="Buy Property" isModalOpen={isBuyModalOpen} isShowActionButton={false}>
                        <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box>
                                    <CustomInput label="Number tokens to purchase" type="number"
                                        value={buyformik?.values.numTokenToPurchase}
                                        onChange={buyformik.handleChange}
                                        name="numTokenToPurchase"
                                        error={
                                            buyformik.touched.numTokenToPurchase &&
                                            Boolean(buyformik.errors.numTokenToPurchase)
                                        }
                                        helperText={
                                            buyformik.touched.numTokenToPurchase && buyformik.errors.numTokenToPurchase
                                        } />
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <CustomButton buttonName="Buy" onClick={buyformik.handleSubmit} />
                                </Box>
                            </Grid>
                        </>
                    </CustomModal>
                }
            </Box >
        </>
    )
};

export default ViewMyListing;