import React from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import { Box, Typography, useTheme } from '@mui/material'
import Lottie from 'lottie-react'
import bridgeAnimation from "../../asset/bridge_animation.json"

const Bridge = () => {
    const theme = useTheme();
    return (
        <CustomPaperBox>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "0 auto"
            }}>
                <Lottie animationData={bridgeAnimation} loop={true} style={{ height: "300px", width: "300px" }} />
                <Typography textAlign="center" fontSize="20px" color={`${theme.palette.primary.main}`}>The 1ByX Blockchain bridge is receiving a makeover to enhance your seamless asset movement experience. Thank you for your understanding</Typography>
            </Box>
        </CustomPaperBox>
    )
}

export default Bridge
