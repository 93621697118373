import React from 'react'
import CreateProperty from './CreateProperty'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import { Box, Grid, InputAdornment, MenuItem, Typography, useTheme } from '@mui/material'
import CustomInput from '../../theme/style-components/CustomInput'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CustomSelect from '../../theme/style-components/CustomSelect'
// import { DropzoneArea } from 'react-mui-dropzone'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLazyGetMyPropertyListingQuery } from '../../store/property/propertyApiSlice'
import { useEffect } from 'react'
import PropertyFeatures from './PropertyFeatures'
import PropertyImages from './PropertyImages'
import PropertyLocation from './PropertyLocation'
import PropertyFinancials from './PropertyFinancials'
import PropertyDocs from './PropertyDocs'
import ComponentTitle from '../shared/ComponentTitle'
import CustomButton from '../../theme/style-components/CustomButton'


const EditProperty = () => {
    const theme = useTheme();

    const { id } = useParams();

    const navigate = useNavigate();

    const [getMyPropertyListing, {
        isLoading: isGetPropertyLoading,
        data: property,
        isSuccess: isGetPropertySuccess,
        isError: isGetPropertyError,
        error: getPropertyError,
    }] = useLazyGetMyPropertyListingQuery();

    useEffect(() => {
        if (id) {
            getMyPropertyListing({ id: id, page: 1, query: "" })
        }
    }, [id]);

    const breadcrumbs = [
        <Link key="1" to="/my-listings">
            Properties
        </Link>,
        <Typography key="2" variant="body1">
            Edit Property
        </Typography>,
    ];

    return (
        <>
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <Box sx={{ textAlign: "end" }}><CustomButton buttonName="preview" onClick={() => navigate(`/my-listings/view/${id}`, {
                state: {
                    disabled: true
                }
            })} /></Box>
            {property && <CreateProperty property={property?.data[0]} id={id} />}
            <PropertyFeatures features={property?.data[0]?.features} id={id} />
            <PropertyImages id={id} images={property?.data[0]?.images} videos={property?.data[0]?.videos} />
            <PropertyLocation id={id} location={property?.data[0]?.location} />
            <PropertyFinancials id={id} financials={property?.data[0]?.financials} />
            <PropertyDocs id={id} documents={property?.data[0]?.documents} />
        </>
    )
}

export default EditProperty
