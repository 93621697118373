import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Box } from '@mui/material';
import { useState } from 'react';
import { urlWithoutPort } from '../store/config';
import CustomButton from '../theme/style-components/CustomButton';

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true); // Set loading to true when starting payment processing

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: urlWithoutPort + "payment-success",
            },
        });

        setIsLoading(false); // Set loading to false when payment processing is done

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            alert(result.error.message);
            //alert(urlWithoutPort + "success")
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <Box>
            <form>
                <PaymentElement />
                <Box sx={{ textAlign: "center", marginTop: '20px' }}>
                    <CustomButton
                        color="primary"
                        type="submit"
                        buttonName="Pay"
                        onClick={handleSubmit}
                        isLoading={isLoading}
                        disabled={isLoading} />
                </Box>
            </form>
        </Box>
    )
};

export default CheckoutForm;