import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import 'react-18-image-lightbox/style.css';
import RouteWrapper from "./routes/RouteWrapper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-vertical-timeline-component/style.min.css";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <RouteWrapper />
            <ToastContainer />
        </GoogleOAuthProvider>
    );
};

export default App;