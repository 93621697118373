import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, Menu, MenuItem } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useTheme } from '@mui/styles';
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { selectCurrentUser, setUser } from '../../store/auth/authSlice';
import { useGoogleLoginMutation } from '../../store/auth/authApiSlice';
import { useLazyGetUserProfileQuery } from '../../store/user-profile/userProfileApiSlice';
import { decryptFn, encryptFn } from '../shared/helperFunction';

const Header = ({ open, handleDrawer, setOpen, toggleTheme, themeMode }) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const profileMenu = anchorEl;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = decryptFn(localStorage.getItem("ACCESS_TOKEN"));


    const userDetails = useSelector(selectCurrentUser);


    const [googleLogin, { isLoading: isGoogleLogin, data: googleData,
        isSuccess: isGoogleLoginSuccess, isError: isGoogleLoginError }] = useGoogleLoginMutation();

    const [getUserProfile, { isLoading: isGetUserLoading, data: userData,
        isSuccess: isGetUserSuccess, isError: isGetUserError }] = useLazyGetUserProfileQuery();

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = async () => {
        // dispatch(logOut());
        // dispatch(setUserType(null));
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
        navigate("/login", { state: { from: location }, replace: true });
        localStorage.clear();
    };

    const handleProfile = async () => {
        navigate("/settings", { state: { from: location }, replace: true });
        setAnchorEl(null);
    };

    const theme = useTheme();

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        marginTop: '0px',
        backgroundImage: "none",
        boxShadow: 'none',
        background: theme.palette.mode === "light" ? 'rgb(244 247 254 / 57%)' : 'rgb(1 22 39 / 57%)',
        backdropFilter: 'blur(20px)',
        padding: "8px 0px !important",
        borderRadius: '0',
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            zIndex: '0',
            padding: '0px 24px',
            zIndex: '999',

        },
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - 90px)`,
            zIndex: '999'
        },

        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: 260,
            [theme.breakpoints.up('lg')]: {
                width: `calc(100% - 240px)`,
            },
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const callTheLogin = async (auth) => {
        try {
            const response = await googleLogin(auth);
        } catch (err) {
            toast.error("Google sign-in failed", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    useEffect(() => {
        if (!isGoogleLogin && isGoogleLoginSuccess && googleData) {
            if (googleData?.access) {
                localStorage.setItem("ACCESS_TOKEN", encryptFn(googleData?.access));
                localStorage.setItem("REFRESH_TOKEN", encryptFn(googleData?.refresh));
                callUserProfile();
            }
        };
    }, [isGoogleLogin, isGoogleLoginSuccess, googleData]);

    useEffect(() => {
        if (!isGetUserLoading && isGetUserSuccess && userData) {
            if (userData) {
                dispatch(setUser(userData?.data));
            }
        };
    }, [isGetUserLoading, isGetUserSuccess, userData]);

    const callUserProfile = async () => {
        try {
            const response = getUserProfile().unwrap();
        } catch (error) {
            toast.error(error?.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const handleGoogleLoginSuccess = (response) => {
        if (response?.credential) {
            callTheLogin(response?.credential)
        }
    };

    const handleGoogleLoginFailure = (error) => {
        toast.error("Google sign-in failed", {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    return (
        <AppBar position="fixed" open={open} onClick={() => open && setOpen(false)}>
            <Toolbar sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: '8px',
                justifyContent: "space-between",
                paddingLeft: '24px',
                paddingRight: '24px',
            }}>
                <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawer}
                    edge="start">
                    <MenuIcon />
                </IconButton>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '30px',
                    padding: '12px 20px',
                    background: theme.palette.background.paper,
                }}>
                    {/* <Box sx={{
                        '& .MuiInput-root': {
                            background: theme.palette.background.default,
                            padding: '4px 10px 4px 16px',
                            borderRadius: '30px',
                            '&:before': {
                                display: 'none',
                            },
                            '&:after': {
                                display: 'none',
                            },
                        },
                        '& .MuiInputBase-root': {
                            '& input': {
                                fontSize: '14px',
                                fontWeight: '500',
                            },
                            '&:after': {
                                display: 'none',
                            },
                        },
                    }}>
                        <FormControl variant="standard">
                            <Input
                                id="input-with-icon-adornment"
                                placeholder='Search...'
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box> */}
                    <IconButton sx={{
                        padding: '0',
                        marginRight: '10px',
                        '& svg': {
                            width: '18px',
                            height: '18px',
                        },
                    }}><NotificationsNoneIcon sx={{ cursor: 'pointer', width: '24px', height: '24px' }} /></IconButton>
                    <IconButton sx={{
                        padding: '0',
                        marginRight: '11px',
                        '& svg': {
                            width: '18px',
                            height: '18px',
                        },
                    }} onClick={toggleTheme}>
                        {themeMode === "light" ? <DarkModeIcon /> : <LightModeIcon />}</IconButton>



                    {!token && !userDetails?.id ? <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        onSuccess={handleGoogleLoginSuccess}
                        onError={handleGoogleLoginFailure}
                        scope="openid profile email"
                        cookiePolicy={'single_host_origin'}
                        shape="circle"
                        containerProps={{
                            style: {
                                width: "100% !important",
                            },
                        }}
                        useOneTap
                    /> : <Box
                        id="basic-button"
                        aria-controls={profileMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={profileMenu ? 'true' : undefined}
                        onClick={handleProfileClick}
                        sx={{
                            padding: "0px",
                            cursor: 'pointer',
                            width: "30px",
                            height: '30px',
                            flex: '0 0 30px',
                            // background: "#512ea8",
                            color: '#fff',
                            borderRadius: "100px",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& img': {
                                width: "100%",
                                height: '100%',
                                borderRadius: '100px'
                            }
                        }}
                    >
                        <img src={userDetails?.social_profile_pic} width="40px" height="40px" />
                    </Box>}

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={profileMenu}
                        onClose={handleClose} sx={{
                            '& .MuiMenu-paper': {
                                width: "200px",
                                backgroundColor: theme.palette.background.paper,
                                boxShadow: 'none',
                                borderRadius: "20px",
                                backgroundImage: "none !important",
                                color: "#dbdbeb99",
                                left: 'auto !important',
                                right: '24px',
                                top: '60px !important',
                                marginTop: '20px',
                                padding: '10px',
                                '& ul': {
                                    padding: '0',
                                    '& li': {
                                        background: 'transparent',
                                        color: theme.palette.text.primary,
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        '&:hover': {
                                            background: 'transparent',
                                        },
                                        '&:last-child': {
                                            color: theme.palette.error.main,
                                        },
                                    },
                                }
                            }
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                        }}
                    >
                        <MenuItem onClick={handleProfile}>
                            &#128075; Hey {userDetails?.first_name}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleSignOut}>
                            Sign Out</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar >
    );
};

export default Header;