import { TextField, useTheme } from '@mui/material'
import React from 'react'

const CustomInput = ({ name, id, min, required, readOnly, minRows, multiline, endAdornment, label, value, type, placeholder, onChange, onBlur, error, disabled, helperText, InputProps
}) => {
    const theme = useTheme();
    return (
        <TextField name={name} fullWidth sx={{
            "& input": {
                color: theme.palette.text.primary,
                padding: '10px 14px',
                paddingLeft: '17px',
            },
            // "& .Mui-disabled": {
            //     WebkitTextFillColor: "#dbdbeb61 !important",
            //     '& fieldset': {
            //         borderColor: '#dbdbeb61 !important',
            //     },
            // },
            "& .Mui-disabled": {
                fill: `${theme.palette.action.disabled} !important`,
                WebkitTextFillColor: `${theme.palette.action.disabled} !important`,
                '& fieldset': {
                    borderColor: `${theme.palette.action.disabled} !important`,
                    color: `${theme.palette.action.disabled} !important`,
                },
                "& .MuiFormControl-root": {
                    color: "red"
                },
            },
            '& fieldset': {
                borderColor: theme.palette.grey["300"],
                borderRadius: '20px',
            },
            '& .MuiInputBase-root,label': {
                color: theme.palette.text.secondary
            },
            '& svg': {
                fill: `${theme.palette.primary.main} !important`
            },
            '& .MuiMenu-list': {
                backgroundColor: `${theme.palette.primary.main} !important`
            },
            "& fieldset:hover": {
                borderColor: "white !important"
            },
            '& .MuiOutlinedInput-root': {
                '&:hover': {
                    '& fieldset': {
                        borderColor: `${theme.palette.primary.main} !important`,
                    },
                },
            },
            '& .Mui-focused': {
                '& fieldset': {
                    borderColor: `${theme.palette.primary.main} !important`,
                },
            },
            '& .MuiFormLabel-root': {
                '&.Mui-focused': {
                    color: theme.palette.text.secondary
                },
            },
            '& .MuiFormLabel-root': {
                top: '-6px',
                left: '8px',
                color: `${theme.palette.grey["400"]} !important`
            },
            '& .MuiInputLabel-shrink': {
                top: '0px',
                left: '0px',
                color: `${theme.palette.primary.main} !important`
            },
            "& .Mui-disabled.MuiInputLabel-shrink ": {
                color: `${theme.palette.action.disabled} !important`,
            },
            '& .Mui-disabled.MuiOutlinedInput-root': {
                '&:hover': {
                    '& fieldset': {
                        borderColor: `${theme.palette.action.disabled} !important`,
                    },
                },
            },
        }}
            id={id} inputProps={{
                readOnly: readOnly,
                min: 0
            }} variant="outlined" multiline={multiline} minRows={minRows} type={type} required={required} endadornment={endAdornment} onBlur={onBlur} placeholder={placeholder} disabled={disabled} error={error} value={value} label={label} helperText={helperText} onChange={onChange} InputProps={InputProps} />
    )
}

export default CustomInput;
