import { apiSlice } from "../apiSlice";

export const paymentApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        stripeIntentProperty: builder.mutation({
            query: (detail) => ({
                url: '/payments/stripe_listing_invest/',
                method: "POST",
                body: detail
            })
        }),
        stripeIntentBuyProperty: builder.mutation({
            query: (detail) => ({
                url: '/payments/stripe_market_buy/',
                method: "POST",
                body: detail
            })
        }),
        getSavedCard: builder.query({
            query: (id) => `/payments/saved_cards/?user_id=${id}`,
            keepUnusedDataFor: 5,
        }),
    }),
});

export const {
    useStripeIntentPropertyMutation,
    useLazyGetSavedCardQuery,
    useStripeIntentBuyPropertyMutation
} = paymentApiSlice;