import { apiSlice } from "../apiSlice";


export const dashboardApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardData: builder.query({
            query: () => `/user/dashboard/`,
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    useGetDashboardDataQuery,
} = dashboardApiSlice;