import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material'
import React from 'react'

const FinancialsTab = ({ propertyDetails }) => {
    const theme = useTheme();
    return (
        <Box>
            <Box sx={{
                '& .MuiPaper-root': {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    boxShadow: 'none',
                },
                marginBottom: "20px"
            }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">TOTAL INVESTMENT VALUE</TableCell>
                                <TableCell>${propertyDetails?.financials?.total_amount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Token price</TableCell>
                                <TableCell>${propertyDetails?.web3?.token_price}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Total tokens</TableCell>
                                <TableCell>${propertyDetails?.web3?.total_tokens}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Platform fee</TableCell>
                                <TableCell>${propertyDetails?.financials?.platform_fee}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{
                '& .MuiPaper-root': {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    boxShadow: 'none',
                },
                marginBottom: "20px"
            }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">UNDERLYING ASSET PRICE</TableCell>
                                <TableCell>${propertyDetails?.financials?.asset_price}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Maintainance fee</TableCell>
                                <TableCell>${propertyDetails?.financials?.maintenance_fee}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Anual Service fee	</TableCell>
                                <TableCell>${propertyDetails?.financials?.anual_service_charges}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Other taxes	</TableCell>
                                <TableCell>${propertyDetails?.financials?.other_taxes}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Other Fees</TableCell>
                                <TableCell>${propertyDetails?.financials?.other_fees}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{
                '& .MuiPaper-root': {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    boxShadow: 'none',
                },
                marginBottom: "20px"
            }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">PROJECTED ANUALIZED RETURN</TableCell>
                                <TableCell>${propertyDetails?.financials?.projected_anualized_return}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Anual gross rent</TableCell>
                                <TableCell>${propertyDetails?.financials?.anual_gross_rent}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Current Market Value</TableCell>
                                <TableCell>${propertyDetails?.financials?.current_market_value}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

export default FinancialsTab
