import React from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox';
import Lottie from 'lottie-react';
import { Box, Typography, useTheme } from '@mui/material';
import defiAnimation from "../../asset/defi_animation.json"


const Defi = () => {
    const theme = useTheme();
    return (
        <CustomPaperBox>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "0 auto"
            }}>
                <Lottie animationData={defiAnimation} loop={true} style={{ height: "300px", width: "300px" }} />
                <Typography textAlign="center" fontSize="20px" color={`${theme.palette.primary.main}`}>
                    The 1ByX DeFi protocol is undergoing enhancements to bring you a seamless borrowing experience against your real estate investments. Thank you for your patience
                </Typography>
            </Box>
        </CustomPaperBox>
    )
}

export default Defi
