import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        investerRegister: builder.mutation({
            query: (data) => ({
                url: "/user/register_investor/",
                method: "POST",
                // body: { ...data },
            }),
        }),
        devRegister: builder.mutation({
            query: (data) => ({
                url: "/user/register_developer/",
                method: "POST",
                body: { ...data },
            }),
        }),
        brokerRegister: builder.mutation({
            query: (data) => ({
                url: "/user/register_broker/",
                method: "POST",
                body: { ...data },
            }),
        }),
    }),
});

export const {
    useInvesterRegisterMutation,
    useDevRegisterMutation,
    useBrokerRegisterMutation
} = authApiSlice;