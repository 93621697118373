import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import cardImg from "../../asset/demo.png";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import TabTitle from "../shared/TabTitle";
import ComponentTitle from "../shared/ComponentTitle";
import { useGetPropertyListQuery } from "../../store/property/propertyApiSlice";
import { useState } from "react";
import { useEffect } from "react";
import CustomButton from "../../theme/style-components/CustomButton";
import soldoutImage from "../../asset/sold_out.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  activeButton: {
    background: "#1976d2 !important",
    color: "#ffffff !important",
    borderRadius: "10px !important",
    marginRight: "10px !important",
  },
  inactiveButton: {
    background: "#fff !important",
    color: "#1976d2 !important",
    borderRadius: "10px !important",
    marginRight: "10px !important",
  },
  avl: {
    background: "#1976d2 !important",
    color: "#ffffff !important",
    borderRadius: "10px !important",
    marginRight: "10px !important",
  },
  mainButton: {
    "& span": {
      display: "none !important",
    },
  },
  card: {
    marginTop: "40px",
  },
  cardBox: {
    borderRadius: "10px",
    cursor: "pointer",
    boxShadow: `0px 0px 5px ${theme.palette.background.default}`,
    background: theme.palette.background.paper,
    marginBottom: "16px",
    position: "relative",
  },
  cardbuttons: {
    // display: "flex",
    // alignItems: "center",
    gap: "10px",
    "& button": {
      fontSize: "11px !important",
      color: "#2e2e2e !important",
      border: "1px solid lightgray !important",
      borderRadius: "3px !important",
      padding: "3px 5px !important",
    },
  },

  cardImg: {
    height: "210px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "10px 10px 0 0",
    },
  },
  cardDetails: {
    padding: "15px",
  },
  cardTitle: {
    fontSize: "18px !important",
    margin: "10px 0 !important",
    fontWeight: "500 !important",
    color: "#1c1c1c !important",
    marginBottom: "10px !important",
  },
  progressbarHeading: {
    fontWeight: "600 !important",
    fontSize: "20px !important",
    color: "#1976d2",
  },
  progressbardata: {
    fontSize: "12px !important",
    color: "gray !important",
    margin: "0 !important",
  },
  progressbar_Details: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  data: {
    background: "#f6f7f9",
    borderRadius: "10px",
    padding: "10px",
    marginTop: "10px",
  },
  data_details: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px 0",
  },
  dataHeading: {
    fontSize: "14px",
    color: "gray",
  },
  datapasenteg: {
    fontSize: "14px",
    color: "#000",
    fontWeight: "500",
  },
}));

const NewListings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const [listingStatus, setListingStatus] = useState("AVAILABLE");
  const [page, setPage] = useState(1);
  const [propertyData, setPropertyData] = useState([]);
  const [query, setQuery] = useState("");

  const redirectToDetails = (id, type) => {
    navigate(`/new-listings/${id}`, {
      state: {
        type: type,
      },
    });
  };

  console.log(listingStatus, "listingStatus");

  //base query hooks
  const {
    isLoading,
    data: propertyReturned,
    isSuccess,
    isError,
    refetch: refetchProperty,
  } = useGetPropertyListQuery({
    listing_status: listingStatus,
    page: page,
    query: query,
  });

  const breadcrumbs = [
    <Typography key="1" variant="body1">
      Properties
    </Typography>,
  ];

  useEffect(() => {
    if (!isLoading && isSuccess && propertyReturned) {
      setPropertyData(propertyReturned?.data);
    }
  }, [isLoading, isSuccess, propertyReturned]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.text,
    },
  }));

  return (
    <>
      <TabTitle title="Properties" />
      <ComponentTitle breadcrumbs={breadcrumbs} />
      <Box sx={{ width: "100%" }}>
        <ButtonGroup
          sx={{
            gap: "10px",
            "& button": {
              border: `1px solid ${theme.palette.primary.main} !important`,
              borderRadius: "16px !important",
            },
          }}
          variant="outlined"
          aria-label="outlined button group"
        >
          <Button
            sx={{
              backgroundColor:
                listingStatus === "AVAILABLE"
                  ? `${theme.palette.primary.main} !important`
                  : "inherit !important",
              color:
                listingStatus === "AVAILABLE"
                  ? "white !important"
                  : `${theme.palette.primary.main} !important`,
              "&:hover": {
                backgroundColor: "unset",
              },
            }}
            color="primary"
            onClick={() => setListingStatus("AVAILABLE")}
          >
            Available
          </Button>
          <Button
            sx={{
              backgroundColor:
                listingStatus === "SOLDOUT"
                  ? `${theme.palette.primary.main} !important`
                  : "inherit !important",
              color:
                listingStatus === "SOLDOUT"
                  ? "white !important"
                  : `${theme.palette.primary.main} !important`,
              "&:hover": {
                backgroundColor: "unset",
              },
            }}
            onClick={() => setListingStatus("SOLDOUT")}
          >
            Funded
          </Button>
        </ButtonGroup>
      </Box>

      {propertyData?.length > 0 && !isLoading && (
        <Box className={classes.card}>
          <Grid container spacing={2}>
            {propertyData.map((prop) => {
              return (
                <Grid key={prop.id} item lg={4} md={4} sm={12}>
                  <Box
                    className={classes.cardBox}
                    onClick={() =>
                      redirectToDetails(prop.id, prop.listing_status)
                    }
                  >
                    <Box className={classes.cardImg}>
                      <img src={prop?.images[0]?.image} />
                    </Box>
                    <Box className={classes.cardDetails}>
                      <Box
                        sx={{
                          marginBottom: "10px",
                        }}
                        className={classes.cardbuttons}
                      >
                        <Box marginBottom="10px">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              "& h3": {
                                color: theme.palette.primary.main,
                                fontSize: "18px",
                                fontWeight: "bold",
                                "& span": {
                                  fontSize: "12px",
                                  marginRight: "5px",
                                },
                              },
                              "& p": {
                                color: theme.palette.text.primary,
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                            }}
                          >
                            <Typography>Property price</Typography>
                            <Typography variant="h3">
                              <span>$</span>
                              {prop?.financials?.total_amount}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              margin: "6px 0",
                            }}
                          >
                            <BorderLinearProgress
                              variant="determinate"
                              value={
                                (prop?.tokenomics?.sold_tokens * 100) /
                                prop?.tokenomics?.total_tokens
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              "& p": {
                                color: "#08165d",
                                fontSize: "14px",
                                fontWeight: "300",
                                color: theme.palette.text.primary,
                              },
                            }}
                          >
                            <Typography>
                              {(prop?.tokenomics?.sold_tokens * 100) /
                                prop?.tokenomics?.total_tokens}
                              % Funded
                            </Typography>
                            {/* <Typography>{progressValue}%</Typography> */}
                            <Typography>
                              {prop?.tokenomics?.available_tokens} tokens left
                            </Typography>
                            {/* <Typography>{propertyDetails?.web3?.tokens_left} tokens left</Typography> */}
                          </Box>
                        </Box>
                        {prop?.property_type && (
                          <Chip
                            sx={{
                              marginRight: "10px",
                              marginBottom: "10px",
                              "& span": {
                                textTransform: "capitalize",
                              },
                            }}
                            label={prop?.property_type?.toLowerCase()}
                            variant="outlined"
                          />
                        )}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          {prop.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#787373",
                            fontWeight: "600",
                            fontSize: "12px",
                            marginBottom: "10px",
                          }}
                        >
                          {prop?.location?.address?.substring(0, 55)}...
                        </Typography>
                        {/* <Typography sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                            }}>
                                                Anual Gross Rent {prop?.financials?.anual_gross_rent}%
                                            </Typography> */}
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Projected Annual Return{" "}
                          <Typography variant="span" fontWeight="bold">
                            {prop?.financials?.projected_anualized_return}%{" "}
                          </Typography>
                        </Typography>
                        {/* <Typography sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                            }}>
                                                Token Price ${(prop?.tokenomics?.token_price_aed_cents) / 100}
                                            </Typography>
                                            <Typography sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                            }}>
                                                Available Tokens {(prop?.tokenomics?.available_tokens)}
                                            </Typography> */}
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Tokens Sold{" "}
                          <Typography variant="span" fontWeight="bold">
                            {prop?.tokenomics?.sold_tokens}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        background: theme.palette.primary.main,
                        borderRadius: " 0px 0 10px 10px",
                        padding: "10px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: theme.palette.text.primary,
                        }}
                      >
                        {/* Available: {prop?.web3?.tokens_left} tokens at ${prop?.web3?.token_price} */}
                        {/* Available: $ {prop?.financials?.asset_price} */}
                        Available {prop?.tokenomics?.available_tokens} Tokens at
                        AED {prop?.tokenomics?.token_price_aed_cents / 100}
                      </Typography>
                    </Box>
                    {prop.listing_status === "SOLDOUT" && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                          "& img": {
                            width: "70%",
                          },
                        }}
                      >
                        <img src={soldoutImage} alt="sold-out" />
                      </Box>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
      {!isLoading && propertyData?.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Typography>No record Found</Typography>
        </Box>
      )}
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
    </>
  );
};

export default NewListings;
