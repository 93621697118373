import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CustomChips from '../../theme/style-components/CustomChips';
import CustomLoader from '../../theme/style-components/CustomLoader';
import { useDeleteMyListingMutation, useGetMyPropertyListingQuery } from '../../store/property/propertyApiSlice';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { showError } from '../shared/helperFunction';
import CustomTable from '../../theme/style-components/CustomTable';

const MyListings = () => {
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [searchText, setSearchText] = useState("");
    const [count, setCount] = useState();
    const [selectedListing, setSelectedListing] = useState({});

    const theme = useTheme();

    //base query hooks
    const {
        isLoading,
        data: myListingReturned,
        isSuccess,
        isError,
        refetch
    } = useGetMyPropertyListingQuery({ page: page, query: query });

    console.log(query, "query");

    const [deleteMyListing] = useDeleteMyListingMutation();


    const [myListingData, setMyListingData] = useState([]);

    const navigate = useNavigate();

    const columns = [
        { id: 'id', label: 'Id' },
        { id: 'property', label: 'Property' },
        { id: 'location', label: 'Location' },
        { id: 'tenant_status', label: 'Tenant Status' },
        { id: 'tokenomics', label: 'Tokenomics' },
        { id: 'status', label: 'Status' },
        { id: 'action', label: 'Action' },
    ];

    const renderStatus = (status) => {
        if (status === "SOLDOUT") {
            return <CustomChips label="Soldout" labelColor="error" color="error" />
        } if (status === "AVAILABLE") {
            return <CustomChips label="Enable" labelColor="primary" color="primary" />
        } if (status === "DISABLED") {
            return <CustomChips label="Disabled" labelColor="warning" color="warning" />
        }
    }

    const data = myListingData?.length > 0 ? myListingData?.map((data) => ({
        id: data.id,
        property: <Box sx={{
            "& a": {
                textDecoration: "none",
                color: theme.palette.primary.main,
                fontWeight: "bold"
            }
        }}><Link to={`/my-listings/view/${data.id}`}>{data?.name}</Link></Box>,
        location: data?.location?.address ? data?.location?.address : "NA",
        tenant_status: <Typography sx={{ textTransform: "capitalize" }}>{data?.tenant_status?.toLowerCase()}</Typography>,
        tokenomics: <Box>
            <Typography>Token price: {data?.tokenomics?.available_tokens ? `$${data?.tokenomics?.token_price_aed_cents / 100}` : "NA"}</Typography>
            <Typography>Total tokens: {data?.tokenomics?.available_tokens ? data?.tokenomics?.total_tokens : "NA"}</Typography>
            <Typography>Available Tokens: {data?.tokenomics?.available_tokens ? data?.tokenomics?.available_tokens : "NA"}</Typography>
            <Typography>sold_tokens: {data?.tokenomics?.available_tokens ? data?.tokenomics?.sold_tokens : "NA"}</Typography>
        </Box>,
        status: renderStatus(data?.listing_status),
        action: "ACTION"
    })) : []

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setMyListingData(myListingReturned?.data);
            setCount(myListingReturned?.page_details?.count)
        }
    }, [isLoading, page, myListingReturned?.data]);

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const setSelectedData = (item) => {
        setSelectedListing(item)
    };

    const handleCreateClick = () => {
        navigate("/my-listings/create-properties")
    };

    const handleEditClick = () => {
        navigate(`/my-listings/edit-properties/${selectedListing?.id}`)
    };

    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&id=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&id=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
    };


    const handleDelteClick = () => {
        console.log("click on delete");
        const id = selectedListing.sn
        swal({
            title: 'Are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            buttons: {
                cancel: 'Cancel',
                confirm: 'Delete',
            },
            dangerMode: true,
        }).then(async (confirmed) => {
            if (confirmed) {
                // User clicked the 'OK' button
                // Perform the desired action
                const response = await deleteMyListing(id).unwrap().then((res) => {
                    const filtered = myListingData.filter((item) => item.id !== id);
                    if (filtered?.length) {
                        setMyListingData(filtered)
                    }
                    res && toast.success("Listing deleted successfully!!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }).catch((err) => {
                    showError(err?.data)
                });
            } else {
                // User clicked the 'Cancel' button
                // Handle the cancellation
            }
        });
    };

    return (
        <>
            <CustomTable
                //pagination props
                page={page}
                tableTitle="My listings"
                count={count}
                rowsPerPage={rowsPerPage}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}

                //create button props
                handleCreateClick={handleCreateClick}
                createButtonName="Create Property"

                //edit menu props
                isShowEditMenu={true}
                handleEditClick={handleEditClick}

                //delete menu props
                isShowDeleteMenu={true}
                handleDelteClick={handleDelteClick}

                //action props
                isActionVisible={true}

                //data props
                data={data}
                columns={columns}

                isLoading={isLoading}

                //search props
                isShowSearch={true}
                handleOnSearch={handleOnSearch}
                searchText={searchText}
                searchPlaceholder="Search by id"

                //set Selected row data 
                setSelectedData={setSelectedData}

            />
        </>
    )
}

export default MyListings;
