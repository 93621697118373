import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Chip, Grid, Menu, MenuItem, TablePagination, useTheme } from '@mui/material';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SmsIcon from '@mui/icons-material/Sms';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CustomButton from './CustomButton';
import CustomInput from './CustomInput';
import TableSkeleton from './CustomTableSkeleton';

const Row = (props) => {
    const { row, handleAction, collapseTitle, isCollapse, innerTableData, innerTableHeaders, isActionVisible, columns, index, setSelectedData } = props;
    const [open, setOpen] = useState(false);

    return (
        <TableRow key={`inner-row-${index}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
            {isCollapse && <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>}
            {columns.map((column, colIndex) => {
                if (column.isChips) {
                    if (column.tableName === "MERCHANT") {
                        return (
                            <TableCell key={colIndex} component="th" scope="row">
                                <Chip label={row[column.id]} color={row[column.id] === "Enable" ? "primary" : "error"} labelColor="primary" />
                            </TableCell>
                        )
                    }
                } else {
                    return (
                        row[column.id] === "ACTION" ? isActionVisible &&
                            <TableCell key={colIndex} component="th" scope="row" >
                                <MoreVertIcon onClick={(e) => {
                                    handleAction(e)
                                    setSelectedData(row)
                                }} sx={{
                                    cursor: 'pointer'
                                }} />
                            </TableCell> : <TableCell key={colIndex} component="th" scope="row">
                            {row[column.id]}
                        </TableCell>
                    )
                }
            })}
        </TableRow >
    );
}

function CustomTable({ searchText, collapseTitle, tableTitle, isLoading, isShowCreateButton = true, innerTableHeaders, handlePrintInvoiceClick, isShowPrintInvoiceMenu, innerTableData, isShowExportCSV, handleExport, title, setSelectedData, isShowEditMenu, handleEditClick, isShowDeleteMenu, handleDelteClick, isShowChatMenu, handleChatClick, handleOnSearch, searchPlaceholder, createButtonName, columns, balance, data, isShowBalance, rowsPerPageOptions = [15, 25, 50], isActionVisible = true, isCollapse, isShowSearch = true, isPagination = true, handleCreateClick, count, page, rowsPerPage, onRowsPerPageChange, onPageChange, handlePrintClick, isShowPrintMenu, isShowAssignBranger, handleAssignBrangerClick }) {

    const [anchorEl, setAnchorEl] = useState(false);
    const isMenuOpen = anchorEl;

    const handleAction = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        onPageChange(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        onRowsPerPageChange(parseInt(event.target.value, 10))
        onPageChange(1)
    };

    const displayedData = data
    const theme = useTheme();

    console.log(theme, "theme ");

    return (
        <Box sx={{
            background: theme.palette.background.paper,
            borderRadius: '20px',
            marginTop: '30px',
        }}>
            <Box sx={{
                padding: '20px 20px',
                paddingBottom: '0',
                marginBottom: "30px"
            }}>
                <Grid container spacing={2} sx={{
                    justifyContent: 'space-between'
                }}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Typography sx={{
                            fontSize: '1.2rem',
                            fontWeight: 600,
                        }}>{tableTitle}</Typography>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "end",
                            gap: "10px"
                        }}>
                            {isShowExportCSV && <CustomButton style={{ marginRight: "10px" }} buttonName="Export" color="secondary" outline onClick={handleExport} />}
                            {isShowCreateButton && <CustomButton buttonName={createButtonName} color="primary" onClick={handleCreateClick} />}
                        </Box>
                    </Grid>

                    {isShowSearch && <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{
                            width: '250px',
                        }}>
                            <CustomInput type="search" value={searchText} placeholder={searchPlaceholder} onChange={handleOnSearch} />
                        </Box>
                    </Grid>}
                </Grid>
            </Box>
            <Box sx={{
                overflow: 'auto'
            }}>
                <Table>
                    <TableHead sx={{
                        '& th': {
                            borderBottomColor: '#dbdbeb1f',
                            textAlign: 'left',
                            borderBottom: "1px solid #e2e8f0",
                            color: theme.palette.secondary.light,
                            textTransform: "uppercase",
                            fontWeight: "600"
                        }
                    }}>
                        <TableRow>
                            {isCollapse && <TableCell />}
                            {columns.map((column, index) => (
                                <TableCell key={index}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{
                        '& td,th': {
                            borderBottomColor: '#dbdbeb1f',
                            textAlign: 'left',
                            '&:first-of-type': {
                                width: '50px',
                            }
                        }
                    }}>
                        {isLoading && <TableSkeleton columnCount={columns?.length} />}
                        {displayedData?.length === 0 ?
                            <TableRow>
                                <TableCell colSpan={columns?.length + 1}>
                                    <Typography variant="body1" align="center">
                                        No data available.
                                    </Typography>
                                </TableCell>
                            </TableRow> :
                            displayedData.map((row, index) => (
                                <Row collapseTitle={collapseTitle} innerTableHeaders={innerTableHeaders} innerTableData={innerTableData} index={index} key={`main-row-${index}`} setSelectedData={setSelectedData} isActionVisible={isActionVisible} columns={columns} handleAction={handleAction} isCollapse={isCollapse} row={row} />
                            ))
                        }
                    </TableBody>
                </Table>
            </Box>
            {isPagination && <TablePagination
                rowsPerPageOptions={false}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={handleClose}

                sx={{
                    '& .MuiMenu-paper': {
                        minWidth: "130px",
                        backgroundColor: theme?.palette?.background?.default,
                        color: theme.palette.text.primary,
                    },
                    '& .MuiMenuItem-root': {
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main
                        }
                    }
                }}

                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {isShowChatMenu && <MenuItem onClick={handleChatClick}>
                    <SmsIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Chat
                </MenuItem>}

                {isShowEditMenu && <MenuItem onClick={handleEditClick}>
                    <EditIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Edit
                </MenuItem>}

                {isShowDeleteMenu && <MenuItem onClick={handleDelteClick}>
                    <DeleteIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Delete
                </MenuItem>}
            </Menu>
        </Box >
    );
}

export default CustomTable;