import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';

const DocumentsTab = ({ propertyDetails }) => {
    const theme = useTheme();

    return (
        <Box>
            <Box sx={{
                '& .MuiPaper-root': {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    boxShadow: 'none',
                },
                marginBottom: "20px"
            }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {propertyDetails.documents
                                ? propertyDetails.documents.map((element, index) => {
                                    console.log("Entered");
                                    // Return the element. Also pass key
                                    return (
                                        <TableRow key={"document" + index}>
                                            <TableCell >{element.document_name
                                                ? element.document_name
                                                : "unnamed doc"}</TableCell>
                                            <TableCell sx={{
                                                textDecoration: "none",
                                                color: theme.palette.text.primary,
                                                "& svg": {
                                                    fill: theme.palette.text.primary
                                                }
                                            }}><a
                                                href={
                                                    element
                                                        ? element.document
                                                            ? process.env.REACT_APP_BACKEND_URL + element.document
                                                            : ""
                                                        : ""
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                key={"document" + index}
                                            >
                                                    <DownloadIcon />
                                                </a></TableCell>
                                        </TableRow>
                                    );
                                })
                                : ""}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

export default DocumentsTab
