// useFormikForm.js
import { useFormik } from 'formik';

const useCustomFormik = (initialValues, validationSchema, onSubmit) => {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return formik;
};

export default useCustomFormik;
