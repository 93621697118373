import { Chip, useTheme } from '@mui/material';
import React from 'react'

const CustomChips = ({ label, color }) => {
    const theme = useTheme();

    const bgMappings = {
        primary: theme.palette.primary.main,
        success: theme.palette.success.main,
        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        info: theme.palette.info.main,
    };

    return (
        <Chip
            sx={{
                backgroundColor: bgMappings[color] || "",
                padding: '7px 0px',
                borderRadius: '4px',
                marginBottom: "5px",
                borderRadius: "20px",
                '& span': {
                    textTransform: 'capitalize',
                    padding: '7px 10px',
                    color: theme?.palette?.common?.white
                }
            }}
            label={label} />
    )
}

export default CustomChips
