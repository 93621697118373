import { Box, Button, CircularProgress, useTheme } from '@mui/material'
import React from 'react'

const CustomButton = ({ buttonName, startIcon, fullWidth = false, color, disabled, onClick, outline, isLoading, }) => {
    const theme = useTheme();
    console.log(theme, "theme");
    const colorMappings = {
        primary: theme.palette.primary.main,
        success: theme.palette.success.main,
        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        info: theme.palette.info.main,
    };

    const outlineColorMappings = {
        primary: theme.palette.primary.main,
        success: theme.palette.success.main,
        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        info: theme.palette.info.main,
    };

    return (
        <Box sx={{
            display: "inline-block"
        }}>
            {!outline && <Button startIcon={startIcon} fullWidth={fullWidth} sx={{
                backgroundColor: disabled ? theme.palette.grey["900"] : colorMappings[color] || "",
                color: disabled ? theme.palette.grey["900"] : `${theme.palette.common.white} !important`,
                textTransform: "capitalize",
                padding: '7px 20px',
                borderRadius: "20px",
                '&:hover': {
                    backgroundColor: disabled ? "#dbdbeb1f" : outlineColorMappings[color] || "",
                }
            }}
                variant="contained"
                onClick={onClick}
                disabled={disabled}>
                {isLoading ? <CircularProgress size={14} sx={{ marginRight: "5px" }} color="inherit" /> : ""}
                {buttonName}
            </Button>}
            {outline && <Button startIcon={startIcon} fullWidth={fullWidth} sx={{
                backgroundColor: 'transparent',
                color: `${colorMappings[color]}`,
                borderRadius: "20px",
                padding: '7px 20px',
                border: `1px solid ${colorMappings[color]}`,
                '&:hover': {
                    backgroundColor: disabled ? "#dbdbeb1f" : outlineColorMappings[color] || "",
                    color: theme.palette.common.white,
                }
            }}
                onClick={onClick}
                disabled={disabled}>
                {isLoading ? <CircularProgress size={14} sx={{ marginRight: "5px" }} color="inherit" /> : ""}
                {buttonName}
            </Button>}
        </Box>
    )
}

export default CustomButton;


