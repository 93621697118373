import * as yup from "yup";

export const investValidationSchema = yup.object({
    tokenAmount: yup.number().required('Field is required')
        .test('is-not-zero', 'Token count must not be zero', (value) => value !== 0),
});

export const buyValidationSchema = yup.object({
    numTokenToPurchase: yup.number().required('Field is required')
        .test('is-not-zero', 'Token count must not be zero', (value) => value !== 0),
});

export const createValidationSchema = yup.object({
    tokens: yup.number().required('Field is required').test('is-not-zero', 'Token count must not be zero', (value) => value !== 0),
    tokenPrice: yup.number().required('Field is required').test('is-not-zero', 'Token price must not be zero', (value) => value !== 0),
});


export const videoURLValidationSchema = yup.object({
    videoURLs: yup.array().of(yup.string().url('Invalid URL').required('Field is required'))
});