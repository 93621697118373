import React from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import { Box, Grid } from '@mui/material'
import CustomInput from '../../theme/style-components/CustomInput'
import CustomButton from '../../theme/style-components/CustomButton'

const DevPublicView = () => {
    return (
        <CustomPaperBox>
            <Grid container spacing={2}>
                <Grid item xl={5} lg={5} md={12} sm={12} xs={12} >
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <CustomInput label="Title" />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <CustomInput label="Description" />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <CustomInput label="Web Link" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomPaperBox>
    )
}

export default DevPublicView
