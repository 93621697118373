import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../view/dashboard/Dashboard";
import Wallet from "../view/wallet/Wallet";
import PublicRoute from "./PublicRoutes";
import PrivateRoute from "./PrivateRoutes";
import Login from "../view/login/Login";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CreateProperty from "../view/my-listings/CreateProperty";
import EditProperty from "../view/my-listings/EditProperty";
import Transactions from "../view/transactions/Transactions";
import Settings from "../view/settings/Settings";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Person4Icon from '@mui/icons-material/Person4';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DevPublicView from "../view/public-view/DevPublicView";
import { selectCurrentUser } from "../store/auth/authSlice";
import Register from "../view/login/Register";
import MyListings from "../view/my-listings/MyListings";
import SpecialRoutes from "./SpecialRoutes";
import FindBrokers from "../view/find-broker/FindBrokers";
import FindDevelopers from "../view/find-dev/FindDevelopers";
import MarketPlace from "../view/market-place/MarketPlace";
import Bridge from "../view/bridge/Bridge";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import MarketPlaceDetails from "../view/market-place/MarketPlaceDetails";
import NewListings from "../view/new-listing/NewListings";
import NewListingDetails from "../view/new-listing/NewListingDetails";
import BrokerDetails from "../view/find-broker/BrokerDetails";
import DeveloperDetails from "../view/find-dev/DeveloperDetails";
import InvestPay from "../view/invest/InvestPay";
import PaymentSuccess from "../checkout/PaymentSuccess";
import ViewListIcon from '@mui/icons-material/ViewList';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Defi from "../view/defi/Defi";
import BuyPropertyPayment from "../view/payment-page/buy-property/BuyPropertyPayment";
import ViewMyListing from "../view/my-listings/ViewMyListing";
import MyProperties from "../view/my-properties/MyProperties";
import ViewMyProperty from "../view/my-properties/ViewMyProperty";

export const routes = [
    {
        name: "Dashboard",
        path: "/dashboard",
        ele: <Dashboard />,
        icon: <DashboardIcon />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
        isMenu: true,
    },
    {
        name: "My Listings",
        path: "/my-listings",
        ele: <MyListings />,
        icon: <HomeWorkIcon />,
        permissions: ["REALESTATE_DEV"],
        isMenu: true,
    },
    {
        name: "New Listings",
        path: "/new-listings",
        ele: <NewListings />,
        icon: <ViewListIcon />,
        permissions: ["INVESTOR"],
        isMenu: true,
    },
    {
        name: "New Listings",
        path: "/new-listings/:id",
        ele: <NewListingDetails />,
        icon: <HomeWorkIcon />,
        permissions: ["INVESTOR", "REALESTATE_DEV", "REALESTATE_BROKER"],
    },
    {
        name: "Marketplace",
        path: "/market-place",
        ele: <MarketPlace />,
        icon: <AddBusinessIcon />,
        permissions: ["INVESTOR"],
        isMenu: true,
    },
    {
        name: "Marketplace Details",
        path: "/market-place/:id",
        ele: <MarketPlaceDetails />,
        icon: <AddBusinessIcon />,
        permissions: ["INVESTOR"],
    },
    {
        name: "My Properties",
        path: "/properties",
        ele: <MyProperties />,
        icon: <HomeWorkIcon />,
        permissions: ["REALESTATE_BROKER"],
        isMenu: true,
    },
    {
        name: "View Property",
        path: "/properties/view/:id",
        ele: <ViewMyProperty />,
        permissions: ["REALESTATE_BROKER"],
    },
    {
        name: "Find Developers",
        path: "/find-developers",
        ele: <FindDevelopers />,
        icon: <PermContactCalendarIcon />,
        permissions: ["REALESTATE_BROKER"],
        isMenu: true,
    },
    {
        name: "Find Brokers",
        path: "/find-brokers",
        ele: <FindBrokers />,
        icon: <Person4Icon />,
        permissions: ["REALESTATE_DEV"],
        isMenu: true,
    },
    {
        name: "Transactions",
        path: "/transactions",
        ele: <Transactions />,
        icon: <ReceiptLongIcon />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
        isMenu: true,
    },
    {
        name: "Wallet",
        path: "/wallet",
        ele: <Wallet />,
        icon: <AttachMoneyIcon />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
        isMenu: true
    },
    {
        name: "Defi",
        path: "/defi",
        ele: <Defi />,
        icon: <SettingsInputSvideoIcon />,
        permissions: ["INVESTOR"],
        isMenu: true,
    },
    {
        name: "Bridge",
        path: "/bridge",
        ele: <Bridge />,
        icon: <AcUnitIcon />,
        permissions: ["INVESTOR"],
        isMenu: true,
    },
    {
        name: "Create Properties",
        path: "/my-listings/create-properties",
        ele: <CreateProperty />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
    },
    {
        name: "Edit Properties",
        path: "/my-listings/edit-properties/:id",
        ele: <EditProperty />,
        permissions: ["REALESTATE_DEV"],
    },
    {
        name: "View My listing",
        path: "/my-listings/view/:id",
        ele: <ViewMyListing />,
        permissions: ["REALESTATE_DEV"],
    },
    {
        name: "Broker Details",
        path: "/find-brokers/:id",
        ele: <BrokerDetails />,
        permissions: ["REALESTATE_DEV"],
    },
    {
        name: "Developer Details",
        path: "/find-developers/:id",
        ele: <DeveloperDetails />,
        permissions: ["REALESTATE_BROKER"],
    },
    {
        name: "Settings",
        path: "/settings",
        ele: <Settings />,
        icon: <SettingsIcon />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
        isMenu: true,
    },
    {
        name: "Invest pay",
        path: "/invest-pay",
        ele: <InvestPay />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
    },
    {
        name: "Payment Sucesss",
        path: "/payment-success",
        ele: <PaymentSuccess />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
    },
    {
        name: "Dev Public View",
        path: "/dev-public-view",
        ele: <DevPublicView />,
        icon: <SettingsIcon />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
    },
    {
        name: "Buy property payment",
        path: "/buy-property/:id",
        ele: <BuyPropertyPayment />,
        permissions: ["REALESTATE_DEV", "REALESTATE_BROKER", "INVESTOR"],
    },
    // {
    //     name: "Broker Public View",
    //     path: "/broker-public-view",
    //     ele: <BrokerPublicView />,
    //     icon: <SettingsIcon />,
    //     permissions: ["DEVELOPER", "BROKER", "INVESTOR"],
    // },

];

const RouteWrapper = () => {
    const currentUser = useSelector(selectCurrentUser);

    const filteredRoutes = routes.filter(route =>
        currentUser?.roles?.some(role => route.permissions.includes(role))
    );

    return (
        <Box>
            <Routes>
                <Route path="login" element={<PublicRoute><Login /></PublicRoute>} />
                <Route path="register" element={<SpecialRoutes><Register /></SpecialRoutes>} />
                {filteredRoutes?.length > 0 ?
                    filteredRoutes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <PrivateRoute permissions={route.permissions}>
                                        {route.ele}
                                    </PrivateRoute>
                                }
                            />
                        );
                    }) : <Route path="*" element={<Navigate to="/login" />} />}
                <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
        </Box>
    );
};

export default RouteWrapper;
