import { Box, Breadcrumbs, Stack } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from '@mui/styles';

const ComponentTitle = ({ breadcrumbs }) => {
    const theme = useTheme();

    return (
        <Box sx={{
            padding: '0px 0 20px 0',
        }}>
            <Stack spacing={2}>
                <Breadcrumbs
                    sx={{
                        "& a": {
                            color: theme.palette.text.primary,
                            fontSize: "1.2rem",
                            textDecoration: "none",
                            fontWeight: 600
                        },
                        "& a:hover": {
                            color: theme.palette.text.primary,
                            fontSize: "1.2rem",
                            textDecoration: "underline",
                            fontWeight: 600
                        },
                        "& p": {
                            color: theme.palette.primary.main, fontSize: "1.2rem",
                            fontWeight: 600
                        },
                        "& svg": {
                            fill: theme.palette.primary.main,
                        }
                    }}
                    separator={<NavigateNextIcon sx={{ fill: '#dbdbeb99', }} fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
        </Box >
    )
}

export default ComponentTitle;
