import { apiSlice } from "../apiSlice";

export const marketPlaceApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMarketplace: builder.query({
            query: ({ id, page, query }) => `/property/marketplace/get_marketplace/${page ? `?page=${page}` : ""}${id ? `&id=${id}` : ""}${query ? query : ""}`,
            keepUnusedDataFor: 0,
        }),
        getMarketplaceListings: builder.query({
            query: ({ id }) => `/property/marketplace/get_marketplace_listings/${id ? `?id=${id}` : ""}`,
            keepUnusedDataFor: 0,
        }),
        getMyMarketplace: builder.query({
            query: ({ id, page, query }) => `/property/marketplace/get_my_marketplace/${page ? `?page=${page}` : ""}${id ? `&id=${id}` : ""}${query ? query : ""}`,
            keepUnusedDataFor: 0,
        }),
        listOnMarketplace: builder.mutation({
            query: ({ property_id, expected_price_per_token_aed_cents, tokens }) => ({
                url: "/property/marketplace/listing_on_marketplace/",
                method: "POST",
                body: {
                    property_id: property_id,
                    expected_price_per_token_aed_cents: expected_price_per_token_aed_cents,
                    tokens: tokens
                },
            })
        }),
        buyProperty: builder.mutation({
            query: ({ property_id, num_tokens_to_purchase, listing_id }) => ({
                url: "/payments/stripe_market_buy/",
                method: "POST",
                body: {
                    property_id: property_id,
                    num_tokens_to_purchase: num_tokens_to_purchase,
                    listing_id: listing_id
                },
            })
        }),
    }),
});

export const {
    useGetMarketplaceQuery,
    useGetMarketplaceListingsQuery,
    useGetMyMarketplaceQuery,
    useBuyPropertyMutation,
    useListOnMarketplaceMutation
} = marketPlaceApiSlice;