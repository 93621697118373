import React, { useRef, useState } from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import CustomInput from '../../theme/style-components/CustomInput'
import { Avatar, Box, Button, Grid, InputAdornment, MenuItem, Tooltip, Typography, useTheme, tooltipClasses } from '@mui/material'
import CustomButton from '../../theme/style-components/CustomButton'
import { useFormik } from 'formik'
import * as yup from "yup";
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../store/auth/authSlice'
import { useEffect } from 'react'
import CustomSelect from '../../theme/style-components/CustomSelect'
import { toast } from 'react-toastify'
import { showError } from '../shared/helperFunction'
import { useUpdateBrokerProfileMutation, useUpdateDevProfileMutation, useUpdateUserProfileMutation } from '../../store/settings/settingsApiSlice'
import CustomCheckBox from '../../theme/style-components/CustomCheckBox';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/styles'

const Settings = () => {
    //ref variables
    const inputRef = useRef(null);
    const [avatarPreview, setAvatarPreview] = useState(null);
    const user = useSelector(selectCurrentUser);
    const theme = useTheme();

    const [updateUserProfile, { isLoading: isUpdateUserProfileLoading }] = useUpdateUserProfileMutation();
    const [updateBrokerProfile, { isLoading: isUpdateBrokerProfileLoading }] = useUpdateBrokerProfileMutation();
    const [updateDevProfile, { isLoading: isUpdateDevLoading }] = useUpdateDevProfileMutation();


    console.log("user==>", user);

    const userValidationSchema = yup.object({
        first_name: yup
            .string("Pleae enter first name")
            .min(3, "First name must contain 3 characters minimum")
            .required("First Name is required")
            .max(50, "First name can contain a maximum of 50 characters"),
        last_name: yup
            .string("Enter last name")
            .min(3, "Last name must contain 3 characters minimum")
            .required("Last Name is required")
            .max(50, "Last name can contain a maximum of 50 characters"),
        email: yup
            .string("Enter mail")
            .email("Enter a valid email")
            .required("Email address is required"),
    });

    const brokerValidationSchema = yup.object({
        title: yup
            .string("Please enter title")
            .min(3, "Title must contain 3 characters minimum")
            .required("Title is required")
            .max(50, "Title can contain a maximum of 50 characters"),
        description: yup
            .string("Please enter description")
            .min(3, "Description must contain 3 characters minimum")
            .required("Description is required")
            .max(250, "Description can contain a maximum of 50 characters"),
        weblink: yup
            .string("Please enter Web Link")
            .required("Web Link is required"),
    });

    const devValidationSchema = yup.object({
        title: yup
            .string("Please enter title")
            .min(3, "Title must contain 3 characters minimum")
            .required("Title is required")
            .max(50, "Title can contain a maximum of 50 characters"),
        description: yup
            .string("Please enter description")
            .min(3, "Description must contain 3 characters minimum")
            .required("Description is required")
            .max(250, "Description can contain a maximum of 50 characters"),
        weblink: yup
            .string("Please enter Web Link")
            .required("Web Link is required")
    });

    useEffect(() => {
        if (user?.first_name) {
            userFormik.setFieldValue("first_name", user?.first_name)
            userFormik.setFieldValue("last_name", user?.last_name)
            userFormik.setFieldValue("email", user?.email)
            // userFormik.setFieldValue("is_active", user?.is_active)
            userFormik.setFieldValue("is_kyc_verified", user?.is_kyc_verified)
            // userFormik.setFieldValue("is_superuser", user?.is_superuser)
        }
    }, [user]);

    //user setting formik variables
    const userFormik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            // is_active: "",
            is_kyc_verified: "",
            // is_superuser: "",
            // dp: ""
        },
        validationSchema: userValidationSchema,
        onSubmit: async (values) => {
            // delete values.dp
            delete values.email
            // delete values.is_active
            // delete values.is_superuser
            console.log("values===>>>>", values);
            const data = { ...values, user_id: user?.id }
            try {
                const response = await updateUserProfile(data).unwrap();
                if (response) {
                    toast.success("User profile updated succesfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                showError(error?.data)
            }
        },
    });

    //broker setting formik variables
    const brokerFormik = useFormik({
        initialValues: {
            title: user?.broker_profile?.title || "",
            description: user?.broker_profile?.description || "",
            weblink: user?.broker_profile?.weblink || "",
        },
        validationSchema: brokerValidationSchema,
        onSubmit: async (values) => {
            const data = { ...values, user_id: user?.id }
            try {
                const response = await updateBrokerProfile(data).unwrap();
                if (response) {
                    toast.success("Broker profile updated succesfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                showError(error?.data)
            }
        },
    });

    //broker setting formik variables
    const devFormik = useFormik({
        initialValues: {
            title: user?.dev_profile?.title || "",
            description: user?.dev_profile?.description || "",
            weblink: user?.dev_profile?.weblink || "",
        },
        validationSchema: devValidationSchema,
        onSubmit: async (values) => {
            const data = { ...values, user_id: user?.id }
            try {
                const response = await updateDevProfile(data).unwrap();
                if (response) {
                    toast.success("Developer profile updated succesfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                showError(error?.data)
            }
        },
    });

    useEffect(() => {
        if (user != null) {
            setAvatarPreview(user.social_profile_pic)
        }
    }, [user]);

    const handleFileChange = (event) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            if (fileReader.readyState === 2) {
                setAvatarPreview(fileReader.result);
                userFormik.setFieldValue("dp", event.target.files[0])
            }
        };
        fileReader.readAsDataURL(event.target.files[0]);
    };

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    return (
        <>
            <CustomPaperBox>
                <Box sx={{
                    "& .MuiTypography-body1": {
                        color: theme.palette.primary.main, fontSize: '22px', marginBottom: '10px'
                    }
                }}><Typography >User Settings</Typography></Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Button
                            onClick={() => {
                                inputRef.current.click();
                            }}
                            disabled={true}
                        >
                            <Avatar
                                sx={{
                                    height: "80px", width: "80px",
                                    "& img": {
                                        objectFit: "contain"
                                    }
                                }}
                                src={avatarPreview ? avatarPreview : null}
                            />
                        </Button>
                    </Grid>
                    <input
                        name="dp"
                        ref={inputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={handleFileChange}
                        value={userFormik.values.dp}
                    />
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CustomInput
                            id="firstName"
                            name="first_name"
                            label="First Name"
                            value={userFormik.values.first_name}
                            onChange={userFormik.handleChange}
                            error={
                                userFormik.touched.first_name &&
                                Boolean(userFormik.errors.first_name)
                            }
                            helperText={
                                userFormik.touched.first_name && userFormik.errors.first_name
                            }
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CustomInput
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            value={userFormik.values.last_name}
                            onChange={userFormik.handleChange}
                            error={
                                userFormik.touched.last_name &&
                                Boolean(userFormik.errors.last_name)
                            }
                            helperText={
                                userFormik.touched.last_name && userFormik.errors.last_name
                            }
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CustomInput
                            id="email"
                            name="email"
                            value={userFormik.values.email}
                            onChange={userFormik.handleChange}
                            error={
                                userFormik.touched.email &&
                                Boolean(userFormik.errors.email)
                            }
                            disabled={true}
                            helperText={
                                userFormik.touched.email && userFormik.errors.email
                            }
                            label="Email" />
                    </Grid>
                    {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomSelect
                                label="Is Active"
                                id="is_active"
                                name="is_active"
                                value={userFormik.values.is_active}
                                onChange={userFormik.handleChange}
                                error={
                                    userFormik.touched.is_active &&
                                    Boolean(userFormik.errors.is_active)
                                }
                                helperText={
                                    userFormik.touched.is_active && userFormik.errors.is_active
                                }
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid> */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box display="flex" alignItems="center">
                            <CustomCheckBox
                                name="is_kyc_verified"
                                label="Is Kyc Verified?"
                                checked={userFormik.values.is_kyc_verified}
                                value={userFormik.values.is_kyc_verified}
                                onChange={userFormik.handleChange}
                                disabled={true}
                            />
                            {!userFormik.values.is_kyc_verified && <LightTooltip title="Your KYC is pending.Please contact the support team for approval.">
                                <InfoIcon />
                            </LightTooltip>}
                        </Box>
                    </Grid>
                    {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomSelect
                                label="Is Superuser"
                                id="is_superuser"
                                name="is_superuser"
                                value={userFormik.values.is_superuser}
                                onChange={userFormik.handleChange}
                                error={
                                    userFormik.touched.is_superuser &&
                                    Boolean(userFormik.errors.is_superuser)
                                }
                                helperText={
                                    userFormik.touched.is_superuser && userFormik.errors.is_superuser
                                }
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid> */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{
                            textAlign: 'end',
                            marginTop: '15px'
                        }}>
                            <CustomButton
                                // isLoading={isPostUserLoading || isPutUserLoading}
                                // disabled={isPostUserLoading || isPutUserLoading}
                                color="primary"
                                type="submit"
                                isLoading={isUpdateUserProfileLoading}
                                onClick={userFormik.handleSubmit}
                                disabled={isUpdateUserProfileLoading ? true : false}
                                buttonName={isUpdateUserProfileLoading ? 'Loading...' : 'Update'} />
                        </Box>
                    </Grid>
                </Grid>
            </CustomPaperBox>

            {user?.roles?.includes("REALESTATE_BROKER") && <CustomPaperBox>
                <Box sx={{
                    "& .MuiTypography-body1": {
                        color: theme.palette.primary.main, fontSize: '22px', marginBottom: '20px'
                    }
                }}><Typography >Broker Settings</Typography></Box>
                <Grid container spacing={3}>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <CustomInput
                            id="title"
                            name="title"
                            value={brokerFormik.values.title}
                            onChange={brokerFormik.handleChange}
                            error={
                                brokerFormik.touched.title &&
                                Boolean(brokerFormik.errors.title)
                            }
                            helperText={
                                brokerFormik.touched.title && brokerFormik.errors.title
                            }
                            label="Title" />
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <CustomInput
                            id="weblink"
                            name="weblink"
                            value={brokerFormik.values.weblink}
                            onChange={brokerFormik.handleChange}
                            error={
                                brokerFormik.touched.weblink &&
                                Boolean(brokerFormik.errors.weblink)
                            }
                            helperText={
                                brokerFormik.touched.weblink && brokerFormik.errors.weblink
                            }
                            label="Web Link" />
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <CustomInput
                            id="description"
                            name="description"
                            multiline={true}
                            minRows={3}
                            value={brokerFormik.values.description}
                            onChange={brokerFormik.handleChange}
                            error={
                                brokerFormik.touched.description &&
                                Boolean(brokerFormik.errors.description)
                            }
                            helperText={
                                brokerFormik.touched.description && brokerFormik.errors.description
                            }
                            label="Description" />
                    </Grid>
                </Grid>
                <Box sx={{
                    textAlign: 'end',
                    marginTop: '15px'
                }}>
                    <CustomButton
                        // isLoading={isPostUserLoading || isPutUserLoading}
                        // disabled={isPostUserLoading || isPutUserLoading}
                        color="primary"
                        type="submit"
                        onClick={brokerFormik.handleSubmit}
                        isLoading={isUpdateBrokerProfileLoading}
                        disabled={isUpdateBrokerProfileLoading ? true : false}
                        buttonName={isUpdateBrokerProfileLoading ? 'Loading...' : 'Update'} />
                </Box>
            </CustomPaperBox>}

            {user?.roles?.includes("REALESTATE_DEV") && <CustomPaperBox>
                <Box sx={{
                    "& .MuiTypography-body1": {
                        color: theme.palette.primary.main, fontSize: '22px', marginBottom: '20px'
                    }
                }}><Typography >Developer Settings</Typography></Box>
                <Grid container spacing={3}>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <CustomInput
                            id="title"
                            name="title"
                            value={devFormik.values.title}
                            onChange={devFormik.handleChange}
                            error={
                                devFormik.touched.title &&
                                Boolean(devFormik.errors.title)
                            }
                            helperText={
                                devFormik.touched.title && devFormik.errors.title
                            }
                            label="Title" />
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <CustomInput
                            id="Web Link"
                            name="weblink"
                            value={devFormik.values.weblink}
                            onChange={devFormik.handleChange}
                            error={
                                devFormik.touched.weblink &&
                                Boolean(devFormik.errors.weblink)
                            }
                            helperText={
                                devFormik.touched.weblink && devFormik.errors.weblink
                            }
                            label="Web Link" />
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <CustomInput
                            id="description"
                            name="description"
                            value={devFormik.values.description}
                            onChange={devFormik.handleChange}
                            multiline={true}
                            minRows={3}
                            error={
                                devFormik.touched.description &&
                                Boolean(devFormik.errors.description)
                            }
                            helperText={
                                devFormik.touched.description && devFormik.errors.description
                            }
                            label="Description" />
                    </Grid>
                </Grid>
                <Box sx={{
                    textAlign: 'end',
                    marginTop: '15px'
                }}>
                    <CustomButton color="primary"
                        type="submit"
                        isLoading={isUpdateDevLoading}
                        onClick={devFormik.handleSubmit}
                        disabled={isUpdateDevLoading ? true : false}
                        buttonName={isUpdateDevLoading ? 'Loading...' : 'Update'} />
                </Box>
            </CustomPaperBox>}
        </>
    )
}

export default Settings
