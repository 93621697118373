import { Box, Grid, Typography, useTheme } from "@mui/material";
import successImage from "../asset/payment-success-2.png"


const PaymentSuccess = () => {
    const theme = useTheme()
    return (
        <Box>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box sx={{
                        maxWidth: '800px',
                        width: '100%',
                        margin: '0 auto',
                        height: '400px',
                        '& img': {
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px',
                            objectFit: 'contain'
                        }
                    }}>
                        <img src={successImage} />
                    </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} display="flex" alignItems="center" justifyContent="center">
                    <Box>
                        <Typography variant='h5' sx={{ color: theme.palette.info.light, textAlign: 'center' }}>
                            Your payment has been successfully processed!
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default PaymentSuccess;


