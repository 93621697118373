import { apiSlice } from "../apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        updateUserProfile: builder.mutation({
            query: (data) => ({
                url: "/user/update_user/",
                method: "PUT",
                body: { ...data },
            }),
        }),
        updateBrokerProfile: builder.mutation({
            query: (data) => ({
                url: "/user/update_broker/",
                method: "PUT",
                body: { ...data },
            }),
        }),
        updateDevProfile: builder.mutation({
            query: (data) => ({
                url: "/user/update_developer/",
                method: "PUT",
                body: { ...data },
            }),
        }),
    }),
});

export const {
    useUpdateUserProfileMutation,
    useUpdateBrokerProfileMutation,
    useUpdateDevProfileMutation
} = settingsApiSlice;