import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Modal, Typography, useTheme } from '@mui/material'
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from './CustomButton';

const CustomModal = ({ isModalOpen, maxWidth, isShowActionButton, title, children, handleClose, onSubmit, onCancel }) => {
    const theme = useTheme();
    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            maxWidth={maxWidth}
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.secondary.dark,
                    borderRadius: '20px',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
                    padding: { xl: '50px', lg: '50px', md: '30px', sm: '20px', xs: '20px' },
                },
                '& .MuiDialog-paper': {
                    width: '100% '
                }

            }}
        >
            <DialogContent>
                <DialogContentText
                    id="scroll-dialog-description"
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                        fontSize: '23px',
                        color: theme.palette.secondary.light,
                        marginBottom: '20px',
                        textAlign: 'center'
                    }}>
                        {title}
                    </Typography>
                </DialogContentText>

                <Box>
                    <Box sx={{
                        textAlign: 'end',
                    }}>
                        <IconButton onClick={handleClose} sx={{
                            position: 'absolute',
                            right: '1rem',
                            top: '1rem',
                            width: '32px',
                            height: '32px',
                            '&:hover': {
                                backgroundColor: '#dbdbeb14'
                            },
                            '& svg': {
                                color: theme.palette.secondary.light,
                                width: '18px'
                            }
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Grid container spacing={2}>
                        {children}
                    </Grid>
                </Box>
            </DialogContent>
            {isShowActionButton &&
                <DialogActions sx={{
                    paddingTop: '32px'
                }}>
                    <CustomButton buttonName="Submit" color="primary" onClick={onSubmit} />
                    <CustomButton buttonName="Cancel" color="secondary" onClick={onCancel} />
                </DialogActions>
            }
        </Dialog>
    )
}

// Set default prop values using defaultProps
CustomModal.defaultProps = {
    isShowActionButton: true,
};

export default CustomModal;
