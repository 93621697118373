import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, useTheme } from '@mui/material';

const CustomTabs = ({ tabsData, activeTab, onChange, children, isBacis }) => {
    const theme = useTheme();
    return (
        <Box sx={{
            '& .MuiTabs-scroller': {
                '& .MuiTabs-flexContainer': {
                    gap: '10px',
                },
                '& button': {
                    border: '1px solid #2ec4b6',
                    borderRadius: '16px',
                    minHeight: 'auto',
                    padding: '5px 15px',
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                    letterSpacing: '0.02857em',
                    textTransform: 'uppercase',
                    '&.Mui-selected': {
                        color: '#fff',
                        background: '#2ec4b6',
                    },
                },
                '& .MuiTabs-indicator': {
                    display: 'none',
                },
            },
        }}>
            {!isBacis &&
                <>
                    <Tabs variant="scrollable"
                        scrollButtons={false}
                        aria-label="scrollable prevent tabs example" value={activeTab} onChange={onChange}
                    >
                        {tabsData.map((tab, index) => (
                            <Tab key={index} label={tab.label} value={index} sx={{
                                color: theme.palette.text.secondary,
                            }} />
                        ))}
                    </Tabs>
                    <div>{children[activeTab]}</div>
                </>
            }

            {isBacis && <>
                <Tabs
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example" value={activeTab} onChange={onChange}
                >
                    {tabsData.map((tab, index) => (
                        <Tab key={index} label={tab.label} value={index} sx={{
                            color: 'red',
                        }} />
                    ))}
                </Tabs>
                <div>{children[activeTab]}</div>
            </>}
        </Box>
    );
};

export default CustomTabs;
