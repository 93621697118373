import React from 'react'
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../store/auth/authSlice';
import { decryptFn } from '../view/shared/helperFunction';
import { Navigate } from 'react-router-dom';

const SpecialRoutes = ({ children }) => {
    const token = decryptFn(localStorage.getItem("ACCESS_TOKEN"));
    const currentUser = useSelector(selectCurrentUser);
    return (token && !currentUser?.roles?.length ? children : <Navigate to="/dashboard" replace />)
}

export default SpecialRoutes;
