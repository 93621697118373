import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, setUser } from "../store/auth/authSlice";
import { decryptFn, encryptFn } from "../view/shared/helperFunction";


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
        const accessToken = decryptFn(localStorage.getItem("ACCESS_TOKEN"))
        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }
        return headers;
    },
});

let isRefreshing = false;
let failedRequests = [];
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 401) {
        api.dispatch(setUser(null));
        localStorage.setItem("REFRESH_TOKEN", null);
        localStorage.setItem("ACCESS_TOKEN", null);
        const loginUrl = `${window.location.origin}/login`;

        // Redirect to the dynamic login page URL using window.location.href
        window.location.href = loginUrl;
    };
    if (result?.error?.data?.code === "token_not_valid") {
        const refreshToken = decryptFn(localStorage.getItem("REFRESH_TOKEN"));
        if (!isRefreshing) {
            isRefreshing = true;
            try {
                const refreshResult = await baseQuery({
                    url: '/token/refresh/',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: { refresh: refreshToken },
                }, api, extraOptions);
                if (refreshResult?.data) {
                    localStorage.setItem("REFRESH_TOKEN", encryptFn(refreshResult?.data.refresh));
                    localStorage.setItem("ACCESS_TOKEN", encryptFn(refreshResult?.data.access));
                    setTimeout(async () => {
                        for (const failedRequest of failedRequests) {
                            try {
                                const retryResult = await baseQuery(failedRequest.args, failedRequest.api, failedRequest.extraOptions);
                                failedRequest.resolve(retryResult);
                            } catch (error) {
                                failedRequest.reject(error);
                            }
                        }
                        // Clear the array of failed requests
                        failedRequests = [];
                    }, 1000); // Add a delay of 1 second before retrying failed requests
                }
                else {
                    // api.dispatch(logOut());
                    api.dispatch(setUser(null));
                }
            } finally {
                isRefreshing = false;
            }
        }
        // Queue up the failed request
        return new Promise((resolve, reject) => {
            failedRequests.push({ args, api, extraOptions, resolve, reject });
        });
    }
    return result;
};
export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
})