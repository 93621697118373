import React, { useEffect, useState } from 'react'
import { useGetTransactionsLogsQuery } from '../../store/transactions/transactionsApiSlice';
import { Box, Typography, useTheme } from '@mui/material';
import CustomTable from '../../theme/style-components/CustomTable';
import { Link } from 'react-router-dom';
import CustomChips from '../../theme/style-components/CustomChips';

const Transactions = () => {
    const [page, setPage] = useState(1);
    const [transactionData, setTransactionData] = useState([]);
    const [query, setQuery] = useState("");
    const [count, setCount] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const theme = useTheme();

    const {
        isLoading,
        data: transactionReturned,
        isSuccess,
        isError,
        refetch: refetchMarketPlace
    } = useGetTransactionsLogsQuery({ page: page, query: query });

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setTransactionData(transactionReturned?.data);
            setCount(transactionReturned?.data?.length)
        }
    }, [isLoading, page, transactionReturned?.data]);

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'property_id', label: 'Property ID' },
        // { id: 'stripe', label: 'Stripe' },
        { id: 'token_amount', label: 'Token Amount' },
        { id: 'tokens', label: 'Tokens' },
        { id: 'transaction_status', label: 'Transaction Status' },
        { id: 'transaction_type', label: 'Transaction Type' },
    ];

    const getTransactionType = (data) => {
        const transactionTypes = ["internal_db_property_wallet_transaction", "internal_db_wallet_transaction", "property_listing_transaction", "property_marketplace_transaction"];
        return transactionTypes.find(type => data[type]);
    };

    const renderStatus = (status) => {
        if (status === "CREATED") {
            return <CustomChips label="Created" labelColor="info" color="info" />
        } if (status === "COMPLETED") {
            return <CustomChips label="Completed" labelColor="primary" color="primary" />
        } if (status === "CANCELLED") {
            return <CustomChips label="cancelled" labelColor="error" color="error" />
        }
    }

    const data = transactionData?.length > 0 ? transactionData.map(data => {
        const transactionType = getTransactionType(data);

        return ({
            id: data?.id,
            property_id: transactionType === "internal_db_property_wallet_transaction" || transactionType === "internal_db_wallet_transaction" ? data?.[transactionType]?.property_id : data?.[transactionType]?.property,
            // stripe: <Box>
            //     <Typography>{data?.[transactionType]?.stripe_trx?.card_last4}</Typography>
            //     {data?.[transactionType]?.stripe_trx?.receipt_url && <Link to={data?.[transactionType]?.stripe_trx?.receipt_url}>Receipt</Link>}
            //     <Typography>{renderStatus(data?.[transactionType]?.stripe_trx?.status)}</Typography>
            // </Box>,
            token_amount: transactionType === "internal_db_property_wallet_transaction" || transactionType === "internal_db_wallet_transaction" ? data?.[transactionType]?.aed_amount_cents / 100 ? `$${(data?.[transactionType]?.aed_amount_cents / 100)}` : "-" : data?.[transactionType]?.token_amount_aed_cents ? `$${(data?.[transactionType]?.token_amount_aed_cents / 100)}` : "NA",
            tokens: data?.[transactionType]?.tokens,
            transaction_status: renderStatus(data?.[transactionType]?.transaction_status),
            transaction_type: <Box sx={{ "& a": { textDecoration: "none", color: theme.palette.primary.main } }}><Typography>{data?.[transactionType]?.transaction_type} {data?.[transactionType]?.transaction_type === "STRIPE" && data?.[transactionType]?.stripe_trx?.card_last4 && <Link target='_blank' to={data?.[transactionType]?.stripe_trx?.receipt_url}>(**{data?.[transactionType]?.stripe_trx?.card_last4})</Link>}</Typography>
            </Box>,
        })
    }) : [];

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    return (
        <>
            < CustomTable
                //pagination props
                page={page}
                tableTitle="Transactions"
                count={count}
                rowsPerPage={rowsPerPage}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}

                isShowCreateButton={false}

                //edit menu props
                isShowEditMenu={false}

                //delete menu props
                isShowDeleteMenu={false}

                //action props
                isActionVisible={false}

                //data props
                data={data}
                columns={columns}

                isLoading={isLoading}

                //search props
                isShowSearch={false}

            //set Selected row data
            // setSelectedData={setSelectedData}
            />
        </>
    )
}

export default Transactions
