import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TableSkeleton = ({ columnCount }) => {
    return (
        [...Array(columnCount)].map((_, rowIndex) => (
            <TableRow key={rowIndex}>
                {[...Array(columnCount)].map((_, colIndex) => (
                    <TableCell key={colIndex}>
                        <Skeleton height={20} />
                    </TableCell>
                ))}
            </TableRow>
        ))
    );
};

export default TableSkeleton;
