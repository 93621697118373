import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import styled from "@emotion/styled";
import { FormHelperText, MenuItem, Paper, useTheme } from '@mui/material';

const StyledPaper = styled(Paper)`
&& {
    background-color: ${(props) => props.theme.palette.background.default} !important; // Access theme colors
    color: ${(props) => props.theme.palette.text.primary} !important; // Access theme text color
  }
`;

const CustomSelect = ({ value, label, onChange, disabled, children, name, error, helperText }) => {
    const theme = useTheme();
    console.log("theme.palette.action.disabled", theme.palette.action.disabled);
    return (
        <FormControl
            disabled={disabled}
            fullWidth sx={{
                '& .MuiInputBase-root,label': {
                    color: theme.palette.text.secondary,
                },
                '& svg': {
                    fill: error ? theme.palette.error.main : theme.palette.primary.main
                },
                '& label': {
                    top: '-5px'
                },
                "& .Mui-disabled": {
                    fill: `${theme.palette.action.disabled} !important`,
                    WebkitTextFillColor: `${theme.palette.action.disabled} !important`,
                    '& fieldset': {
                        borderColor: `${theme.palette.action.disabled} !important`,
                        color: `${theme.palette.action.disabled} !important`,
                    },
                    "& .MuiFormControl-root": {
                        color: "red"
                    },
                },

                '& .MuiSelect-select': {
                    padding: '10px 14px',
                    paddingLeft: '17px'
                },
                // '& .MuiMenu-list': {
                //     backgroundColor: 'rgb(43, 44, 64) !important'
                // },
                '& fieldset': {
                    borderColor: theme.palette.grey["300"],
                    borderRadius: '20px',
                },
                "& fieldset:hover": {
                    borderColor: "white !important"
                },
                '& .MuiOutlinedInput-root': {
                    '&:hover': {
                        '& fieldset': {
                            borderColor: `${theme.palette.primary.main} !important`,
                        },
                    },
                },
                '& .Mui-focused': {
                    '& fieldset': {
                        borderColor: `${theme.palette.primary.main} !important`,
                    },
                },
                '& .MuiFormLabel-root': {
                    '&.Mui-focused': {
                        color: `${theme.palette.primary.main} !important`,
                    },
                },
                "& .MuiSelect-outlined": {
                    backgroundColor: `${theme.palette.background.paper} !important`
                },
                '& .MuiInputLabel-shrink': {
                    top: '0px',
                    left: '0px',
                    color: `${theme.palette.primary.main} !important`,
                },

                "& .Mui-disabled.MuiInputLabel-shrink ": {
                    color: `${theme.palette.action.disabled} !important`,
                },
                '& .Mui-disabled.MuiOutlinedInput-root': {
                    '&:hover': {
                        '& fieldset': {
                            borderColor: `${theme.palette.action.disabled} !important`,
                        },
                    },
                },
            }}>
            <InputLabel id="demo-simple-select-label" error={error}>{label}</InputLabel>
            <Select
                sx={{
                    "&:hover": {
                        boxShadow: "none !important",
                    }
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                name={name}
                error={error}
                disabled={disabled}
                onChange={onChange}
                MenuProps={{
                    PaperProps: {
                        component: StyledPaper,
                    },
                }}
            >
                {React.Children.map(children, (child) => (
                    <MenuItem sx={{
                        "&:hover": {
                            backgroundColor: `${theme.palette.primary.main} !important`,
                            color: '#fff',
                        },

                        "&.Mui-selected": {
                            backgroundColor: `${theme.palette.primary.main} !important`
                        }
                    }} value={child.props.value}>
                        {child.props.children}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText error={error}>{error && helperText}</FormHelperText>
        </FormControl >
    );
};

export default CustomSelect;
