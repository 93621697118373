import React from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import { Box, Grid, InputAdornment, Typography, useTheme } from '@mui/material'
import CustomInput from '../../theme/style-components/CustomInput'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useState } from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import CustomButton from '../../theme/style-components/CustomButton';
import { useUpdatePropertyLocationMutation } from '../../store/property/propertyApiSlice';
import { toast } from 'react-toastify';
import { showError } from '../shared/helperFunction';
import { useEffect } from 'react';


const PropertyLocation = ({ id, location }) => {
    const theme = useTheme();
    const [updatePropertyLocation, { data, isLoading, error }] = useUpdatePropertyLocationMutation();

    const [autocompleteAddress, setAutocompleteAddress] = useState("");
    const searchOptions = {
        componentRestrictions: { country: "ae" }
    }

    const validationSchema = yup.object({
        address: yup
            .string("Please enter the address")
            .required("Address is required"),
        map_link: yup
            .string().test('is-url', 'Invalid link format', (value) => {
                if (!value) {
                    return true; // Allow empty values (optional field)
                }

                // Use a regular expression to check if the value is a valid URL
                const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
                return urlRegex.test(value);
            })
            .required("Map link is required"),
        description: yup
            .string("Please select property type")
            .required("Description link is required"),
    });


    useEffect(() => {
        if (location) {
            locationFormik.setFieldValue("address", location.address)
            locationFormik.setFieldValue("country", location.country)
            locationFormik.setFieldValue("postcode", location.postcode)
            locationFormik.setFieldValue("long", location.long)
            locationFormik.setFieldValue("lat", location.lat)
            locationFormik.setFieldValue("city", location.city)
            locationFormik.setFieldValue("map_link", location.map_link)
            locationFormik.setFieldValue("description", location.description)
        }
    }, [location]);

    const locationFormik = useFormik({
        initialValues: {
            address: location?.address || "",
            city: location?.city || "",
            country: location?.country || "",
            postcode: location?.postcode || "",
            lat: location?.lat || "",
            long: location?.long || "",
            map_link: location?.map_link || "",
            description: location?.description || "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const updateData = { ...values, id: id }

            try {
                const response = await updatePropertyLocation(updateData).unwrap();
                if (response) {
                    toast.success("Property location updated succesfully!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                showError(error?.data)
            }
        },
    });

    const handleAddressSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let index = 0;
        const addressLength = results[0].address_components.length;
        let postCode = {};
        let cityObject = {};
        let countryObject = {};
        setAutocompleteAddress(value);
        locationFormik.setFieldValue("address", value)
        let stateExists = false;
        const myFunction = (item, index, array) => {
            if (array[index].types[0] == "postal_code") {
                postCode = item;
            }
            if (array[index].types[0] == "country") {
                locationFormik.setFieldValue("country", item.long_name)

            }
            if (array[index].types[0] == "administrative_area_level_2") {
                stateExists = true;
            }

            if (!stateExists) {
                if (array[index].types[0] == "administrative_area_level_1") {
                    cityObject = item;
                }
            } else {
                if (array[index].types[0] == "administrative_area_level_2") {
                    cityObject = item;
                }
            }
        };
        results[0].address_components.forEach(myFunction);
        locationFormik.setFieldValue("postcode", postCode.long_name ? postCode.long_name : "N/A")
        locationFormik.setFieldValue("city", cityObject.long_name)
        locationFormik.setFieldValue("lat", latLng.lat)
        locationFormik.setFieldValue("long", latLng.lng)
    };

    return (
        <CustomPaperBox>
            <Box sx={{
                "& .MuiTypography-body1": {
                    color: theme.palette.primary.main, fontSize: '22px', marginBottom: '15px'
                }
            }}><Typography >Location </Typography></Box>
            <Grid container spacing={3}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <PlacesAutocomplete
                        value={autocompleteAddress}
                        onChange={(e) => {
                            setAutocompleteAddress(e);
                        }}
                        searchOptions={searchOptions}
                        onSelect={(e) => handleAddressSelect(e)}
                    >
                        {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                        }) => (
                            <Box>
                                <CustomInput
                                    type="search"
                                    fullWidth
                                    label="Search Address"
                                    variant="outlined"
                                    id="standard-adornment-amount"
                                    startAdornment={
                                        <InputAdornment position="start"></InputAdornment>
                                    }
                                    {...getInputProps({ type: "search" })}
                                ></CustomInput>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 9999,
                                        backgroundColor: theme.palette.background.default,
                                        marginRight: "15px",
                                        padding: "15px 0px",
                                        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                                        maxWidth: "580px",
                                        width: '100%',
                                        borderRadius: '10px',
                                        color: theme.palette.text.primary,
                                        display: suggestions.length > 0 ? "block" : "none"
                                    }}
                                >
                                    {loading ? <div>...loading</div> : null}

                                    {suggestions.map((suggestion, index) => {
                                        const style = {
                                            backgroundColor: suggestion.active
                                                ? theme.palette.primary.main
                                                : theme.palette.background.default,
                                            color: suggestion.active
                                                ? "#fff"
                                                : theme.palette.text.primary,
                                            padding: "10px",
                                        };

                                        return (
                                            <Box key={index}
                                                {...getSuggestionItemProps(suggestion, { style })}
                                            >
                                                {suggestion.description}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        )}
                    </PlacesAutocomplete>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <CustomInput label="Address"
                        id="address"
                        name="address"
                        readOnly={true}
                        value={locationFormik.values.address}
                        onChange={locationFormik.handleChange}
                        onBlur={locationFormik.handleBlur}
                        error={
                            locationFormik.touched.address &&
                            Boolean(locationFormik.errors.address)
                        }
                        helperText={
                            locationFormik.touched.address && locationFormik.errors.address
                        } />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <CustomInput
                        label="City"
                        id="city"
                        name="city"
                        readOnly={true}
                        value={locationFormik.values.city}
                        onChange={locationFormik.handleChange}
                        onBlur={locationFormik.handleBlur}
                        error={
                            locationFormik.touched.city &&
                            Boolean(locationFormik.errors.city)
                        }
                        helperText={
                            locationFormik.touched.city && locationFormik.errors.city
                        } />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <CustomInput label="Postcode"
                        id="postcode"
                        name="postcode"
                        readOnly={true}
                        value={locationFormik.values.postcode}
                        onChange={locationFormik.handleChange}
                        onBlur={locationFormik.handleBlur}
                        error={
                            locationFormik.touched.postcode &&
                            Boolean(locationFormik.errors.postcode)
                        }
                        helperText={
                            locationFormik.touched.postcode && locationFormik.errors.postcode
                        }
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <CustomInput label="Map Link"
                        id="map_link"
                        name="map_link"
                        value={locationFormik.values.map_link}
                        onChange={locationFormik.handleChange}
                        onBlur={locationFormik.handleBlur}
                        error={
                            locationFormik.touched.map_link &&
                            Boolean(locationFormik.errors.map_link)
                        }
                        helperText={
                            locationFormik.touched.map_link && locationFormik.errors.map_link
                        }
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <CustomInput label="Description" multiline={true} minRows={3}
                        id="description"
                        name="description"
                        value={locationFormik.values.description}
                        onChange={locationFormik.handleChange}
                        onBlur={locationFormik.handleBlur}
                        error={
                            locationFormik.touched.description &&
                            Boolean(locationFormik.errors.description)
                        }
                        helperText={
                            locationFormik.touched.description && locationFormik.errors.description
                        }
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} >
                    <Box sx={{
                        textAlign: 'end',
                        marginTop: '15px'
                    }}>
                        <CustomButton
                            color="primary"
                            type="submit"
                            isLoading={isLoading}
                            onClick={locationFormik.handleSubmit}
                            disabled={isLoading}
                            buttonName={id ? "Update" : "Next"} />
                    </Box>
                </Grid>
            </Grid>
        </CustomPaperBox >
    )
}

export default PropertyLocation
