import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CustomChips from '../../theme/style-components/CustomChips';
import CustomLoader from '../../theme/style-components/CustomLoader';
import { useGetPropertyListQuery } from '../../store/property/propertyApiSlice';
import CustomTable from '../../theme/style-components/CustomTable';
import CustomButton from '../../theme/style-components/CustomButton';

const Properties = () => {
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [searchText, setSearchText] = useState("");
    const [count, setCount] = useState();

    const theme = useTheme();

    //base query hooks
    const {
        isLoading,
        data: getListingsReturned,
        isSuccess,
        isError,
        refetch
    } = useGetPropertyListQuery({ page: page, query: query });

    const [propertiesData, setPropertiesData] = useState([]);

    const navigate = useNavigate();

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'property', label: 'Property' },
        { id: 'location', label: 'Location' },
        { id: 'type', label: 'Type' },
        { id: 'tenant_status', label: 'Tenant Status' },
        { id: 'current_rent', label: 'Current Rent' },
        { id: 'add_rent', label: 'Add rent' },
    ];

    const renderStatus = (status) => {
        if (status === "SOLDOUT") {
            return <CustomChips label="Soldout" labelColor="error" color="error" />
        } if (status === "AVAILABLE") {
            return <CustomChips label="Enable" labelColor="primary" color="primary" />
        } if (status === "DISABLED") {
            return <CustomChips label="Disabled" labelColor="warning" color="warning" />
        }
    }

    const data = propertiesData?.length > 0 ? propertiesData?.map((data) => ({
        id: data.id,
        property: <Box sx={{
            "& a": {
                textDecoration: "none",
                color: theme.palette.primary.main,
                fontWeight: "bold"
            }
        }}><Link to={`/properties/view/${data.id}`}>{data?.name}</Link></Box>,
        location: data?.location?.address,
        type: <Typography sx={{ textTransform: "capitalize" }}>{data?.property_type?.toLowerCase()}</Typography>,
        tenant_status: <Typography sx={{ textTransform: "capitalize" }}>{data?.tenant_status?.toLowerCase()}</Typography>,
        current_rent: <Typography>{`${data?.financials?.current_rent ? "$" + data?.financials?.current_rent : "N/A"}`}</Typography>,
        add_rent: <Box sx={{ minWidth: { md: "150px", lg: "150px", sm: "110px", xs: "110px" } }}><CustomButton buttonName="Add rent" /></Box>,
    })) : []

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setPropertiesData(getListingsReturned?.data);
            setCount(getListingsReturned?.page_details?.count)
        }
    }, [isLoading, page, getListingsReturned?.data]);

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const handleCreateClick = () => {
        navigate("/create-properties")
    };

    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&id=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&id=${encodeURIComponent(newSearchText)}`;
        }

        setQuery(newQuery);
    };

    return (
        <>
            <CustomTable
                //pagination props
                page={page}
                tableTitle="My Properties"
                count={count}
                rowsPerPage={rowsPerPage}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}

                //create button props
                handleCreateClick={handleCreateClick}
                createButtonName="Create Property"
                isShowCreateButton={false}

                //action props
                isActionVisible={false}

                //data props
                data={data}
                columns={columns}

                isLoading={isLoading}

                //search props
                isShowSearch={true}
                handleOnSearch={handleOnSearch}
                searchText={searchText}
                searchPlaceholder="Search by id"

            />
        </>
    )
}

export default Properties;
