import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Layout from "../view/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../store/auth/authSlice";
import { decryptFn } from "../view/shared/helperFunction";
import { useGetUserProfileQuery, useLazyGetUserProfileQuery } from "../store/user-profile/userProfileApiSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";

const PrivateRoute = ({ children, permissions }) => {
    const token = decryptFn(localStorage.getItem("ACCESS_TOKEN"));
    const currentUser = useSelector(selectCurrentUser);

    const [getUserProfile, { isLoading: isGetUserLoading, data: userData,
        isSuccess: isGetUserSuccess, isError: isGetUserError }] = useLazyGetUserProfileQuery();

    let access = false;
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        callUserProfile()
    }, []);

    useEffect(() => {
        if (!isGetUserLoading && isGetUserSuccess && userData) {
            if (userData) {
                dispatch(setUser(userData?.data));
            }
        };
    }, [isGetUserLoading, isGetUserSuccess, userData]);

    const callUserProfile = async () => {
        try {
            const response = getUserProfile().unwrap();
        } catch (error) {
            toast.error(error?.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    if (permissions && currentUser?.roles?.length) {
        access = currentUser.roles.some(role => permissions.includes(role));
    }

    return token && currentUser?.roles?.length ? (
        access ? (
            <Layout>{children}</Layout>
        ) : (
            <Navigate state={{ from: location }} to="/dashboard" replace={true} />
        )
    ) : (
        <Navigate to="/login" replace />
    );
};

export default PrivateRoute;
