import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const GoogleMap = ({ markers }) => {
    const google = window.google;

    const mapStyles = {
        width: '100%',
        height: '450px',
    };

    const center = {
        lat: markers?.lat,
        lng: markers?.lng,
    };

    const containerStyle = {
        position: 'relative',  
        width: 'auto',
        height: '450px'
      }

    return (
            <Map
                google={google}
                zoom={14}
                style={mapStyles}
                initialCenter={center}
                containerStyle={containerStyle}
            >
                <Marker position={center} />
            </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyA2MUSkJP3v7lL-dY2GwwnzEGd1ZEggU3o',
})(GoogleMap);
