import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import loginbg from '../../asset/images/logos/foote.svg'
import cryptoicon from '../../asset/crypto-logo.png'
import { GoogleLogin } from '@react-oauth/google';
import { selectCurrentUser, setUser } from '../../store/auth/authSlice';
import { decryptFn, encryptFn } from '../shared/helperFunction';
import { useGoogleLoginMutation } from '../../store/auth/authApiSlice';
import { useLazyGetUserProfileQuery } from '../../store/user-profile/userProfileApiSlice';
import { toast } from 'react-toastify';
import { ReactComponent as Icon } from "../../asset/images/logos/1byx-logo-final.svg";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = decryptFn(localStorage.getItem("ACCESS_TOKEN"));
    const userDetails = useSelector(selectCurrentUser);

    const [googleLogin, { isLoading: isGoogleLogin, data: googleData,
        isSuccess: isGoogleLoginSuccess, isError: isGoogleLoginError }] = useGoogleLoginMutation();

    const [getUserProfile, { isLoading: isGetUserLoading, data: userData,
        isSuccess: isGetUserSuccess, isError: isGetUserError }] = useLazyGetUserProfileQuery();

    useEffect(() => {
        if (!isGoogleLogin && isGoogleLoginSuccess && googleData) {
            if (googleData?.access) {
                localStorage.setItem("ACCESS_TOKEN", encryptFn(googleData?.access));
                localStorage.setItem("REFRESH_TOKEN", encryptFn(googleData?.refresh));
                callUserProfile();
            }
        };
    }, [isGoogleLogin, isGoogleLoginSuccess, googleData]);

    useEffect(() => {
        if (!isGetUserLoading && isGetUserSuccess && userData) {
            if (userData) {
                dispatch(setUser(userData?.data));
            }
        };
    }, [isGetUserLoading, isGetUserSuccess, userData]);

    const callTheLogin = async (auth) => {
        try {
            const response = await googleLogin(auth);
        } catch (err) {
            toast.error("Google sign-in failed", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const handleGoogleLoginSuccess = (response) => {
        if (response?.credential) {
            callTheLogin(response?.credential)
        }
    };

    const handleGoogleLoginFailure = (error) => {
        toast.error("Google sign-in failed", {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const callUserProfile = async () => {
        try {
            const response = await getUserProfile().unwrap();
            if (response) {
                if (response?.data?.roles?.length === 0) {
                    navigate("/register")
                } else {
                    navigate("/dashboard");
                }
            }
        } catch (error) {
            toast.error(error?.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <>
            <Box sx={{
                backgroundColor: '#011627',
                height: '100vh',
                overflow: 'hidden',
                '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    animation: 'moving 8s linear infinite',
                },
                '@keyframes moving': {
                    '0%': {
                        transform: 'translatey(0px)',
                    },
                    '20%': {
                        transform: 'translateX(-50px)',
                    },
                    '50%': {
                        transform: 'translatey(-40px)',
                    },
                    '100%': {
                        transform: 'translatey(0px)',
                    }
                }
            }}>

                <img src={loginbg} />
            </Box >
            <Box sx={{
                height: 'auto',
                zIndex: '9999',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%,-50%)',
                left: '50%',
                maxWidth: '450px',
                width: { xl: '100%', lg: '100%', md: '100%', sm: '80%', xs: '80%' },
                backgroundColor: '#072035',
                borderRadius: '10px',
                padding: '25px 10px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: '200px',
                        height: '100px',
                        gap: "5px",
                        margin: '0 auto',
                        alignItems: "center",
                        display: "flex",
                        '& svg': {
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                        }
                    }}>
                        {/* <img src={cryptoicon} /> */}
                        <Icon />
                        <Typography color="#2ec4b6" fontSize="30px">(Beta)</Typography>
                    </Box>
                    <Box sx={{
                        textTransform: 'capitalize',
                        // padding: '10px 15px',
                        display: 'flex',
                        gap: '12px',
                        maxWidth: 'max-content',
                        width: '100%',
                        margin: '0 auto',
                        // marginTop: '25px'
                    }}>
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            onSuccess={handleGoogleLoginSuccess}
                            onError={handleGoogleLoginFailure}
                            scope="openid profile email"
                            cookiePolicy={'single_host_origin'}
                            shape="circle"
                            containerProps={{
                                style: {
                                    width: "100% !important",
                                },
                            }}
                            useOneTap
                        />
                    </Box>

                </Box>
            </Box>
        </>
    );
};

export default Login;