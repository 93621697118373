import { Box, Chip, Grid, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ComponentTitle from '../shared/ComponentTitle'
import TabTitle from '../shared/TabTitle'
import { useLazyGetPublicUserQuery } from '../../store/user-profile/userProfileApiSlice'
import { selectCurrentUser } from '../../store/auth/authSlice'
import { useSelector } from 'react-redux'
import { showError } from '../shared/helperFunction'
import { makeStyles } from '@mui/styles'
import soldoutImage from "../../asset/sold_out.png"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    cardBox: {
        borderRadius: "10px",
        boxShadow: `0px 0px 5px ${theme.palette.background.default}`,
        background: theme.palette.background.paper,
        marginBottom: "16px",
        position: "relative",
        cursor: "pointer"
    },
    cardbuttons: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& button": {
            fontSize: "11px !important",
            color: "#2e2e2e !important",
            border: "1px solid lightgray !important",
            borderRadius: "3px !important",
            padding: "3px 5px !important",
        }
    },
    cardImg: {
        height: "210px",
        cursor: 'pointer',
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
            borderRadius: "10px 10px 0 0",
        }
    },
    cardDetails: {
        padding: "15px"
    }
}));

const BrokerDetails = () => {
    const [query, setQuery] = useState("");
    const [brokerData, setBrokerData] = useState({});
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();

    const { id } = useParams();
    const currentUser = useSelector(selectCurrentUser);

    const [getPublicUser, { isLoading: isGetLoading, data: userData,
        isSuccess: isGetSuccess, isError: isGetError }] = useLazyGetPublicUserQuery();

    useEffect(() => {
        if (currentUser) {
            let newQuery = query;

            // Remove existing search parameters from the query
            newQuery = newQuery.replace(/&id=[^&]*/g, '');

            // Add new search parameter if search text is not empty
            newQuery += `&id=${id}`;
            setQuery(newQuery);

        }
    }, [currentUser]);

    useEffect(() => {
        if (query) {
            try {
                const result = getPublicUser({ page: 1, query: query }).unwrap().then((res) => {
                    setBrokerData(res?.data[0]);
                }).catch(err => {
                    showError(err?.data)
                })
            } catch (err) {
                showError(err?.data)
            }
        }
    }, [query]);

    console.log(brokerData, "data");

    const breadcrumbs = [
        <Link key="1" to="/find-brokers">
            Brokers
        </Link>,
        <Typography key="2" variant="body1">
            Details
        </Typography>,
    ];

    const redirectToDetails = (id, type) => {
        navigate(`/my-listings/view/${id}`, {
            state: {
                type: type
            }
        })
    };

    return (
        <>
            <TabTitle title="broker-details" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <CustomPaperBox>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item lg={2} md={2} sm={3} xs={12}>
                        <Box sx={{
                            width: { lg: '170px', md: '120px', sm: '100px', xs: '100px', },
                            height: { lg: '170px', md: '120px', sm: '100px', xs: '100px', },
                            flex: { lg: '0 0 170px', md: '0 0 120px', sm: '0 0 100px', xs: '0 0 100px', },
                            borderRadius: '100px',
                            border: '2px solid #2ec4b6',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: { lg: '0', md: '0', sm: '0 auto', xs: '0 auto', },
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '100px',
                            },
                        }}>
                            <img src={brokerData?.social_profile_pic} />
                        </Box>
                    </Grid>
                    <Grid item lg={10} md={10} sm={9} xs={12}>
                        <Box sx={{
                            color: theme.palette.text.primary,
                        }}>
                            <Box sx={{
                                display: 'flex',
                            }}>
                                <Typography sx={{
                                    width: '110px',
                                    flex: '0 0 110px',
                                    fontWeight: '500',
                                    fontSize: { lg: '18px', md: '18px', sm: '16px', xs: '14px', },

                                }}>Title:</Typography>
                                <Typography sx={{
                                    wordBreak: 'break-word',
                                    fontSize: { lg: '16px', md: '16px', sm: '14px', xs: '12px', },
                                }}>{brokerData?.broker_profile?.title ? brokerData?.broker_profile?.title : "NA"}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                margin: '10px 0',
                            }}>
                                <Typography sx={{
                                    width: '110px',
                                    flex: '0 0 110px',
                                    fontWeight: '500',
                                    fontSize: { lg: '18px', md: '18px', sm: '16px', xs: '14px', },

                                }}>Description:</Typography>
                                <Typography sx={{
                                    wordBreak: 'break-word',
                                    fontSize: { lg: '16px', md: '16px', sm: '14px', xs: '12px', },
                                }}>{brokerData?.broker_profile?.description ? brokerData?.broker_profile?.description : "NA"}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                '& a': {
                                    wordBreak: 'break-word',
                                    fontSize: { lg: '16px', md: '16px', sm: '14px', xs: '12px', },
                                },
                            }}>
                                <Typography sx={{
                                    width: '110px',
                                    flex: '0 0 110px',
                                    fontWeight: '500',
                                    fontSize: { lg: '18px', md: '18px', sm: '16px', xs: '14px', },
                                }}>Web Link:</Typography>
                                <Box sx={{
                                    "& a": {
                                        color: `${theme.palette.text.primary} !important`
                                    }
                                }}><Link>{brokerData?.broker_profile?.weblink ? brokerData?.broker_profile?.weblink : "NA"}</Link></Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CustomPaperBox>
            {brokerData?.broker_profile?.broker_managing?.length > 0 && <Box marginTop={6}>
                <Box sx={{
                    "& .MuiTypography-body1": {
                        color: theme.palette.primary.main, fontSize: '22px',
                    }
                }}><Typography >Managing</Typography></Box>
                <Box marginTop={3}>
                    <Grid container spacing={2}>
                        {brokerData?.broker_profile?.broker_managing?.length > 0 ?
                            brokerData?.broker_profile?.broker_managing?.map((item) => {
                                if (item?.listing_status !== "DISABLED") {
                                    return (
                                        < Grid item lg={4} md={4} sm={12} xs={12} >
                                            <Box className={classes.cardBox} onClick={() => redirectToDetails(item.id, item?.listing_status)}>
                                                <Box className={classes.cardImg}>
                                                    <img src={item?.images?.[0]?.image} />
                                                </Box>
                                                <Box className={classes.cardDetails}>
                                                    <Box sx={{
                                                        marginBottom: '10px',
                                                    }} className={classes.cardbuttons}>
                                                        <Chip sx={{
                                                            marginRight: "10px",
                                                            marginBottom: "10px",
                                                            "& span": {
                                                                textTransform: "capitalize"
                                                            }
                                                        }} color='info' label={item?.property_type} variant="outlined" />
                                                        <Chip sx={{
                                                            marginRight: "10px",
                                                            marginBottom: "10px",
                                                            "& span": {
                                                                textTransform: "capitalize"
                                                            }
                                                        }} color='warning' label={item?.tenant_status} variant="outlined" />
                                                        <Chip sx={{
                                                            marginRight: "10px",
                                                            marginBottom: "10px",
                                                            "& span": {
                                                                textTransform: "capitalize"
                                                            }
                                                        }} color='primary' label={item?.listing_status} variant="outlined" />
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{
                                                            color: theme.palette.primary.main,
                                                            fontWeight: '600',
                                                            fontSize: '18px',
                                                        }}>
                                                            {item.name}
                                                        </Typography>
                                                        {item?.location?.address ? <Typography sx={{
                                                            color: '#787373',
                                                            fontWeight: '600',
                                                            fontSize: '12px',
                                                            marginBottom: '10px',
                                                        }}>
                                                            {item?.location?.address?.substring(0, 55)}...
                                                        </Typography> : null}
                                                    </Box>
                                                    {item.listing_status === "SOLDOUT" && <Box sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        textAlign: 'center',
                                                        '& img': {
                                                            width: '70%',
                                                        },
                                                    }}>
                                                        <img src={soldoutImage} alt="sold-out" />
                                                    </Box>}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                }
                            }) : "Not available"}
                    </Grid>
                </Box >
            </Box >}
        </>
    )
}

export default BrokerDetails
