import { useFormik } from 'formik'
import React from 'react'
import * as yup from "yup";
import { useUpdatePropertyFeaturesMutation } from '../../store/property/propertyApiSlice';
import { toast } from 'react-toastify';
import { showError } from '../shared/helperFunction';
import CustomPaperBox from '../../theme/style-components/CustomPaperBox';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import CustomInput from '../../theme/style-components/CustomInput';
import CustomButton from '../../theme/style-components/CustomButton';
import { useEffect } from 'react';

const PropertyFeatures = ({ features, id }) => {
    const theme = useTheme();
    const [updatePropertyFeatures, { data: updateFeaturesReturned, isLoading: isUpdateLoading, error: isUpdateError }] = useUpdatePropertyFeaturesMutation();

    useEffect(() => {
        if (features?.id) {
            featuresFormik.setFieldValue("bedrooms", features?.bedrooms)
            featuresFormik.setFieldValue("bathrooms", features?.bathrooms)
            featuresFormik.setFieldValue("area_sqft", features?.area_sqft)
        }
    }, [features])

    const validationSchema = yup.object({
        bedrooms: yup
            .number()
            .typeError('Please enter a valid number')
            .required("Bedrooms is required")
            .positive("Bedrooms must be a positive number")
            .integer("Bedrooms must be an integer")
            .max(9999999999, 'Maximum 10 digits allowed'),
        bathrooms: yup
            .number()
            .typeError('Please enter a valid number')
            .required("Bathrooms is required")
            .positive("Bathrooms must be a positive number")
            .integer("Bathrooms must be an integer")
            .max(9999999999, 'Maximum 10 digits allowed'),
        area_sqft: yup
            .number()
            .typeError('Please enter a valid number')
            .required("Area sqft is required")
            .positive("Area sqft must be a positive number")
            .integer("Area sqft must be an integer")
            .max(9999999999, 'Maximum 10 digits allowed')
    });

    const featuresFormik = useFormik({
        initialValues: {
            bedrooms: features?.bedrooms || "",
            bathrooms: features?.bathrooms || "",
            area_sqft: features?.area_sqft || "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const updateData = { ...values, id: id }

            try {
                const response = await updatePropertyFeatures(updateData).unwrap();
                if (response) {
                    toast.success("Property features updated succesfully!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                showError(error?.data)
            }
        },
    });

    return (
        <CustomPaperBox>
            <Box sx={{
                "& .MuiTypography-body1": {
                    color: theme.palette.primary.main, fontSize: '22px', marginBottom: '15px'
                }
            }}><Typography >Features</Typography></Box>
            <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <CustomInput
                        id="bedrooms"
                        name="bedrooms"
                        label="Bedrooms"
                        value={featuresFormik.values.bedrooms}
                        onChange={featuresFormik.handleChange}
                        type="number"
                        onBlur={featuresFormik.handleBlur}
                        error={
                            featuresFormik.touched.bedrooms &&
                            Boolean(featuresFormik.errors.bedrooms)
                        }
                        helperText={
                            featuresFormik.touched.bedrooms && featuresFormik.errors.bedrooms
                        }
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <CustomInput
                        label="Bathrooms"
                        id="bathrooms"
                        name="bathrooms"
                        type="number"
                        value={featuresFormik.values.bathrooms}
                        onChange={featuresFormik.handleChange}
                        onBlur={featuresFormik.handleBlur}
                        error={
                            featuresFormik.touched.bathrooms &&
                            Boolean(featuresFormik.errors.bathrooms)
                        }
                        helperText={
                            featuresFormik.touched.bathrooms && featuresFormik.errors.bathrooms
                        }
                    />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <CustomInput label="Area sqft"
                        id="area_sqft"
                        name="area_sqft"
                        type="number"
                        value={featuresFormik.values.area_sqft}
                        onChange={featuresFormik.handleChange}
                        onBlur={featuresFormik.handleBlur}
                        error={
                            featuresFormik.touched.area_sqft &&
                            Boolean(featuresFormik.errors.area_sqft)
                        }
                        helperText={
                            featuresFormik.touched.area_sqft && featuresFormik.errors.area_sqft
                        }
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} >
                    <Box sx={{
                        textAlign: 'end',
                        marginTop: '15px'
                    }}>
                        <CustomButton
                            color="primary"
                            type="submit"
                            isLoading={isUpdateLoading}
                            onClick={featuresFormik.handleSubmit}
                            disabled={isUpdateLoading}
                            buttonName={id ? "Update" : "Next"} />
                    </Box>
                </Grid>
            </Grid>
        </CustomPaperBox>
    )
}

export default PropertyFeatures
