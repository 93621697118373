import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import Lightbox from 'react-18-image-lightbox';
import CustomButton from '../../theme/style-components/CustomButton';
import YouTube from 'react-youtube';

const ImageGrid = ({ lightBoxImage }) => {
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const handleImageClick = (imgIndex) => {
        setIsLightBoxOpen(true);
        setPhotoIndex(imgIndex)
    };

    const extractVideoId = (url) => {
        const match = url.match(/[?&]v=([^?&]+)/);
        return match && match[1] ? match[1] : '';
    };

    const youtubeUrl = 'https://www.youtube.com/watch?v=M-j_LvEK2ZA&ab_channel=OGVideography';
    const videoId = extractVideoId(youtubeUrl);

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            controls: 0,
            modestbranding: 0,
            showinfo: 0,
            controls: 0, // Setting controls to 0 hides the default YouTube controls
            fs: 0,
        },
    };


    const handleVideoClick = (event) => {
        // Your custom logic when the video is clicked
        console.log('Video clicked!');
        // You can pause/play the video or perform other actions here
    };

    const onPlay = (event) => {
        // access to player in all event handlers via event.target
        console.log("hello");
        event.target.pauseVideo();
    }


    const calculateGridSize = (totalImages, image, index) => {
        if (totalImages?.length === 1) {
            return (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={{
                        height: "428px",
                        "& img": {
                            cursor: "pointer",
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "10px"
                        }
                    }}>
                        <img onClick={handleImageClick} src={totalImages[0]} alt={`Image ${index + 1}`} />
                    </Box>
                </Grid>
            )
        } else if (totalImages?.length === 2 && index === 0) {
            return (
                <>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box sx={{
                            height: "428px",
                            "& img": {
                                cursor: "pointer",
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "10px"
                            }
                        }}>
                            <img onClick={() => handleImageClick(0)} src={totalImages[0]} alt={`Image ${index + 1}`} />

                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box sx={{
                            height: "428px",
                            "& img": {
                                cursor: "pointer",
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "10px"
                            }
                        }}>
                            <img onClick={() => handleImageClick(1)} src={totalImages[1]} alt={`Image ${index + 1}`} />
                        </Box>
                    </Grid>
                </>
            )
        } else if (totalImages?.length === 3 && index === 0) {
            return (
                <>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box sx={{
                            height: "428px",
                            "& img": {
                                cursor: "pointer",
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "10px"
                            }
                        }}>
                            <img onClick={() => handleImageClick(0)} src={totalImages[0]} alt={`Image ${index + 1}`} />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(1)} src={totalImages[1]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(2)} src={totalImages[2]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )
        } else if (totalImages?.length === 4 && index === 0) {
            return (
                <>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box sx={{
                            height: "428px",
                            "& img": {
                                cursor: "pointer",
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "10px"
                            }
                        }}>
                            <img src={totalImages[0]} onClick={() => handleImageClick(0)} alt={`Image ${index + 1}`} />

                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(1)} src={totalImages[1]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(2)} src={totalImages[2]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(3)} src={totalImages[3]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )
        } else if (totalImages?.length >= 5 && index === 0) {
            return (
                <>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box sx={{
                            height: "428px",
                            "& img": {
                                cursor: "pointer",
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "10px"
                            }
                        }}>
                            <img onClick={() => handleImageClick(0)} src={totalImages[0]} alt={`Image ${index + 1}`} />

                        </Box>
                    </Grid>
                    <Grid item key={index + "index"} xs={6} sm={6} md={6} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(1)} src={totalImages[1]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(2)} src={totalImages[2]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(3)} src={totalImages[3]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box sx={{
                                    height: "210px",
                                    "& img": {
                                        cursor: "pointer",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }
                                }}>
                                    <img onClick={() => handleImageClick(4)} src={totalImages[4]} alt={`Image ${index + 1}`} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )
        }
    };

    return (
        <Box sx={{
            position: "relative",
            display: { lg: "block", md: "block", sm: "none", xs: "none" }
        }}>
            <Grid container spacing={1}>
                {lightBoxImage.map((image, index) => (
                    <React.Fragment key={index}>
                        {calculateGridSize(lightBoxImage, image, index)}
                    </React.Fragment>
                ))}
            </Grid>
            <Box sx={{
                position: 'absolute',
                bottom: '-15px',
                right: '20px',
            }}>
                <CustomButton onClick={() => handleImageClick(0)} startIcon={<ImageIcon />} buttonName={`${lightBoxImage?.length} photos`} />
            </Box>
            {isLightBoxOpen && lightBoxImage?.length > 0 && (
                <Box>
                    <Lightbox
                        imageTitle={`${photoIndex + 1}/${lightBoxImage.length}`}
                        mainSrc={lightBoxImage[photoIndex]}
                        nextSrc={lightBoxImage[(photoIndex + 1) % lightBoxImage.length]}
                        prevSrc={lightBoxImage[(photoIndex + lightBoxImage.length - 1) % lightBoxImage.length]}
                        onCloseRequest={() => setIsLightBoxOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + lightBoxImage.length - 1) % lightBoxImage.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % lightBoxImage.length)}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ImageGrid;



