import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../store/apiSlice';
import authReducer from '../store/auth/authSlice';
import { persistReducer, persistStore } from 'redux-persist';
import persistConfig from './persistConfig';

const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
});

const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
    devtools: true,
});

export const persistor = persistStore(store);