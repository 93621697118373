import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubIcon from '@mui/icons-material/Bathtub';
import MarginIcon from '@mui/icons-material/Margin';
import CustomChips from '../../theme/style-components/CustomChips';
import GoogleMap from './GoogleMap';

const DetailsTab = ({ propertyDetails }) => {
    const theme = useTheme();
    const [gmapLink, setGmapLink] = useState(null);
    const GmapAPIKEY = process.env.REACT_APP_GmapAPIKEY;

    useEffect(() => {
        if (propertyDetails) {
            if (propertyDetails?.location?.map_link) {
                let gmap_link_start = "https://www.google.com/maps/place/";
                let map_link = propertyDetails?.location?.map_link;
                if (map_link.startsWith(gmap_link_start)) {
                    let q = map_link
                        .slice(gmap_link_start.length)
                        .split("/", 1);
                    if (q.length) {
                        q = q[0];
                        setGmapLink(
                            "https://www.google.com/maps/embed/v1/place?key=" +
                            GmapAPIKEY +
                            "&q=" +
                            q
                        );
                    }
                }
            }
        }
    }, [propertyDetails]);

    return (
        <div>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px',
            }}>
                <Box sx={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '10px',
                    textAlign: 'center',
                    width: '80px',
                    height: '80px',
                    flex: '0 0 80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& svg': {
                        fill: theme.palette.primary.main,
                    },
                    '& p': {
                        fontSize: '12px',
                        fontWeight: '500',
                    },
                }}>
                    <BedOutlinedIcon />
                    <Typography>{propertyDetails?.features?.bedrooms} Bedrooms</Typography>
                </Box>
                <Box sx={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '10px',
                    textAlign: 'center',
                    width: '80px',
                    height: '80px',
                    flex: '0 0 80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& svg': {
                        fill: theme.palette.primary.main,
                    },
                    '& p': {
                        fontSize: '12px',
                        fontWeight: '500',
                    },
                }}>
                    <BathtubIcon />
                    <Typography>{propertyDetails?.features?.bathrooms} Bathrooms</Typography>
                </Box>
                <Box sx={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '10px',
                    textAlign: 'center',
                    width: '80px',
                    height: '80px',
                    flex: '0 0 80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& svg': {
                        fill: theme.palette.primary.main,
                    },
                    '& p': {
                        fontSize: '12px',
                        fontWeight: '500',
                    },
                }}>
                    <MarginIcon />
                    <Typography>{propertyDetails?.features?.area_sqft} sqft</Typography>
                </Box>
            </Box>
            <Box sx={{
                marginTop: "20px",
                "& .MuiChip-root": {
                    marginRight: "10px"
                }
            }}>
                {propertyDetails?.amenities?.length > 0 && propertyDetails?.amenities?.map((item) => {
                    return (
                        <CustomChips color="primary" key={item?.id} label={item?.name} />
                    )
                })}
            </Box>
            <Box sx={{
                margin: '20px 0',
            }}>
                <Typography sx={{
                    fontSize: '18px',
                    fontWeight: '500',
                    marginBottom: '15px',
                }}>Investment Summary</Typography>

                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}>
                    <Box sx={{
                        marginRight: '1.25rem',
                        marginBottom: '1.25rem',
                        paddingLeft: '1rem',
                        borderWidth: '1px',
                        height: '100px',
                        width: '180px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'space-evenly',
                        borderRadius: '6px',
                        background: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    }}>
                        <Typography>Total Investment:</Typography>
                        <Typography>$ {propertyDetails?.financials?.current_market_value}</Typography>
                    </Box>
                    <Box sx={{
                        marginRight: '1.25rem',
                        marginBottom: '1.25rem',
                        paddingLeft: '1rem',
                        borderWidth: '1px',
                        height: '100px',
                        width: '180px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'space-evenly',
                        borderRadius: '6px',
                        background: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    }}>
                        <Typography>Token Price:</Typography>
                        <Typography>$ {propertyDetails?.tokenomics?.token_price_aed_cents / 100}</Typography>
                    </Box>
                    <Box sx={{
                        marginRight: '1.25rem',
                        marginBottom: '1.25rem',
                        paddingLeft: '1rem',
                        borderWidth: '1px',
                        height: '100px',
                        width: '180px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'space-evenly',
                        borderRadius: '6px',
                        background: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    }}>
                        <Typography>Total Tokens:</Typography>
                        <Typography> {propertyDetails?.tokenomics?.total_tokens}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                marginBottom: '20px',
            }}>
                <Typography sx={{
                    fontSize: '18px',
                    fontWeight: '500',
                    marginBottom: '15px',
                }}>About the property</Typography>
                <Box>
                    <Typography>{propertyDetails?.description
                    }</Typography>
                </Box>
            </Box>
            <Box sx={{
                marginBottom: '20px',
            }}>
                <Typography sx={{
                    fontSize: '18px',
                    fontWeight: '500',
                    marginBottom: '15px',
                }}>Location</Typography>
                <Box>
                    <Typography>{propertyDetails?.location?.address ? propertyDetails?.location?.address : null
                    }</Typography>
                </Box>
            </Box>
            {propertyDetails?.location?.lat && propertyDetails?.location?.long && <Box sx={{
                width: { lg: "550px", md: "550px", sm: "auto", xs: "auto",},       
            }}>
                <GoogleMap markers={{ lat: propertyDetails?.location?.lat, lng: propertyDetails?.location?.long }} />
            </Box>}
        </div>
    )
}

export default DetailsTab;
