import React from 'react';
import styled from '@emotion/styled';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../../asset/images/logos/logo.png"
import { routes } from '../../routes/RouteWrapper';
import { selectCurrentUser } from '../../store/auth/authSlice';
import { ReactComponent as Icon } from "../../asset/images/logos/1byx-logo-final.svg";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
    overflowX: 'hidden',
    minHeight: '100vh',

    [theme.breakpoints.up('xs')]: {
        position: 'fixed',
        zIndex: '999',
    },

    [theme.breakpoints.up('lg')]: {
        position: 'fixed'
    },

});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
    overflowX: 'hidden',
    // width: `calc(${theme.spacing(7)} + 1px)`,
    width: `calc(90px + 1px)`,



    [theme.breakpoints.up('xs')]: {
        // width: `calc(${theme.spacing(8)} + 1px)`,
        left: '-200px',
        zIndex: '999',
        position: 'fixed'
    },
    [theme.breakpoints.up('lg')]: {
        // width: `calc(${theme.spacing(8)} + 1px)`,
        left: '0px',
        zIndex: '999',
        position: 'fixed'
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const DrawerHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));


const Sidebar = ({ open }) => {
    const theme = useTheme();
    // const userType = useSelector(state => state.auth.userType)
    const location = useLocation();
    const currentUser = useSelector(selectCurrentUser);

    const isActive = (path) => {
        return location.pathname.includes(path.substring(1))
    }

    const filteredRoutes = routes.filter(route =>
        currentUser?.roles?.some(role => route.permissions.includes(role))
    );

    return (
        <Drawer
            variant="permanent"
            role="presentation"
            open={open}
        >
            <DrawerHeader>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // width: '100%',
                    // gap: '10px',
                    // marginTop: '15px',
                    // '& svg': {
                    //     width: '50px'
                    // }

                    cursor: "pointer",
                    width: "180px",
                    height: "50px",
                    "& svg": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    },
                }}>
                    {/* <img src={svgLogo} /> */}
                    <Icon />
                </Box>
            </DrawerHeader>

            <List sx={{
                marginTop: '7px'
            }}>
                {filteredRoutes?.map((route, index) => {
                    return (
                        <Box key={index}>
                            {
                                route.isMenu && <ListItem disablePadding
                                    sx={{
                                        display: 'block',
                                        '& a': {
                                            textDecoration: 'none'
                                        }
                                    }}
                                >
                                    <Link to={route.path}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                                color: isActive(route.path) ? theme.palette.primary.main : theme.palette.text.primary,
                                                borderRadius: '5px',
                                                margin: '10px 16px',
                                                borderRight: isActive(route.path) && open ? `3px solid ${theme.palette.primary.main}` : theme.palette.text.primary,
                                                borderTopRightRadius: isActive(route.path) && open ? `0` : "5px",
                                                borderBottomRightRadius: isActive(route.path) && open ? `0` : "5px",
                                                '& svg': {
                                                    color: isActive(route.path) ? theme.palette.primary.main : theme.palette.text.primary,

                                                },
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                    color: isActive(route.path) ? "#fff" : "#dbdbeb99",
                                                }}
                                            >
                                                {route.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={route.name} sx={{ height: open ? '100%' : '0px', opacity: open ? 1 : 0, whiteSpace: route.name?.length >= 20 ? 'normal' : "unset" }} />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            }
                        </Box>
                    )
                })}
            </List>

        </Drawer >
    );
};

export default Sidebar;
export { DrawerHeader }; // Exporting DrawerHeader component