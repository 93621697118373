import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';

export const formatAmount = (amount) => {
    return Number(amount).toLocaleString('en-IN')
};

export const showError = (errors) => {
    console.log("got error", errors);
    if (errors?.errors) {
        const errorProperty = Object.keys(errors?.errors)[0];
        if (errors?.errors[errorProperty].length > 0) {
            const firstError = errors?.errors[errorProperty][0];
            toast.error(`${errorProperty}: ${firstError}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            console.log("No errors found.");
        }
    }
    if (!errors?.message && !errors?.errors && !errors.detail) {
        const errorProperty = Object.keys(errors)[0];
        const firstError = errors[errorProperty]?.[0]; // Use optional chaining here
        if (errorProperty && firstError) {
            toast.error(`${errorProperty}: ${firstError}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }
    if (errors?.message) {
        toast.error(errors?.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    if (errors?.detail) {
        toast.error(errors?.detail, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

}

export const encryptFn = (text) => {
    try {
        if (text) {
            const encrypted = CryptoJS.AES.encrypt(text, process.env.REACT_APP_ENCRYPT_KEY).toString();
            return encrypted;
        } else {
            return '';
        }
    } catch (error) {
        console.error('Encryption error:', error);
        return '';
    }
}

export const decryptFn = (text) => {
    try {
        if (text) {
            // Decryption
            const decryptedBytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_ENCRYPT_KEY);
            const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
            return decryptedString;
        } else {
            return '';
        }
    } catch (error) {
        console.error('Decryption error:', error);
        return '';
    }
}

export const convertAedToDollar = (amount) => {
    return amount / 100
}  