import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Backdrop, Box, Grid, Typography, useTheme } from "@mui/material";
import CustomButton from "../../theme/style-components/CustomButton";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import { selectCurrentUser } from "../../store/auth/authSlice";
import CheckoutForm from "../../checkout/CheckoutForm";
import Loader from "../loader/Loader";
import ComponentTitle from "../shared/ComponentTitle";
import { useStripeIntentPropertyMutation } from "../../store/payment/PaymentApiSlice";
import { showError } from "../shared/helperFunction";


const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBKEY
);

const InvestPay = () => {
    const navigate = useNavigate();
    const [stripeIntentProperty, { isLoading, isError }] = useStripeIntentPropertyMutation();
    const [message, setMessage] = useState("");
    const [cardLoading, setCardLoading] = useState(false);
    const { id } = useSelector(selectCurrentUser);
    const theme = useTheme();
    const { state } = useLocation();

    console.log(state, "state");

    //stripe options
    const [options, setOptions] = useState({
        clientSecret: "",
    });

    useEffect(() => {
        const payment = async () => {
            try {
                setCardLoading(true);
                let response = await stripeIntentProperty({
                    property_id: state?.prop_id, num_tokens_to_purchase: state?.num_tokens_to_purchase,
                }).unwrap();
                let successMessage = "";

                if (response) {
                    setCardLoading(false);
                }

                setOptions({
                    clientSecret: response.data.clientSecret,
                });
                setMessage(successMessage);
            } catch (err) {
                if (err) {
                    let errorMessage;
                    setCardLoading(false);
                    for (const key in err.data.errors) {
                        if (Array.isArray(err.data.errors[key]) && err.data.errors[key].length > 0) {
                            errorMessage = err.data.errors[key][0];
                            break;
                        }
                    }
                    //     if (errorMessage) {
                    //         toast.error(errorMessage, {
                    //             position: toast.POSITION.TOP_RIGHT
                    //         });
                    //     }
                    showError(err?.data)
                }
            }
        };
        payment();
    }, []);

    return (
        <Box>
            <ComponentTitle title="pay" />
            <Box sx={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <Typography variant="h5" fontWeight={600}>Card Details</Typography>
                <CustomButton outline color="primary" buttonName="Back" onClick={() => navigate(-1)} />
            </Box>
            {cardLoading ? <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={cardLoading}
            >
                <Loader />
            </Backdrop> :
                <CustomPaperBox >
                    <Box>
                        <Grid container spacing={2} >
                            {options?.clientSecret ?
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box sx={{
                                        width: "100%",
                                        maxWidth: "50%",
                                        margin: "0 auto"
                                    }}>
                                        <Box sx={{
                                            padding: "20px"
                                        }}>
                                            <Elements stripe={stripePromise} options={options}>
                                                <CheckoutForm />
                                            </Elements>
                                        </Box>
                                    </Box>
                                </Grid>
                                :
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography>Card not found</Typography>
                                </Grid>
                            }
                        </Grid >
                    </Box >
                </CustomPaperBox >
            }
        </Box >
    )
};

export default InvestPay;

