import { Box, useTheme } from '@mui/material'
import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EventBusyIcon from '@mui/icons-material/EventBusy';

const BuyingProcess = ({ propertyDetails }) => {
    const theme = useTheme();

    return (
        <Box>
            <Box sx={{
                '& .vertical-timeline-element-content-arrow': {
                    borderRightColor: '#eee'
                },
                '& .vertical-timeline': {
                    '&::before': {
                        background: theme.palette.primary.main,
                    },
                }
            }}>


                <VerticalTimeline layout={"1-column-left"}>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: theme.palette.background.paper,
                        }}
                        // date="2011 - present"
                        iconStyle={{
                            background: theme.palette.background.paper,
                        }}
                        contentArrowStyle={{
                            borderRight: "0px solid  rgb(33, 150, 243)",
                        }}
                        icon={
                            propertyDetails.buying_status ? (
                                propertyDetails.buying_status.tokens_created_on ? (
                                    <CheckCircleOutlineIcon />
                                ) : (
                                    <EventBusyIcon />
                                )
                            ) : (
                                <EventBusyIcon />
                            )
                        }
                    >
                        <h3>
                            {propertyDetails.buying_status
                                ? propertyDetails.buying_status.tokens_offering_ended_on
                                    ? propertyDetails.buying_status
                                        .tokens_offering_ended_on
                                    : "TBD"
                                : "TBD"}
                        </h3>
                        <h4 className="vertical-timeline-element-subtitle">
                            {/* <Text color={textColor} fontSize='sm' fontWeight='700'> */}
                            Property put under contract by LLC
                            {/* </Text> */}
                        </h4>
                        <p>SPV paperwork is complete and LLC is tokenized.</p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: theme.palette.background.paper,
                        }}
                        // date="2011 - present"
                        iconStyle={{
                            background: theme.palette.background.paper,
                        }}
                        icon={
                            propertyDetails.buying_status ? (
                                propertyDetails.buying_status.tokens_created_on ? (
                                    <CheckCircleOutlineIcon />
                                ) : (
                                    <EventBusyIcon />
                                )
                            ) : (
                                <EventBusyIcon />
                            )
                        }                                               >
                        <h3 className="vertical-timeline-element-title">
                            TBD
                        </h3>
                        <h4 className="vertical-timeline-element-subtitle">
                            Token Offering
                        </h4>
                        <p>
                            tokens are available to purchase. Once purchased,
                            tokens will automatically be sent to the ethereum
                            wallet address.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: theme.palette.background.paper,
                        }}
                        // date="2011 - present"
                        iconStyle={{
                            background: theme.palette.background.paper,
                        }}
                        icon={
                            propertyDetails.buying_status ? (
                                propertyDetails.buying_status.tokens_created_on ? (
                                    <CheckCircleOutlineIcon />
                                ) : (
                                    <EventBusyIcon />
                                )
                            ) : (
                                <EventBusyIcon />
                            )
                        }
                    >
                        <h3 className="vertical-timeline-element-title">
                            TBD
                        </h3>
                        <h4 className="vertical-timeline-element-subtitle">
                            Token Offering Complete
                        </h4>
                        <p>
                            The value of your tokens over time is based on the
                            fair-market-value (FMV) of the property itself. The
                            fair-market-value (FMV) of the property over time is
                            determined by taking the rate-of-change of the
                            HouseCanary Automated Valuation Model (AVM) on a
                            monthly basis, then applying that same
                            rate-of-change to your token’s Principle Value on a
                            monthly basis. All properties will have their FMV
                            updated around one month after the property sale is
                            recorded on HouseCanary. After that, the FMV will be
                            updated monthly moving forward. The HouseCanary AVM
                            is updated once per month between the 28th-7th, so
                            our properties' FMV update will occur within that
                            time frame as well. Every time the FMV updates for a
                            property, there will be a report from HouseCanary
                            attached which walks through exactly how their AVM
                            was calculated. This process is done to estimate an
                            increase or a decrease in your property's value over
                            time.
                        </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </Box>
        </Box>
    )
}

export default BuyingProcess
