import { Box, Grid, IconButton, TableHead, Typography, useTheme } from "@mui/material";
import Layout from "../layout/Layout";
import TabTitle from "../shared/TabTitle";
import ComponentTitle from "../shared/ComponentTitle";
import BarChartIcon from '@mui/icons-material/BarChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useGetDashboardDataQuery } from "../../store/dashboard/dashboadApiSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import walletAnimation from "../../asset/wallet_animation.json"
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";


const Wallet = () => {

    //base query hooks
    // const {
    //     isLoading,
    //     data: dashboardReturned,
    //     isSuccess,
    //     isError,
    //     refetch: refetchDashboard
    // } = useGetDashboardDataQuery();

    // const [dashboardData, setDashboardData] = useState({});


    // const breadcrumbs = [
    //     <Typography key="1" variant="body1">
    //         Wallet
    //     </Typography>,
    // ];
    const theme = useTheme();

    // const [page, setPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(15);
    // const navigate = useNavigate();


    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    // const columns = [
    //     { id: 'id', label: 'Id' },
    //     { id: 'name', label: 'Name' },
    //     { id: 'balance', label: 'balance' },
    //     { id: 'value', label: 'Value' },
    //     { id: 'action', label: 'Action' },
    // ];

    // const data = dashboardData?.invested_properties?.length > 0 ? dashboardData?.invested_properties?.map((data) => ({
    //     id: data.property_id,
    //     name: data?.property_name,
    //     balance: data?.token_balance,
    //     value: data?.token_value,
    //     action: "ACTION"
    // })) : []

    // const setSelectedData = (item) => {
    //     console.log(item, "irtem");
    // };

    // const handleAction = (event) => {
    //     // setAnchorEl(event.currentTarget);
    //     console.log(event.currentTarget);
    // };

    // const count = 1;

    // useEffect(() => {
    //     if (!isLoading && isSuccess) {
    //         setDashboardData(dashboardReturned?.data);
    //         // setCount(dashboardReturned?.page_details?.count)
    //     }
    // }, [isLoading, page, dashboardReturned?.data]);

    // //handle page function
    // const onPageChange = (value) => {
    //     setPage(value)
    // };

    // //handle row page function
    // const onRowsPerPageChange = (value) => {
    //     setRowsPerPage(value)
    // };

    // const handleCreateClick = () => {
    //     navigate("/orders/create")
    // };

    // const handleEditClick = () => {
    //     navigate(`/orders/10`)
    // };

    return (
        <CustomPaperBox>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "0 auto"
            }}>
                <Lottie animationData={walletAnimation} loop={true} style={{ height: "300px", width: "300px" }} />
                <Typography textAlign="center" fontSize="20px" color={`${theme.palette.primary.main}`}>Our 1ByX multichain property wallet is diligently upgrading – we appreciate your patience for the enhanced experience!</Typography>
            </Box>
        </CustomPaperBox>
    )
};

export default Wallet;