import { apiSlice } from "../apiSlice";

export const propertyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPropertyList: builder.query({
            query: ({ listing_status, id, page, query }) => `/property/get_listings/${page ? `?page=${page}` : ""}${id ? `&id=${id}` : ""}${listing_status ? `&listing_status=${listing_status}` : ""}${query}`,
            keepUnusedDataFor: 0,
        }),
        getMyPropertyListing: builder.query({
            query: ({ listing_status, id, page, query }) => `/property/my_listings/${page ? `?page=${page}` : ""}${id ? `&id=${id}` : ""}${query ? query : ""}${listing_status ? `&listing_status=${listing_status}` : ""}`,
            keepUnusedDataFor: 0,
        }),
        deleteMyListing: builder.mutation({
            query: (id) => ({
                url: `/property/my_listings/?id=${id}`,
                method: "DELETE",
            })
        }),
        buyProperty: builder.query({
            query: ({ listing_status, id }) => `/property/property/buy/`,
            keepUnusedDataFor: 0,
        }),
        buyProperty: builder.mutation({
            query: ({ property_id, token_amount }) => ({
                url: "/property/property/buy/",
                method: "PUT",
                body: {
                    property_id: property_id,
                    token_amount: token_amount,
                },
            })
        }),
        createProperty: builder.mutation({
            query: (data) => ({
                url: "/property/listings/",
                method: "POST",
                body: { ...data },
            })
        }),
        updateProperty: builder.mutation({
            query: (data) => ({
                url: "/property/listings/",
                method: "PUT",
                body: { ...data },
            })
        }),
        updatePropertyFeatures: builder.mutation({
            query: (data) => ({
                url: "/property/property_features/",
                method: "PUT",
                body: { ...data },
            })
        }),
        updatePropertyImages: builder.mutation({
            query: (data) => ({
                url: "/property/property_images/",
                method: "PUT",
                body: data,
            })
        }),
        updatePropertyVideoURL: builder.mutation({
            query: (data) => ({
                url: "/property/property_videos/",
                method: "PUT",
                body: data,
            })
        }),
        updatePropertyLocation: builder.mutation({
            query: (data) => ({
                url: "/property/property_location/",
                method: "PUT",
                body: data,
            })
        }),
        updatePropertyFinancials: builder.mutation({
            query: (data) => ({
                url: "/property/property_financials/",
                method: "PUT",
                body: data,
            })
        }),
        updatePropertyDocuments: builder.mutation({
            query: (data) => ({
                url: "/property/property_docs/",
                method: "PUT",
                body: data,
            })
        }),
        deletePropertyDocuments: builder.mutation({
            query: (data) => ({
                url: `/property/property_docs/?doc_id=${data.doc_id}`,
                method: "DELETE",
            })
        }),
        deletePropertyImages: builder.mutation({
            query: (id) => ({
                url: `/property/property_images/?doc_id=${id}`,
                method: "DELETE",
            })
        }),
        deletePropertyVideoURL: builder.mutation({
            query: (id) => ({
                url: `/property/property_videos/?video_id=${id}`,
                method: "DELETE",
            })
        }),
    }),
});

export const {
    useGetPropertyListQuery,
    useLazyGetPropertyListQuery,
    useBuyPropertyMutation,
    useCreatePropertyMutation,
    useUpdatePropertyMutation,
    useGetMyPropertyListingQuery,
    useLazyGetMyPropertyListingQuery,
    useUpdatePropertyFeaturesMutation,
    useUpdatePropertyImagesMutation,
    useUpdatePropertyLocationMutation,
    useUpdatePropertyFinancialsMutation,
    useUpdatePropertyDocumentsMutation,
    useDeletePropertyDocumentsMutation,
    useDeleteMyListingMutation,
    useDeletePropertyImagesMutation,
    useUpdatePropertyVideoURLMutation,
    useDeletePropertyVideoURLMutation
} = propertyApiSlice;