import { Box, FormControlLabel, Typography, styled, useTheme, withStyles } from '@mui/material';
import React from 'react';
import Switch from '@mui/material/Switch';


const CustomSwitch = ({ onChange, checked, value, label }) => {
    const theme = useTheme();
    return (
        <Box display="flex" gap="10px">
            <Box sx={{
                '& input[type="checkbox"]': {
                    height: 0,
                    width: 0,
                    visibility: 'hidden',
                    display: "none"
                },
                '& label': {
                    cursor: 'pointer',
                    textIndent: '-9999px',
                    width: '50px',
                    height: '26px',
                    background: 'grey',
                    display: 'block',
                    borderRadius: '100px',
                    position: 'relative',
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        top: '2px',
                        left: '2px',
                        width: '22px',
                        height: '22px',
                        background: '#fff',
                        borderRadius: '90px',
                        transition: '0.3s',
                    },
                },
                '& input:checked + label': {
                    background: theme.palette.primary.main,
                },
                '& input:checked + label:after': {
                    left: 'calc(100% - 2px)',
                    transform: 'translateX(-100%)',
                },
                '& label:active:after': {
                    width: '30px',
                },
            }}>
                <input type="checkbox" onChange={onChange} checked={checked} id="switch" /><label for="switch">Toggle</label>
            </Box>
            <Typography>{label}</Typography>
        </Box>
    )
}

export default CustomSwitch
