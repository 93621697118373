import React from 'react';
import Lottie from "lottie-react";
import roundLoader from './new-loader.json';
import { Box } from "@mui/material";

const Loader = () => {
    const style = {
        height: 50,
    };

    return (
        <Box>
            <Lottie style={style} animationData={roundLoader} loop={true} />
        </Box>
    );
};

export default Loader;