import { Box } from '@mui/material'
import React from 'react'
import YouTube from 'react-youtube';

const VideoTab = ({ propertyDetails }) => {
    const opts = {
        height: '315',
        width: '700',
        playerVars: {
            autoplay: 0,
            controls: 1, // Setting controls to 0 hides the default YouTube controls
            modestbranding: 0,
            showinfo: 0,
            fs: 0,
            rel: 0,
        },
    };

    return (
        propertyDetails?.videos?.map((item) => {
            const videoId = extractVideoId(item?.url);
            return (
                <Box sx={{
                    marginBottom: "20px",
                    "& .youtube-iframe": {
                        borderRadius: "10px",
                        width: "100%"
                    }
                }}>
                    <YouTube iframeClassName="youtube-iframe" videoId={videoId} opts={opts} />
                </Box>
            )
        })
    )
};

const extractVideoId = (url) => {
    const match = url.match(/[?&]v=([^?&]+)/);
    return match && match[1] ? match[1] : '';
};

export default VideoTab
