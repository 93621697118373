import React from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import CustomInput from '../../theme/style-components/CustomInput'
import { Box, FormHelperText, FormLabel, Grid, MenuItem, Typography, useTheme } from '@mui/material'
import CustomButton from '../../theme/style-components/CustomButton'
import { useFormik } from 'formik'
import * as yup from "yup";
import { toast } from 'react-toastify'
import { showError } from '../shared/helperFunction'
import CustomSelect from '../../theme/style-components/CustomSelect'
import { useCreatePropertyMutation, useUpdatePropertyMutation } from '../../store/property/propertyApiSlice'
import { Link, useNavigate } from 'react-router-dom'
import CustomCheckBox from '../../theme/style-components/CustomCheckBox'
import { useState } from 'react'
import { useEffect } from 'react'
import ComponentTitle from '../shared/ComponentTitle'

const CreateProperty = ({ id, property }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [updateProperty, { isLoading: isUpdateLoading }] = useUpdatePropertyMutation();
    const [createProperty, { isLoading: isCreateLoading }] = useCreatePropertyMutation();

    const validationSchema = yup.object({
        name: yup
            .string("Please enter name")
            .min(3, "Name must contain 3 characters minimum")
            .required("Name is required")
            .max(100, "Name can contain a maximum of 100 characters"),
        title: yup
            .string("Please enter title")
            .min(3, "Title must contain 3 characters minimum")
            .required("Title is required")
            .max(100, "Title can contain a maximum of 100 characters"),
        description: yup
            .string("Please enter description")
            .min(3, "Description must contain 3 characters minimum")
            .required("Description is required")
            .max(600, "Description can contain a maximum of 600 characters"),
        tenant_status: yup
            .string("Please select tenant status")
            .required("Tenant status is required"),
        property_type: yup
            .string("Please select property type")
            .required("Property type is required"),
        // amenities:
        //     yup.array()
        //         .of(yup.number()) // Each selected option should be a string
        //         .min(1, 'Select at least one option') // Ensure at least one option is selected  
    });

    const propertyFormik = useFormik({
        initialValues: {
            name: property?.name || "",
            title: property?.title || "",
            description: property?.description || "",
            tenant_status: property?.tenant_status || "OCUPIED",
            property_type: property?.property_type || "RESEDENTIAL",
            amenities: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("values", values);
            const createData = { ...values };
            const updateData = { ...values, id: id }

            if (id) {
                try {
                    const response = await updateProperty(updateData).unwrap();
                    if (response) {
                        toast.success("Property updated succesfully!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } catch (error) {
                    showError(error?.data)
                }
            } else {
                try {
                    const response = await createProperty(createData).unwrap();
                    if (response) {
                        toast.success("Property created succesfully!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        navigate(`/my-listings/edit-properties/${response?.data?.id}`)
                    }

                } catch (error) {
                    showError(error?.data)
                }
            }
        },
    });

    const [amenitiesOptions, setAmenitiesOptions] = useState([
        { label: 'pool', value: 1, isChecked: false },
        { label: 'gym', value: 2, isChecked: false },
        { label: 'club house', value: 3, isChecked: false },
    ]);

    useEffect(() => {
        if (property?.amenities?.length > 0) {
            // Use the setAmenitiesOptions function to update state immutably
            setAmenitiesOptions(prevOptions =>
                prevOptions.map(amen => ({
                    ...amen,
                    isChecked: property.amenities.some(item => item.id === amen.value),
                }))
            );
        }
    }, [property?.amenities]);

    useEffect(() => {
        const selectedAmen = amenitiesOptions
            .map((item) => {
                if (item.isChecked) {
                    return item.value;
                }
                return null;
            })
            .filter((value) => value !== null);
        propertyFormik.setFieldValue("amenities", selectedAmen)
    }, [amenitiesOptions])


    const breadcrumbs = [
        <Link to="/my-listings">
            Properties
        </Link>,
        <Typography key="2" variant="body1">
            Create Property
        </Typography>,
    ];


    return (
        <>
            {!id && <ComponentTitle breadcrumbs={breadcrumbs} />}
            <CustomPaperBox>
                <Box sx={{
                    "& .MuiTypography-body1": {
                        color: theme.palette.primary.main, fontSize: '22px', marginBottom: '10px'
                    }
                }}><Typography >Basic Information</Typography></Box>
                <Grid container spacing={3}>
                    <Grid item lg={6} md={6} sm={12} xs={12} >
                        <CustomInput
                            id="name"
                            name="name"
                            label="Name"
                            value={propertyFormik.values.name}
                            onChange={propertyFormik.handleChange}
                            error={
                                propertyFormik.touched.name &&
                                Boolean(propertyFormik.errors.name)
                            }
                            helperText={
                                propertyFormik.touched.name && propertyFormik.errors.name
                            }
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} >
                        <CustomInput
                            label="Title"
                            id="title"
                            name="title"
                            value={propertyFormik.values.title}
                            onChange={propertyFormik.handleChange}
                            error={
                                propertyFormik.touched.title &&
                                Boolean(propertyFormik.errors.title)
                            }
                            helperText={
                                propertyFormik.touched.title && propertyFormik.errors.title
                            }
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <CustomInput minRows={3} multiline={true} label="Description"
                            id="description"
                            name="description"
                            value={propertyFormik.values.description}
                            onChange={propertyFormik.handleChange}
                            error={
                                propertyFormik.touched.description &&
                                Boolean(propertyFormik.errors.description)
                            }
                            helperText={
                                propertyFormik.touched.description && propertyFormik.errors.description
                            }
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomSelect
                                label="Tenant Status"
                                id="tenant_status"
                                name="tenant_status"
                                value={propertyFormik.values.tenant_status}
                                onChange={propertyFormik.handleChange}
                                error={
                                    propertyFormik.touched.tenant_status &&
                                    Boolean(propertyFormik.errors.tenant_status)
                                }
                                helperText={
                                    propertyFormik.touched.tenant_status && propertyFormik.errors.tenant_status
                                }
                            >
                                <MenuItem value="OCUPIED">Ocupied</MenuItem>
                                <MenuItem value="VACANT ">Vacant</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomSelect
                                label="Property Type"
                                id="property_type"
                                name="property_type"
                                value={propertyFormik.values.property_type}
                                onChange={propertyFormik.handleChange}
                                error={
                                    propertyFormik.touched.property_type &&
                                    Boolean(propertyFormik.errors.property_type)
                                }
                                helperText={
                                    propertyFormik.touched.property_type && propertyFormik.errors.property_type
                                }
                            >
                                <MenuItem value="RESEDENTIAL">Resedential</MenuItem>
                                <MenuItem value="COMMERCIAL">Commercial</MenuItem>
                            </CustomSelect>
                        </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} >
                        <Box sx={{
                            marginBottom: "10px",
                            marginLeft: '15px'
                        }}>
                            <FormLabel sx={{
                                color: theme.palette.primary.main,
                            }}>Amenities:</FormLabel>
                        </Box>
                        <Box sx={{
                            border: `1px solid ${propertyFormik.touched.amenities &&
                                Boolean(propertyFormik.errors.amenities) ? theme.palette.error.main : theme.palette.grey[300]}`,
                            padding: "20px",
                            borderRadius: "20px"
                        }}>
                            {amenitiesOptions.length > 0 && amenitiesOptions.map((item, index) => {
                                return (
                                    <CustomCheckBox
                                        key={index}
                                        label={item.label}
                                        checked={item.isChecked}
                                        name="amenities"
                                        value={item.value}
                                        onBlur={propertyFormik.onBlur}
                                        onChange={(e) => {
                                            setAmenitiesOptions(prevOptions =>
                                                prevOptions.map(amen => {
                                                    if (Number(amen.value) === Number(e.target.value)) {
                                                        return ({
                                                            ...amen,
                                                            isChecked: !amen.isChecked,
                                                        })
                                                    } else {
                                                        return amen
                                                    }
                                                })
                                            );
                                        }}
                                    />
                                )
                            })}
                        </Box>
                        <Box sx={{
                            padding: "0px 10px"
                        }}>
                            <FormHelperText error={
                                propertyFormik.touched.amenities &&
                                Boolean(propertyFormik.errors.amenities)
                            }>{propertyFormik.touched.amenities && propertyFormik.errors.amenities}</FormHelperText>
                        </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} >
                        <Box sx={{
                            textAlign: 'end',
                            marginTop: '15px'
                        }}>
                            <CustomButton
                                color="primary"
                                type="submit"
                                isLoading={isCreateLoading || isUpdateLoading}
                                onClick={propertyFormik.handleSubmit}
                                disabled={isCreateLoading || isUpdateLoading}
                                buttonName={id ? "Update" : "Next"} />
                        </Box>
                    </Grid>
                </Grid>
            </CustomPaperBox>
        </>
    )
}

export default CreateProperty;
