import { apiSlice } from "../apiSlice";


export const userProfileApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUserProfile: builder.query({
            query: () => `/user/me/`,
            keepUnusedDataFor: 0,
        }),
        getPublicUser: builder.query({
            query: ({ page, query }) => `/user/public_user/?page=${page}${query}`,
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    useGetUserProfileQuery,
    useLazyGetUserProfileQuery,
    useGetPublicUserQuery,
    useLazyGetPublicUserQuery,
} = userProfileApiSlice;