import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, useTheme } from '@mui/material';

export default function CustomCheckBox({ checked, id, onChange, name, label, value, disabled }) {
    const theme = useTheme();
    return (
        <FormControlLabel sx={{
            "& .MuiTypography-root": {
                color: `${theme.palette.primary.main} !important`
            }
        }} control={<Checkbox
            sx={{
                '& svg': {
                    fill: theme.palette.primary.main
                }
            }}
            id={id}
            disabled={disabled}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />} label={label} />
    );
}