import { Backdrop, Box, CircularProgress, Grid, Stack, TablePagination, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/auth/authSlice';
import Loader from '../loader/Loader';
import { useLazyGetPublicUserQuery } from '../../store/user-profile/userProfileApiSlice';
import { showError } from '../shared/helperFunction';
import { useNavigate } from 'react-router-dom';
import TabTitle from '../shared/TabTitle';
import ComponentTitle from '../shared/ComponentTitle';
import CustomPaperBox from '../../theme/style-components/CustomPaperBox';
import CustomInput from '../../theme/style-components/CustomInput';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import Skeleton from 'react-loading-skeleton';

const FindBrokers = () => {
    const [page, setPage] = useState(1);
    const [count, setCount] = useState();
    const [searchedText, setSearchedText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [brokerData, setBrokerData] = useState([]);
    const [query, setQuery] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    const currentUser = useSelector(selectCurrentUser);

    const [getPublicUser, { isLoading: isGetLoading, data: returnedData,
        isSuccess: isGetSuccess, isError: isGetError }] = useLazyGetPublicUserQuery();

    useEffect(() => {
        if (currentUser) {
            let newQuery = query;

            // Remove existing search parameters from the query
            newQuery = newQuery.replace(/&user_role=[^&]*/g, '');

            // Add new search parameter if search text is not empty
            newQuery += `&user_role=${"REALESTATE_BROKER"}`;
            setQuery(newQuery);

        }
    }, [currentUser]);

    useEffect(() => {
        if (query) {
            try {
                const result = getPublicUser({ page: page, query: query }).unwrap().then((res) => {
                    setBrokerData(res?.data);
                    setCount(res?.page_details?.count)
                }).catch(err => {
                    showError(err?.data)
                })
            } catch (err) {
                showError(err?.data)
            }
        }
    }, [query, page]);

    const breadcrumbs = [
        <Typography key="1" variant="body1">
            Brokers
        </Typography>,
    ];

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchedText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&user_search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `&user_search=${encodeURIComponent(newSearchText)}`;
        }
        setQuery(newQuery);
    };
    console.log("isGetLoading", isGetLoading);
    return (
        <Box>
            <TabTitle title="find-broker" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <CustomPaperBox>
                <Box sx={{ margin: "20px 0" }}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box>
                                <CustomInput type="search" onChange={handleSearch} value={searchedText} placeholder="Search by name" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    {!isGetLoading && isGetSuccess ? returnedData?.data?.length > 0 ? returnedData?.data?.map((item) => {
                        return (
                            <Grid key={item.id} item lg={3} md={3} sm={4} xs={12}>
                                <Box onClick={() => navigate(`/find-brokers/${item.id}`)} sx={{
                                    textAlign: 'center',
                                    background: theme.palette.background.paper,
                                    cursor: "pointer",
                                    borderRadius: '10px',
                                    padding: '15px',
                                    marginTop: '60px',
                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                    '& span': {
                                        width: '120px',
                                        height: '120px',
                                        flex: '0 0 120px',
                                        border: `5px solid ${theme.palette.background.default}`,
                                        borderRadius: '100px',
                                        margin: '-70px auto 20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '& img': {
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '100px',
                                        },
                                    },
                                    '& h3': {
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        marginBottom: '5px',
                                        color: theme.palette.text.primary,
                                    },
                                    '& p': {
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        color: theme.palette.text.primary,
                                    },
                                }}>
                                    <Typography variant='span'>
                                        <img src={item?.social_profile_pic} />
                                    </Typography>
                                    <Typography variant='h3'>{item?.first_name}</Typography>
                                    <Typography>{item.email}</Typography>
                                </Box>
                            </Grid>
                        )
                    }) : <Grid item lg={12} md={12} sm={12} xs={12}><Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "150px",
                        "& svg": {
                            fill: theme.palette.warning.dark
                        }
                    }}>
                        <SearchOffIcon /><Typography sx={{ color: theme.palette.warning.dark }}>No record found</Typography></Box></Grid> :
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {/* Display a loading indicator while data is being fetched */}
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "150px" }}>
                                <CircularProgress size="1.5rem" />
                            </Box>
                        </Grid>
                    }
                    {returnedData?.data?.length > 0 && <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box display="flex" justifyContent="end">
                            <Stack spacing={2}>
                                <TablePagination
                                    rowsPerPageOptions={false}
                                    component="div"
                                    count={count}
                                    page={page - 1}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </Stack>
                            {/* {devData?.length > 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    alignContent: "center",
                                }}
                            >
                                <IconButton
                                    disabled={
                                        returnedData?.page_details.previous === null
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        setPage(
                                            (prevState) => prevState - 1
                                        );
                                    }}
                                >
                                    <ChevronLeftIcon />
                                </IconButton>
                                <Typography sx={{ margin: "0 6px" }}>{page}</Typography>
                                <IconButton
                                    disabled={
                                        returnedData?.page_details.next > 0 ? false : true
                                    }
                                    onClick={() => {
                                        setPage(
                                            (prevState) => prevState + 1
                                        );
                                    }}
                                >
                                    <ChevronRightIcon />
                                </IconButton>
                            </div>
                        )} */}
                        </Box>
                    </Grid>}
                </Grid>
            </CustomPaperBox>
        </Box>
    )
}

export default FindBrokers;
